import React, {useState, useRef, useEffect, useContext} from 'react'
import {useIntl} from 'react-intl'
import ButtonCal from '../../../localMedia/buttonCal.svg'
import Accordion from 'react-bootstrap/Accordion'
import SofaIcon from '../../../localMedia/sofa.svg'
import PlaneIcon from '../../../localMedia/plane.svg'
import UmbrellaIcon from '../../../localMedia/umbrella.svg'
import Check from '../../../localMedia/check.svg'
import Cross from '../../../localMedia/cross.svg'
import History from '../../../localMedia/history.svg'
import Revert from '../../../localMedia/fullRevert.svg'
import DatePicker from 'react-datepicker'
import {default as Apiservices, quickBooks} from '../../../../src/common/index'
import {useForm} from 'react-hook-form'
import clsx from 'clsx'
import moment from 'moment'
import sandTime from '../../../localMedia/sandtime.svg'
import Rejected from '../../../localMedia/rejected.svg'
import Approved from '../../../localMedia/approved.svg'
import Approval from '../../../localMedia/approval.svg'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast} from 'react-toastify'
import ThingsContext from '../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../modules/apps/masters/commonicons/ValidateContext'
import {PageTitle} from '../../../_metronic/layout/core'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import Drawer from '../../modules/apps/timesheetentry/ComponentDrawer'
import ModalPop from '../../modules/apps/masters/modal/ModalPop'
import BackDrop from '../../modules/apps/timesheetentry/ComponentBackdrop'
import {Tooltip} from '../../modules/apps/timesheetentry/ComponentTooltip'
import ViewDocumentPop from '../../modules/apps/masters/modal/ViewDocumentPop'
import RevertPopup from '../../modules/apps/masters/modal/RevertPopup'
import RevertWeekPopup from '../../modules/apps/masters/modal/RevertWeekPopup'
import DocFilesView from '../../modules/apps/masters/modal/DocFilesView'
import RevertAllPopup from '../../modules/apps/masters/modal/RevertAllPopup'
import RejectSubmissionPopup from '../../modules/apps/masters/modal/RejectSubmissionPopup'
import RevertSubmissionPopup from '../../modules/apps/masters/modal/RevertSubmissionPopup'
import RejectProjectSubmissionPopup from '../../modules/apps/masters/modal/RejectProjectSubmissionPopup'
import RevertProjectSubmissionPopup from '../../modules/apps/masters/modal/RevertProjectSubmissionPopup'
import UpdateConsultant from '../../pages/approver/UpdateConsultant'
import {Link} from 'react-router-dom'
import RejectMonthPop from '../../modules/apps/masters/modal/RejectMonthPop'
import RevertMonthPop from '../../modules/apps/masters/modal/RevertMonthPop'
import RejectMonthProjectPop from '../../modules/apps/masters/modal/RejectMonthProjectPop'
import RevertMonthProjectPopup from '../../modules/apps/masters/modal/RevertMonthProjectPopup'
import {KTSVG} from '../../../_metronic/helpers'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import AnnouncementPop from '../../modules/apps/masters/modal/AnnouncementPop'
type dat = {
  employee_name: any
  monday_actual: any
  comments: any
  tuesday_actual: any
  wednesday_actual: any
  thursday_actual: any
  friday_actual: any
  saturday_actual: any
  sunday_actual: any
  total_actual: any
  total_leave: any
  client_manager_name: any
  project_name: any
  client_manager_email: any
  document_files: any
  monday_ot: any
  tuesday_ot: any
  wednesday_ot: any
  thursday_ot: any
  friday_ot: any
  saturday_ot: any
  sunday_ot: any
  monday_leave: any
  tuesday_leave: any
  wednesday_leave: any
  thursday_leave: any
  friday_leave: any
  saturday_leave: any
  sunday_leave: any
  notes: any
  monday_notes: any
  tuesday_notes: any
  wednesday_notes: any
  thursday_notes: any
  friday_notes: any
  saturday_notes: any
  ts_client_manager_name: any
  sunday_notes: any
  approver_notes: any
  ts_approver_notes: any
  week_start_date: any
  rejected_comments_flag: any
  revert_CrossBtn: any
  revert_ApproveBtn: any
  status: any
  total_regular_hours: any
  approve: any
  reject: any
  submit: any
  client_name: any
  document_name: any
  allRevertData: any
  quickApprove: any
  qb_Name: any
  trueColor: any
}

type UserSubmitForm = {
  details: dat[]
  status: any
  rejected_hours: any
  regular_hours: any
  business_unit_id: any
}

const Approver: React.FC<any> = (props: any) => {
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<UserSubmitForm>()
  type ButtonProps = React.HTMLProps<HTMLButtonElement>
  interface IUser {
    submit: any
    approve: any
    reject: any
  }
  let user: IUser
  const current = new Date()
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
  var dataDateType: any = current.getMonth() + 1
  const [startDate, setStartDate] = useState(new Date())
  const [docResultDataRes, setDocResultDataRes] = useState<any>([])
  const [candidate, setCandidate] = useState<any[]>([])
  const [datedata3, setDateData3] = useState(dataDateType)
  const [yeardata3, setYearData3] = useState<any[]>()
  const [projectIdData, setProjectIdData] = useState<any[]>([])
  const [adpValidatedddIdData, setAdpValidatedddIdData] = useState<any[]>([])
  // const [userIdData, setUserIdData] = useState<any[]>([])
  const [viewshow11, setViewShow11] = useState(false)
  const [viewshow12, setViewShow12] = useState(false)
  const [userIdData, setUserIdData] = useState('')
  const [BuIndividualRecord, setBuIndividualRecord] = useState<any>('')
  const [weekStartData, setWeekStartData] = useState<any>('')
  const [weekEndData, setWeekEndData] = useState<any[]>([])
  const [weekDataProjectList, setWeekDataProjectList] = useState<any[]>([])
  const [weekDataProjectList1, setWeekDataProjectList1] = useState<any[]>([])
  const [weekDataProjectListMonth, setWeekDataProjectListMonth] = useState<any[]>([])
  const [weekDataProjectListMonth1, setWeekDataProjectListMonth1] = useState<any[]>([])
  const [weekDataProjectListYear, setWeekDataProjectListYear] = useState<any[]>([])
  const [weekDataProjectListYear1, setWeekDataProjectListYear1] = useState<any[]>([])
  const [weekDataProjectListData, setWeekDataProjectListData] = useState<any[]>([])
  const [timeClientData, setTimeClentData] = useState<any[]>([])
  const [notesData, setNotesData] = useState('')
  const [monthCanDates, setMonthCanDates] = useState<any[]>([])
  const [month_view_dates, setMonthViewDates] = useState<any[]>([])
  const [propsConsultant11, setPropsConsultant11] = useState('')
  const [propsConsultant12, setPropsConsultant12] = useState('')
  const [emp_name, setEmpName] = useState('')
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  const [emp_name1, setEmpName1] = useState('')
  const [emp_nameProj, setEmpNameProj] = useState('')
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [startEnd, setStartEnd] = useState('')
  const [monthStartDate, setMonthStartDate] = useState('')
  const [monthEndDate, setMonthEndDate] = useState('')
  const [startMonthNumber, setStartMonthNumber] = useState('')
  const [startYearNumber, setStartYearNumber] = useState('')
  const [weekStartDate, setWeekStartDate] = useState('')
  const [weekEndDate, setWeekEndDate] = useState('')
  const [month_open, setMonthOpen] = useState(false)
  const [month_view, setMonth_View] = useState(false)
  const [showMonthView, setShowMonthView] = useState(false)
  const [showWeekView, setShowWeekView] = useState(true)
  const [startingMonthView, setStartingMonthView] = useState(true)
  const [startingMonthView1, setStartingMonthView1] = useState(true)
  const [showingYearMonth, setShowingYearMonth] = useState(false)
  const [list, setList] = useState(false)
  const [approveTrue, setApproveTrue] = useState(false)
  const [nextDate, setNextDate] = useState(false)
  const [books, setBooks] = useState(false)
  const [approveTrue1, setApproveTrue1] = useState(false)
  const [revertTrue, setRevertTrue] = useState(false)
  const [rejectTrue, setRejectTrue] = useState(false)
  const [month_week_view, setMonthWeekView] = useState(false)
  const [totalHoursYear, setTotalHoursYear] = useState<any | []>([])
  const [dataDateMonth, setDataDateMonth] = useState('')
  const [dataDateYear, setDataDateYear] = useState('')
  const [totalHoursYear1, setTotalHoursYear1] = useState<any | []>([])
  const [totalHoursYear2, setTotalHoursYear2] = useState<any | []>([])
  const [show, setShow] = useState(false)
  const [tabName, setTabName] = useState('SUBMITTED')
  const [approver_history_per_week, setApproverHistoryPerWeek] = useState<any | []>([])
  const [approver_history_per_day, setApproverHistoryPerDay] = useState<any | []>([])
  const [approver_history_per_day_status, setApproverHistoryPerDayStatus] = useState<any | []>([])
  const [weekdaysOtHours, setWeekdaysOtHours] = useState<any | []>([])
  const [quickbook_show_pop, setQuickbookShowPop] = useState(false)
  const [quickbook_open_pop, setQuickbookOpenPop] = useState(false)
  const [weekDayWithData, setWeekDayWithData] = useState<string>('')
  const [weekDayWithSavedData, setWeekDayWithSavedData] = useState<any[]>()
  const [approvedDataStatusAll, setApprovedDataStatusAll] = useState<any[]>()
  const [rejectedDataStatusAll, setRejectedDataStatusAll] = useState<any[]>()
  const [revertededDataStatusAll, setRevertededDataStatusAll] = useState<any[]>()
  const [search, setSearch] = useState('')
  const [employeeView, setEmployeeView] = useState(false)
  const [MonthCalendarName, setMonthCalendarName] = useState(false)
  const [projectView, setProjectView] = useState(false)
  const [calendarView, setCalendarView] = useState(false)
  const [calendarView1, setCalendarView1] = useState(false)
  const [validateADPdataApprover, setValidateADPdataApprover] = useState(false)
  const [qBtRANSACTIONSfdATA, setQBtRANSACTIONSdATA] = useState(false)
  const [validateQuickBooksdataApprover, setValidateQuickBooksdataApprover] = useState(false)
  const [validateNetSuitedataApprover, setValidateNetSuitedataApprover] = useState(false)
  const [enableADPdATA, setEnableADPdATA] = useState(false)
  const [monthlyView, setMonthlyView] = useState(false)
  const [approver_history_open_per_week, setApproverHistoryOpenPerWeek] = useState(false)
  const [approver_history_open_per_day, setApproverHistoryOpenPerDay] = useState(false)
  const [disableApproveButton, setDisableApproveButton] = useState(false)
  const [revertedDataMonth2, setRevertedDataMonth2] = useState('')
  const [revertedDataMonth3, setRevertedDataMonth3] = useState('')
  const [revertedUserIdDataMonth2, setRevertedUserIdDataMonth2] = useState('')
  const [revertedUserIdDataMonth3, setRevertedUserIdDataMonth3] = useState('')
  const [revertedProjectIdDataMonth2, setRevertedProjectIdDataMonth2] = useState('')
  const [revertedProjectIdDataMonth3, setRevertedProjectIdDataMonth3] = useState('')
  const [revertApproveButton, setRevertApproveButton] = useState(false)
  const [disableRejectButton, setDisableRejectButton] = useState(false)
  const [sinleApprovedData, setSinleApprovedData] = useState('false')
  const [timeTracker, setTimeTracker] = useState(moment())
  const [prev_week, setPrevWeek] = useState('')
  const [sumofAllProj, setSumofAllProj] = useState<any[]>([])
  const [sumofHour_otHourProject, setSumofHour_otHourProject] = useState<any[]>([])
  const [sumofAllProjLeave, setSumofAllProjLeave] = useState<any[]>([])
  const [imagesArrayRes, setImagesArrayRes] = useState<any>([])
  const [showApproveReject, setShowApproveReject] = useState<any | []>([])
  const [showRevertAll, setShowRevertAll] = useState<any | []>([])
  const [weekDay, setWeekDay] = useState<any[]>([])
  const [clientName, setClientName] = useState<any[]>([])
  const [viewshow1, setViewShow1] = useState(false)
  const [viewshow2, setViewShow2] = useState(false)
  const [viewshow4, setViewShow4] = useState(false)
  const [viewshow5, setViewShow5] = useState(false)
  const [viewshow8, setViewShow8] = useState(false)
  const [viewshow6, setViewShow6] = useState(false)
  const [viewshow7, setViewShow7] = useState(false)
  const [viewshow3, setViewShow3] = useState(false)
  const [viewshow10, setViewShow10] = useState(false)
  const [revertedUserIdDataMonth, setRevertedUserIdDataMonth] = useState('')
  const [revertedUserIdDataMonth1, setRevertedUserIdDataMonth1] = useState('')
  const [revertedDataMonth, setRevertedDataMonth] = useState('')
  const [revertedDataMonth1, setRevertedDataMonth1] = useState('')
  const [showingMonthDisable, setShowingMonthDisable] = useState(true)
  const [showingMonthDisable1, setShowingMonthDisable1] = useState(true)
  const [revertedProjectStartData, setRevertedProjectStartData] = useState('')
  const [revertedProjectEndedData, setRevertedProjectEndedData] = useState('')
  const [revertedProjectUserIdData, setRevertedProjectUserIdData] = useState('')
  const [revertedProjectProjectIdData, setRevertedProjectProjectIdData] = useState('')
  const [revertedProjectMonthData, setRevertedProjectMonthData] = useState('')
  const [revertedProjectYearData, setRevertedProjectYearData] = useState('')
  const [revertedStartData, setRevertedStartData] = useState('')
  const [revertedEndedData, setRevertedEndedData] = useState('')
  const [revertedEndedData1, setRevertedEndedData1] = useState('')
  const [revertedEndedData2, setRevertedEndedData2] = useState('')
  const [revertedEndedData3, setRevertedEndedData3] = useState('')
  const [revertedUserIdData, setRevertedUserIdData] = useState('')
  const [rejectedStartData, setRejectedStartData] = useState('')
  const [rejectedEndedData, setRejectedEndedData] = useState('')
  const [rejectedUserIdData, setRejectedUserIdData] = useState('')
  const [rejectedStartDataProject, setRejectedStartDataProject] = useState('')
  const [rejectedEndedDataProject, setRejectedEndedDataProject] = useState('')
  const [rejectedUserIdDataProject, setRejectedUserIdDataProject] = useState('')
  const [rejectedProjectIdDataProject, setRejectedProjectIdDataProject] = useState('')
  const [rejectedProjectDataMonth, setRejectedProjectDataMonth] = useState('')
  const [rejectedProjectDataYear, setRejectedProjectDataYear] = useState('')
  const [propsConsultant, setPropsConsultant] = useState('')
  const [propsConsultant1, setPropsConsultant1] = useState('')
  const [propsConsultant2, setPropsConsultant2] = useState('')
  const [propsConsultant3, setPropsConsultant3] = useState('')
  const [propsConsultant8, setPropsConsultant8] = useState('')
  const [propsConsultant4, setPropsConsultant4] = useState('')
  const [propsConsultant7, setPropsConsultant7] = useState('')
  const [indexDataEntireWeek, setIndexDataEntireWeek] = useState('')
  const [pendingTimeSheetClick, setPendingTimeSheetClick] = useState<any | []>(false)
  const [monthYear, setmonthYear] = useState('')
  const [month_name, setMonthName] = useState('')
  const [year_name, setYearName] = useState('')
  const [monthNumber, setMonthNumber] = useState<any>()
  const [seletedDate, setseletedDate] = useState(moment(new Date()))
  const [week_range, setWeekRange] = useState('')
  const [week_dates, setWeekDates] = useState<any>([])
  const [indexState, setindexState] = useState('')
  const [project_list, setProject_list] = useState([])
  const [project_list_name, setProject_list_Name] = useState([])
  const [approve_btn_disable, setApproveBtnDisable] = useState(false)
  const [userAuth, setUserAuth] = useState(false)
  const [monthcalendar, setmonthcalendar] = useState(false)
  const [startingDisabled, setStartingDisabled] = useState(false)
  const [projectViewDisabled, setProjectViewDisabled] = useState(true)
  const [showAllData, setShowAllData] = useState(false)
  const [AdpUserId, setAdpUserId] = useState<any>()
  const [AdpPopupOpen, setAdpPopupOpen] = useState(false)
  const [mon, setMon] = useState<any>()
  const [tue, setTue] = useState<any>()
  const [wed, setWed] = useState<any>()
  const [thu, setThu] = useState<any>()
  const [fri, setFri] = useState<any>()
  const [sat, setSat] = useState<any>()
  const [sun, setSun] = useState<any>()
  const [monday_notes, setMondayNotes] = useState('')
  const [wednesday_notes, setWednesdayNotes] = useState('')
  const [thursday_notes, setThursdayNotes] = useState('')
  const [friday_notes, setFridayNotes] = useState('')
  const [saturday_notes, setSaturdayNotes] = useState('')
  const [sunday_notes, setSundayNotes] = useState('')
  const [tuesday_notes, setTuesdayNotes] = useState('')
  const [indexData, setIndexData] = useState('')
  const [dateData, setDateData] = useState('')
  const [weekIndexData, setWeekIndexData] = useState('')
  const [approverCommentsData, setApproverCommentsData] = useState('')
  const [adpValidatedddd, setAdpValidatedddd] = useState('')
  const intl = useIntl()
  const [showBgBasedOnUserIdId, setshowBgBasedOnUserIdId] = useState(false)
  const [bgId, setBgId] = useState('')
  const [bgUserId, setBgUserId] = useState('')
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnitId, setBusinessUnitId] = useState<any | []>([])
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const [announcement_list, setAnnouncementList] = useState<any | []>([])

  const ref: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  if (ref.current !== null) {
    // ref.current?.focus()
  }
  const openTooltip = () => {
    ref.current.open()
    setIsMenuOpen(false)
    // ref.current.toggle()
  }
  const closeResetTooltip = () => {
    ref.current.close()
    setBusinessUnitId([])
    setValue('business_unit_id', '')
    if (tabName === 'SUBMITTED') {
      pendingTimeSheet('clear')
    } else if (tabName === 'REJECTED') {
      rejectTimeSheet('clear')
    } else if (tabName === 'APPROVED') {
      approveTimeSheet('clear')
    } else if (tabName === '') {
      allapproveTimeSheet('clear')
    }
  }
  const closeTooltip = () => {
    ref.current.close()
    if (tabName === 'SUBMITTED') {
      pendingTimeSheet('')
    } else if (tabName === 'REJECTED') {
      rejectTimeSheet('')
    } else if (tabName === 'APPROVED') {
      approveTimeSheet('')
    } else if (tabName === '') {
      allapproveTimeSheet('')
    }
  }

  const [showOT, setShowOT] = useState<any | {}>({
    monday_ot: false,
    tuesday_ot: false,
    wednesday_ot: false,
    thursday_ot: false,
    friday_ot: false,
    saturday_ot: false,
    sunday_ot: false,
  })
  const [showHoliday, setShowHoliday] = useState<any | {}>({
    monday_holiday: false,
    tuesday_holiday: false,
    wednesday_holiday: false,
    thursday_holiday: false,
    friday_holiday: false,
    saturday_holiday: false,
    sunday_holiday: false,
  })
  const [showLeave, setShowLeave] = useState<any | {}>({
    monday_leave: false,
    tuesday_leave: false,
    wednesday_leave: false,
    thursday_leave: false,
    friday_leave: false,
    saturday_leave: false,
    sunday_leave: false,
  })

  const getApproverHistoryPerDay = async (
    proj_id: any,
    start_date: any,
    end_date: any,
    timesheet_date: any
  ) => {
    setShowBackDrop(true)
    setShow(true)
    setApproverHistoryOpenPerDay(true)
    setApproverHistoryOpenPerWeek(false)
    const test = {
      project_id: proj_id,
      week_start_date: start_date,
      week_end_date: end_date,
      timesheet_date: moment(timesheet_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
    const res = await Apiservices.timesheetHistoryPerDay(test)
    if (res.data && res.data.data && res.data.data) {
      setApproverHistoryPerDay(res.data.data)
    } else {
      setApproverHistoryPerDay([])
    }
    setShowBackDrop(false)
  }

  const getHistoryPerWeek = async (proj_id: any, start_date: any, end_date: any) => {
    setShowBackDrop(true)
    setShow(true)
    setApproverHistoryOpenPerWeek(true)
    setApproverHistoryOpenPerDay(false)
    const test = {
      project_id: proj_id,
      week_start_date: moment(mon, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      week_end_date: moment(sun, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
    const res = await Apiservices.timesheetHistory(test)
    if (res.data && res.data.data && res.data.data.timesheet_status_history) {
      setApproverHistoryPerWeek(res.data.data.timesheet_status_history)
    } else {
      setApproverHistoryPerWeek([])
    }
    setShowBackDrop(false)
  }
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleFocus = () => {
    setIsMenuOpen(true) // Open the dropdown when input is clicked
  }
  const handleItemSelection = (selected: any) => {
    // setIsMenuOpen(false) // Optionally close the dropdown after selecting an item
    handleBusinessUnit(selected)
  }
  const handleCloseAllPopups = () => {
    setIsMenuOpen(false)
  }

  const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({value, onClick}, ref: any) => (
      <button className='btn btn-icon btn-light btn-sm  ms-2 ' onClick={onClick} ref={ref}>
        <img src={ButtonCal} className='buttonCal' />
      </button>
    )
  )

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const projectViewData = async () => {
    setShowBackDrop(true)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let prestartweek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
    let week1 = moment(prestartweek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(preendweek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    let weekOnMonth = moment(prestartweek, 'YYYY-MM-DD').format('M')
    let weekOnYear = moment(preendweek, 'YYYY-MM-DD').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)
    setseletedDate(moment(prestartweek))
    setTimeTracker(moment(prestartweek))
    setWeekStartDate(prestartweek)
    setWeekEndDate(preendweek)
    setStartingMonthView1(true)
    setMonthCalendarName(false)
    setProjectViewDisabled(true)
    setShowingMonthDisable(true)
    setShowingMonthDisable1(true)
    setStartingDisabled(false)
    setUserAuth(false)
    setApproveTrue(false)
    setApproveTrue1(false)
    setRejectTrue(false)
    setRevertTrue(false)
    setMonthlyView(false)
    setmonthcalendar(false)
    setTabName('SUBMITTED')

    //when we click on projectView default this function will call
    // if (!nextDate) {
    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
      status: 'SUBMITTED',
      // year: currentYear,
      // month: month,
      week_start_date: prestartweek,
      week_end_date: preendweek,
      business_unit_id: business2 ? business2 : '',
    })
    setProject_list(response.data.data)
    setWeekDataProjectList(response.data.data)
    setProject_list_Name(response?.data?.data)
    getProjectList(response?.data?.data)
    setCandidate([])
    setMonthViewDates([])
    setMonth_View(false)
    setMonthOpen(false)
    setPendingTimeSheetClick(false)
    setCalendarView(false)
    setProjectView(true)
    setEmployeeView(false)
    // }
    // if (calendarView) {
    //   setPendingTimeSheetClick(false)
    //   const response: any = await Apiservices.pendingTimeSheet2({
    //     status: tabName,
    //     year: yeardata3,
    //     month: datedata3,
    //     business_unit_id: business2 ? business2 : '',
    //   })
    //   setProject_list(response.data.data)
    //   setWeekDataProjectList(response.data.data)
    //   setProject_list_Name(response?.data?.data)
    //   getProjectList(response?.data?.data)
    //   setCandidate([])
    //   setMonthViewDates([])
    //   setMonth_View(false)
    //   setMonthOpen(false)
    //   setPendingTimeSheetClick(false)
    //   setCalendarView(false)
    //   setProjectView(true)
    //   setEmployeeView(false)
    // }

    // if (nextDate) {
    //   const response: any = await Apiservices.pendingTimeSheet2({
    //     status: tabName,
    //     year: year_name,
    //     month: moment(month_name, 'MMMM').format('M'),
    //     business_unit_id: business2 ? business2 : '',
    //   })
    //   setProject_list(response.data.data)
    //   setWeekDataProjectList(response.data.data)
    //   setProject_list_Name(response?.data?.data)
    //   getProjectList(response?.data?.data)
    //   setCandidate([])
    //   setMonthViewDates([])
    //   setMonth_View(false)
    //   setMonthOpen(false)
    //   setPendingTimeSheetClick(false)
    //   setCalendarView(false)
    //   setProjectView(true)
    //   setEmployeeView(false)
    // }
    setShowBackDrop(false)
  }
  const [viewshow9, setViewShow9] = useState(false)
  function closeModalHandler1() {
    setViewShow1(false)
  }
  function closeModalHandler2() {
    setViewShow2(false)
  }
  function closeModalHandler3() {
    setViewShow3(false)
  }
  function closeModalHandler4() {
    setViewShow4(false)
  }
  function closeModalHandler5() {
    setViewShow5(false)
  }
  function closeModalHandler6() {
    setViewShow6(false)
  }
  function closeModalHandler7() {
    setViewShow7(false)
  }
  function closeModalHandler8() {
    setViewShow8(false)
  }
  function closeModalHandler9() {
    setViewShow9(false)
  }

  function closeModalHandler10() {
    setViewShow10(false)
  }

  function closeModalHandler11() {
    setViewShow11(false)
  }
  function closeModalHandler12() {
    setViewShow12(false)
  }

  const setConsultant = async (passed_status: any) => {
    setPropsConsultant(passed_status)
    let index: any = indexData
    let weekIndex = weekIndexData
    let date = dateData
    let candiateResponse: any = candidate
    candiateResponse[index].week_day_approve[weekIndex] = false
    candiateResponse[index].timesheet_data[weekIndex].status = 'SUBMITTED'
    setCandidate([...candiateResponse])
    candiateResponse[index].week_day_reject[weekIndex] = false
    setCandidate([...candiateResponse])
    setRevertApproveButton(true)
    setDisableApproveButton(false)
    let partialApprove = {
      user_id: candidate[index].timesheet_data[0].user_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      approver_notes: passed_status,
      year: weekStartData.slice(0, 4),
      // month: weekStartData.slice(6, 7),
      month: moment(weekStartData).format('M'),
      timesheet_date: date,
    }
    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.revertProjectWiseSingleDay(partialApprove)
    let week = true
    let month = false
    getUserAuth(
      emp_name,
      userIdData,
      weekStartData,
      weekEndData,
      projectIdData,
      BuIndividualRecord,
      week,
      month,
      'fhfghf'
    )
    setShowBackDrop(false)
    setSinleApprovedData(getWeeks.message)
    if (getWeeks.message == 'Timesheet Reverted Successfully') {
      SuccessToast('Reverted Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    ShowHideApproveAllRejectAllRevertAll(candiateResponse)
    setViewShow1(false)
  }

  const setConsultant1 = async (passed_status: any) => {
    setPropsConsultant1(passed_status)
    let index: any = indexDataEntireWeek
    setRevertTrue(true)
    setApproveTrue(true)
    setApproveTrue1(false)
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      user_id: candidate[index].timesheet_data[0].user_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      approver_notes: passed_status,
      timesheet_data: [
        {
          timesheet_date: candidate[index]?.timesheet_data[0]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[1]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[2]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[3]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[4]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[5]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[6]?.timesheet_date,
        },
      ],
    }
    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.revertProjectWiseTimeSheet(postData)
    setShowBackDrop(false)
    setSinleApprovedData(getWeeks.message)
    let startDate = weekStartData
    let endDate = weekEndData
    let week = true
    let month = false
    getUserAuth(
      emp_name,
      userIdData,
      weekStartData,
      weekEndData,
      projectIdData,
      BuIndividualRecord,
      week,
      month,
      'dgdgfd'
    )
    if (getWeeks.message == 'Timesheet Reverted Successfully') {
      SuccessToast('TimeSheet Reverted  Successfully')
      for (let i = 0; i < candidate[index]?.timesheet_data?.length; i++) {
        if (candidate[index]?.timesheet_data[i]?.status) {
          candidate[index].timesheet_data[i].status = 'SUBMITTED'
        }
      }
      setCandidate([...candidate])
    } else {
      WarnToast('Something Went Wrong')
    }
    const getStatus: any = await Apiservices.editTimesheetData(postData)
    setRevertededDataStatusAll(getStatus.data.data[0]?.ts_status)
    ShowHideApproveAllRejectAllRevertAll(candidate)
    setShowBackDrop(false)
    setViewShow2(false)
  }

  const setConsultant2 = async (passed_status: any) => {
    setShowBackDrop(true)
    setPropsConsultant2(passed_status)
    let index: any = indexDataEntireWeek
    setRevertTrue(true)
    setApproveTrue(true)
    setApproveTrue1(false)
    let timesheetsResponse = []
    if (candidate && candidate.length > 0) {
      for (let each of candidate) {
        timesheetsResponse.push({
          user_id: each.user_id,
          project_id: each.timesheet_data[0].project_id,
          approver_notes: passed_status,
          timesheet_data: each.timesheet_data.map((each: any) => ({
            timesheet_date: each.timesheet_date,
          })),
        })
      }
    }
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      timesheets: timesheetsResponse,
    }
    const getWeeks: any = await Apiservices.revertAllProjectTimesheets(postData)
    for (let j = 0; j < candidate?.length; j++) {
      for (let i = 0; i < candidate[j]?.timesheet_data?.length; i++) {
        if (candidate[j]?.timesheet_data[i]?.status == 'APPROVED') {
          candidate[j].timesheet_data[i].status = 'SUBMITTED'
        }
        if (candidate[j]?.timesheet_data[i]?.status == 'REJECTED') {
          candidate[j].timesheet_data[i].status = 'SUBMITTED'
        }
      }
    }
    setCandidate([...candidate])
    ShowHideApproveAllRejectAllRevertAll(candidate)
    let week = true
    let month = false
    getUserAuth(
      emp_name,
      userIdData,
      weekStartData,
      weekEndData,
      projectIdData,
      BuIndividualRecord,
      week,
      month,
      'gfdhd'
    )
    setViewShow4(false)
    setShowBackDrop(false)
  }
  const setConsultant3 = async (passed_status: any) => {
    setPropsConsultant3(passed_status)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let startDate1 = rejectedStartData.slice(5, 7)
    let startMonth1 = rejectedStartData.slice(0, 4)
    const response: any = await Apiservices.editTimesheetData({
      user_id: rejectedUserIdData,
      week_start_date: rejectedStartData,
      week_end_date: rejectedEndedData,
    })
    let timesheetsResponse = []
    if (response.data.data && response.data.data.length > 0) {
      for (let each of response.data.data) {
        timesheetsResponse.push({
          user_id: each.user_id,
          project_id: each.timesheet_data[0].project_id,
          approver_notes: passed_status,
          timesheet_data: each.timesheet_data.map((each: any) => ({
            timesheet_date: each.timesheet_date,
          })),
        })
      }
    }
    let postData = {
      year: startMonth1,
      month: startDate1,
      week_start_date: rejectedStartData,
      week_end_date: rejectedEndedData,
      timesheets: timesheetsResponse,
    }
    setShowBackDrop(true)

    const getWeeks: any = await Apiservices.rejectAllProjectTimesheets(postData)
    if (getWeeks.message == 'Timesheet Rejected Successfully') {
      SuccessToast('Timesheet Rejected Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }

    // const response3: any = await Apiservices.pendingTimeSheet1({
    //   status: tabName,
    //   month: startDate1,
    //   year: startMonth1,
    //   business_unit_id: business2 ? business2 : '',
    // })

    const response3: any = await Apiservices.pendingWeekWiseTimeSheet1({
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
      status: tabName,
      business_unit_id: business2 ? business2 : '',
    })
    setEmployeeView(true)
    setWeekDataProjectList(response3.data.data)
    setProject_list_Name(response3?.data?.data)
    getProjectList(response3?.data?.data)
    if (!startingMonthView || !startingMonthView1) {
      const users = await Apiservices.getTotalHours({
        year: startMonth1,
        month: startDate1,
        business_unit_id: business2 ? business2 : '',
      })
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    setShowBackDrop(false)
    setViewShow5(false)
  }
  const setConsultant8 = async (passed_status: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    setPropsConsultant8(passed_status)
    // const [rejectedStartDataProject, setRejectedStartDataProject] = useState('')
    // const [rejectedEndedDataProject, setRejectedEndedDataProject] = useState('')
    let startDate1 = rejectedStartDataProject.slice(5, 7)
    let startMonth1 = rejectedStartDataProject.slice(0, 4)
    var start = moment(rejectedStartDataProject).clone().startOf('isoWeek')
    var end = moment(rejectedEndedDataProject).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
      setWeekDates(arr)
    }

    setShowBackDrop(true)
    var monDayDate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var tuesDayDate = moment(days[1], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var wednesyDate = moment(days[2], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var thursDayDate = moment(days[3], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var fridayDate = moment(days[4], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var saturdayDate = moment(days[5], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var sundayDate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    let postData = {
      year: rejectedProjectDataYear,
      month: rejectedProjectDataMonth,
      week_start_date: rejectedStartDataProject,
      week_end_date: rejectedEndedDataProject,
      user_id: rejectedUserIdDataProject,
      project_id: rejectedProjectIdDataProject,
      approver_notes: passed_status,
      timesheet_data: [
        {
          timesheet_date: monDayDate,
        },
        {
          timesheet_date: tuesDayDate,
        },
        {
          timesheet_date: wednesyDate,
        },
        {
          timesheet_date: thursDayDate,
        },
        {
          timesheet_date: fridayDate,
        },
        {
          timesheet_date: saturdayDate,
        },
        {
          timesheet_date: sundayDate,
        },
      ],
    }

    const getWeeks: any = await Apiservices.rejectProjectWiseTimeSheet(postData)

    if (getWeeks.message == 'Timesheet Rejected Successfully') {
      SuccessToast('Timesheet Rejected Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    const response3: any = await Apiservices.pendingTimeSheet2({
      status: tabName,
      month: startDate1,
      year: startMonth1,
      business_unit_id: business2 ? business2 : '',
    })
    // setEmployeeView(true)
    setWeekDataProjectList(response3.data.data)
    setProject_list_Name(response3?.data?.data)
    getProjectList(response3?.data?.data)
    // const users = await Apiservices.getTotalHours({
    //   year: startMonth1,
    //   month: startDate1,
    // })

    setShowBackDrop(false)
    setViewShow8(false)
  }
  const userName = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''
      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }

  const handleAdpPopupOpen = (EmployeeUser_id: any) => {
    window.location.href = '/masters/manageconsultants/addconsultant'
    // setAdpUserId(EmployeeUser_id)
    // setAdpPopupOpen(true)
  }

  const handleCancel1 = () => {
    setAdpPopupOpen(false)
  }

  const setConsultant4 = async (passed_status: any) => {
    setPropsConsultant4(passed_status)

    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let startDate1 = revertedStartData.slice(5, 7)
    let startMonth1 = revertedStartData.slice(0, 4)
    const response: any = await Apiservices.editTimesheetData({
      user_id: revertedUserIdData,
      week_start_date: revertedStartData,
      week_end_date: revertedEndedData,
    })
    let timesheetsResponse = []
    if (response.data.data && response.data.data.length > 0) {
      for (let each of response.data.data) {
        timesheetsResponse.push({
          user_id: each.user_id,
          project_id: each.timesheet_data[0].project_id,
          approver_notes: passed_status,
          timesheet_data: each.timesheet_data.map((each: any) => ({
            timesheet_date: each.timesheet_date,
          })),
        })
      }
    }
    let postData = {
      year: startMonth1,
      month: startDate1,
      week_start_date: revertedStartData,
      week_end_date: revertedEndedData,
      timesheets: timesheetsResponse,
    }
    setShowBackDrop(true)

    const getWeeks: any = await Apiservices.revertAllProjectTimesheets(postData)
    // const response3: any = await Apiservices.pendingTimeSheet1({
    //   status: tabName,
    //   month: startDate1,
    //   year: startMonth1,
    //   business_unit_id: business2 ? business2 : '',
    // })

    const response3: any = await Apiservices.pendingWeekWiseTimeSheet1({
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
      status: tabName,
      business_unit_id: business2 ? business2 : '',
    })

    if (getWeeks.message == 'Timesheet Reverted Successfully') {
      SuccessToast('Timesheet Reverted Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    setEmployeeView(true)
    setWeekDataProjectList(response3.data.data)
    setProject_list_Name(response3?.data?.data)
    getProjectList(response3?.data?.data)

    if (!startingMonthView || !startingMonthView1) {
      const users = await Apiservices.getTotalHours({
        year: startMonth1,
        month: startDate1,
        business_unit_id: business2 ? business2 : '',
      })
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    setShowBackDrop(false)
    setViewShow6(false)
    setViewShow5(false)
  }
  const [propsConsultant9, setPropsConsultant9] = useState('')

  const setConsultant9 = async (passed_status: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    setShowBackDrop(true)
    setPropsConsultant9(passed_status)
    let postData = {
      year: revertedEndedData,
      // month: revertedDataMonth,
      month: moment(revertedDataMonth, 'MMMM').format('M'),
      user_id: revertedUserIdDataMonth,
      approver_notes: passed_status,
    }

    const getWeeks: any = await Apiservices.allRejectMonthlyTimeSheetData(postData)

    const response: any = await Apiservices.monthViewOpen({
      status: tabName,
      // year: 2024,
      year: revertedEndedData,
      month: moment(revertedDataMonth, 'MMM').format('M'),
      business_unit_id: business2 ? business2 : '',
    })
    setWeekDataProjectList1(response.data.data)
    // setWeekDataProjectListMonth(response.data.monthName)
    // setWeekDataProjectListYear(response.data.year)

    setShowBackDrop(false)

    setViewShow9(false)
  }

  const [propsConsultant10, setPropsConsultant10] = useState('')

  const setConsultant10 = async (passed_status: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    setShowBackDrop(true)
    setPropsConsultant10(passed_status)
    let postData = {
      // year: revertedEndedData,
      year: revertedEndedData1,
      // month: revertedDataMonth1,
      month: moment(revertedDataMonth1, 'MMMM').format('M'),
      user_id: revertedUserIdDataMonth1,
      approver_notes: passed_status,
    }
    const getWeeks: any = await Apiservices.allRevertMonthlyTimeSheetData(postData)

    const response: any = await Apiservices.monthViewOpen({
      status: tabName,
      year: revertedEndedData,
      month: revertedDataMonth1,
      business_unit_id: business2 ? business2 : '',
    })
    setWeekDataProjectList1(response.data.data)
    setShowBackDrop(false)
    setViewShow10(false)
  }

  const setConsultant11 = async (passed_status: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    setShowBackDrop(true)
    setPropsConsultant11(passed_status)
    let postData = {
      year: revertedEndedData2,
      // month: weekStartData.slice(5, 7),
      // month: revertedDataMonth2,
      month: moment(revertedDataMonth2, 'MMMM').format('M'),
      user_id: revertedUserIdDataMonth2,
      project_id: revertedProjectIdDataMonth2,
      approver_notes: passed_status,
    }
    const getWeeks: any = await Apiservices.allRejectMonthlyTimeSheetDataProject(postData)

    const response: any = await Apiservices.monthViewOpenProject({
      status: tabName,
      year: revertedEndedData2,
      month: revertedDataMonth2,
      business_unit_id: business2 ? business2 : '',
    })
    setWeekDataProjectList1(response.data.data)
    setShowBackDrop(false)
    setViewShow11(false)
  }

  const setConsultant12 = async (passed_status: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    setShowBackDrop(true)
    setPropsConsultant12(passed_status)
    let postData = {
      year: revertedEndedData3,
      // month: revertedDataMonth3,
      month: moment(revertedDataMonth3, 'MMMM').format('M'),
      user_id: revertedUserIdDataMonth3,
      project_id: revertedProjectIdDataMonth3,
      approver_notes: passed_status,
    }

    const getWeeks: any = await Apiservices.allRevertMonthlyTimeSheetDataProject1(postData)

    const response: any = await Apiservices.monthViewOpenProject({
      status: tabName,
      year: revertedEndedData3,
      month: revertedDataMonth2,
      business_unit_id: business2 ? business2 : '',
    })

    setWeekDataProjectList1(response.data.data)
    setShowBackDrop(false)
    setViewShow12(false)
  }

  const setConsultant7 = async (passed_status: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    setPropsConsultant7(passed_status)
    var start = moment(revertedProjectStartData).clone().startOf('isoWeek')
    var end = moment(revertedProjectEndedData).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
      setWeekDates(arr)
    }
    var monDayDate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var tuesDayDate = moment(days[1], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var wednesyDate = moment(days[2], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var thursDayDate = moment(days[3], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var fridayDate = moment(days[4], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var saturdayDate = moment(days[5], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var sundayDate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setShowBackDrop(true)
    let postData = {
      year: revertedProjectYearData,
      month: revertedProjectMonthData,
      week_start_date: revertedProjectStartData,
      week_end_date: revertedProjectEndedData,
      user_id: revertedProjectUserIdData,
      project_id: revertedProjectProjectIdData,
      approver_notes: passed_status,
      timesheet_data: [
        {
          timesheet_date: monDayDate,
        },
        {
          timesheet_date: tuesDayDate,
        },
        {
          timesheet_date: wednesyDate,
        },
        {
          timesheet_date: thursDayDate,
        },
        {
          timesheet_date: fridayDate,
        },
        {
          timesheet_date: saturdayDate,
        },
        {
          timesheet_date: sundayDate,
        },
      ],
    }

    const getWeeks: any = await Apiservices.revertProjectWiseTimeSheet(postData)
    if (getWeeks.message == 'Timesheet Reverted Successfully') {
      SuccessToast('Timesheet Reverted Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    const response3: any = await Apiservices.pendingTimeSheet2({
      status: tabName,
      month: revertedProjectMonthData,
      year: revertedProjectYearData,
      business_unit_id: business2 ? business2 : '',
    })
    // setEmployeeView(true)
    // setProjectView(true)
    setWeekDataProjectList(response3.data.data)
    setProject_list_Name(response3?.data?.data)
    getProjectList(response3?.data?.data)
    const users = await Apiservices.getTotalHours({
      year: revertedProjectYearData,
      month: revertedProjectMonthData,
      business_unit_id: business2 ? business2 : '',
    })
    setShowBackDrop(false)

    setViewShow7(false)

    setViewShow5(false)
  }

  //In useEffect employeeView cards are displayed
  const employeeViewData = async () => {
    setShowBackDrop(true)
    let prestartweek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')

    let week1 = moment(prestartweek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(preendweek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    let weekOnMonth = moment(prestartweek, 'YYYY-MM-DD').format('M')
    let weekOnYear = moment(preendweek, 'YYYY-MM-DD').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)

    setseletedDate(moment(prestartweek))
    setTimeTracker(moment(prestartweek))
    setWeekStartDate(prestartweek)
    setWeekEndDate(preendweek)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setCalendarView1(false)
    setStartingMonthView(true)
    setmonthcalendar(false)
    setMonthCalendarName(false)
    setNextDate(false)
    setProjectViewDisabled(true)
    setShowingMonthDisable(true)
    setShowingMonthDisable1(true)
    setStartingDisabled(false)
    setUserAuth(false)
    setApproveTrue(false)
    setApproveTrue1(false)
    setRejectTrue(false)
    setRevertTrue(false)
    setMonthlyView(false)
    setTabName('SUBMITTED')

    //if we click on next and previous month buttons then nextDate will become true

    //In useEffect employeeView card is displaying
    // when we click on monthbutton and click on employeeView button

    // if (!nextDate) {
    console.log('if 1')
    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1

    const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
      week_start_date: prestartweek,
      week_end_date: preendweek,
      status: 'SUBMITTED',
      // year: currentYear,
      // month: month,
      business_unit_id: business2 ? business2 : '',
    })

    setWeekDataProjectList(response?.data?.data)
    setProject_list_Name(response?.data?.data)
    getProjectList(response?.data?.data)
    setPendingTimeSheetClick(false)
    setMonthOpen(false)
    setMonth_View(false)
    setEmployeeView(true)
    setProjectView(false)
    setCalendarView(false)
    // }
    //when we click on datepicker and click on employeeView this function will call
    // if (calendarView1) {
    //   console.log('if 2')

    //   setTabName('SUBMITTED')
    //   setPendingTimeSheetClick(false)

    //   const response: any = await Apiservices.pendingTimeSheet1({
    //     status: tabName,
    //     year: yeardata3,
    //     month: datedata3,
    //     business_unit_id: business2 ? business2 : '',
    //   })
    //   setWeekDataProjectList(response.data.data)
    //   setMonthOpen(false)
    //   setMonthCanDates([])
    //   setProject_list_Name(response.data.data)
    // }
    //when we click on next button and previous button and click on employeeView
    // if (nextDate) {
    //   console.log('if 3')

    //   let currentYear = new Date().getFullYear()
    //   let month = new Date().getMonth() + 1

    //   const response: any = await Apiservices.pendingTimeSheet1({
    //     status: tabName,
    //     year: year_name,
    //     month: moment(month_name, 'MMMM').format('M'),
    //     business_unit_id: business2 ? business2 : '',
    //   })
    //   setWeekDataProjectList(response.data.data)
    //   setProject_list_Name(response?.data?.data)
    //   getProjectList(response?.data?.data)
    //   setPendingTimeSheetClick(false)
    //   setMonthOpen(false)
    //   setMonth_View(false)
    //   setEmployeeView(true)
    //   setProjectView(false)
    //   setCalendarView(false)
    // }

    const users = await Apiservices.getTotalHoursByWeek({
      week_start_date: prestartweek,
      week_end_date: preendweek,
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)
    setShowBackDrop(false)
  }

  const monthViewNextAndPrev = async (month: any, year: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let new_date = moment(`${year}-${month}-01`)
    let new_date1 = moment(new_date).format('YYYY-MM-DD')
    let prestartweek = moment(new_date1).startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment(new_date1).endOf('isoWeek').format('YYYY-MM-DD')
    let startdate = prestartweek
    let enddate = preendweek
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    let week1 = moment(prestartweek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(preendweek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let week = `${week1} - ${week2}`
    setStartEnd(week)

    var month_number = moment(month, 'M').format('YYYY-MM-DD')
    setWeekStartData(month_number)
    setDataDateMonth(month)
    setDataDateYear(year)

    setMonthStartDate(startdate)
    setMonthEndDate(enddate)
    setShowBackDrop(true)

    const users = await Apiservices.getTotalHours({
      month: month,
      // year: 2024,
      year: year,
      business_unit_id: business2 ? business2 : '',
    })

    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)
    // when we click on  employee view and clicking  on the month view button and clicking on next button
    if (!startingMonthView) {
      console.log('if 1')
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpen({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })

        setProject_list(response.data.data)

        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet1({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            year: moment(weekStartData).format('YYYY'),
            month: month,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      } else {
        const response: any = await Apiservices.monthViewOpen({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setProject_list(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet1({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            year: year,
            month: month,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      }
    }

    // when we click on  Project view and clicking  on the month view button and clicking on next button
    if (!startingMonthView1) {
      console.log('if 2')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpenProject({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setProject_list(response.data.data)

        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet2({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            year: year,
            month: month,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      } else {
        const response: any = await Apiservices.monthViewOpenProject({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setProject_list(response.data.data)

        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet2({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            year: year,
            month: month,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      }
    }

    // when we click on  employee view and immediately click on the next button without clicking on the month view button
    if (!userAuth && employeeView && startingMonthView) {
      console.log('if 3')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      if (tabName == ' ') {
        const response: any = await Apiservices.pendingTimeSheet1({
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setMonthOpen(false)
        setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      } else {
        const response: any = await Apiservices.pendingTimeSheet1({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setMonthOpen(false)
        setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      }
    }
    // when we click on  project view and immediately click on the next button without clicking on the month view button
    if (!userAuth && projectView && startingMonthView1) {
      console.log('if 4')

      if (tabName == ' ') {
        const response: any = await Apiservices.pendingTimeSheet2({
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingTimeSheet2({
          status: tabName,
          year: year,
          month: month,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }

    //when we click on employee week card and click on the next button this one will call
    if (userAuth) {
      console.log('if 5')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)

      const response: any = await Apiservices.pendingTimeSheet1({
        status: tabName,
        year: year,
        month: month,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)
      const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
        user_id: userIdData,
        // year: moment(weekStartData).format('YYYY'),
        year: year,
        month: month,
      })

      setCandidate([])
      let month_data = [data.data.data]
      setMonthViewDates(month_data)
      setMonth_View(true)
    }

    //clicking on project view and clicking on the cards and click on next button this one will call
    if (projectView && startingMonthView1) {
      console.log('if 6')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      const response: any = await Apiservices.pendingTimeSheet2({
        status: tabName,
        year: year,
        month: month,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)

      if (userIdData) {
        const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
          user_id: userIdData,
          year: moment(weekStartData).format('YYYY'),
          month: month,
        })

        setCandidate([])
        let month_data = [data.data.data]
        setMonthViewDates(month_data)
        setMonth_View(true)
      }
    }
    setShowBackDrop(false)
  }

  const nextMonth = async () => {
    setShowBackDrop(true)
    setNextDate(true)
    setWeekDataProjectList([])
    setCandidate([])
    setTimeClentData([])
    setmonthYear('')
    setMonthName('')
    setYearName('')
    setMonthCanDates([])
    setMonthViewDates([])
    var lastDayOfNextMonth = moment(seletedDate.add(1, 'M')).endOf('month')
    setseletedDate(lastDayOfNextMonth)
    const monthNo = moment(seletedDate).month()
    const nextMonth = seletedDate.format('MMMM')
    const nextMonth1 = seletedDate.format('M')
    const nextYear = seletedDate.format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)
    setMonthNumber(monthNo)
    monthViewNextAndPrev(monthNo + 1, nextYear)
    setDateData3(monthNo + 1)
    setShowBackDrop(false)
  }

  const prevMonth = async () => {
    setShowBackDrop(true)
    setNextDate(true)
    setmonthYear('')
    setWeekDataProjectList([])
    setMonthName('')
    setYearName('')
    console.log(seletedDate, 'seletedDate')
    var lastDayOfPreviousMonth = moment(seletedDate.add(-1, 'M')).endOf('month')
    setseletedDate(lastDayOfPreviousMonth)
    const monthNo = moment(seletedDate).month()
    const nextMonth = seletedDate.format('MMMM')
    const nextMonth1 = seletedDate.format('M')
    const nextYear = seletedDate.format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)
    setMonthNumber(monthNo)
    monthViewNextAndPrev(monthNo + 1, nextYear)
    setDateData3(monthNo + 1)

    console.log(dataDateMonth, 'dataDateMonth')
    setShowBackDrop(false)
  }

  const nextWeek = async () => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let start_day = getDays(1)

    let data3: any = moment(start_day[0]).format('M')
    setDateData3(data3)
    setDataDateMonth(moment(start_day[0]).format('M'))
    setDataDateYear(moment(start_day[0]).format('YYYY'))
    setmonthYear(moment(start_day[0]).format('MMMM YYYY'))
    setShowBackDrop(true)

    const users = await Apiservices.getTotalHoursByWeek({
      week_start_date: start_day[0],
      week_end_date: start_day[1],
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)
    console.log(userAuth, startingMonthView1)
    // when we click on  employee view and immediately click on the next button without clicking on the month view button
    if (!userAuth && employeeView && startingMonthView) {
      console.log('if 1')

      console.log('if next')
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      if (tabName == ' ') {
        // const response: any = await Apiservices.pendingTimeSheet1({
        //   year: year,
        //   month: month,
        //   business_unit_id: business2 ? business2 : '',
        // })

        const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          business_unit_id: business2 ? business2 : '',
        })
        console.log(response, 'next')
        setWeekDataProjectList(response.data.data)
        setMonthOpen(false)
        setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      } else {
        console.log('else next')

        // const response: any = await Apiservices.pendingTimeSheet1({
        //   status: tabName,
        //   year: year,
        //   month: month,
        //   business_unit_id: business2 ? business2 : '',
        // })
        const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          status: tabName,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setMonthOpen(false)
        setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      }
    }

    if (userAuth && employeeView && startingMonthView) {
      console.log('if 2')

      setTabName(tabName)
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)

      getUserAuth(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView,
        'gfjf'
      )

      // setCandidate([])
      // setMonthViewDates([])
      // setMonth_View(false)

      // setDocResultDataRes([])
      // setImagesArrayRes([])
      // setMonthWeekView(false)
      // setMonthOpen(false)
      // setMonthCanDates([])

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   year: year,
      //   month: month,
      //   business_unit_id: business2 ? business2 : '',
      // })
      // setWeekDataProjectList(response.data.data)
      // setMonthOpen(false)
      // setMonthCanDates([])
      // setProject_list_Name(response.data.data)
      // getProjectList(response.data.data)
      // setShowWeekView(false)
      // setShowMonthView(true)
      // setMonthlyView(true)
      // const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //   user_id: userIdData,
      //   // year: moment(weekStartData).format('YYYY'),
      //   year: year,
      //   month: month,
      // })

      // setCandidate([])
      // let month_data = [data.data.data]
      // setMonthViewDates(month_data)
      // setMonth_View(true)
    }

    if (userAuth && employeeView && !startingMonthView) {
      console.log('if 2')

      setTabName(tabName)
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)

      getUserAuth(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView,
        'gfjf'
      )

      // setCandidate([])
      // setMonthViewDates([])
      // setMonth_View(false)

      // setDocResultDataRes([])
      // setImagesArrayRes([])
      // setMonthWeekView(false)
      // setMonthOpen(false)
      // setMonthCanDates([])

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   year: year,
      //   month: month,
      //   business_unit_id: business2 ? business2 : '',
      // })
      // setWeekDataProjectList(response.data.data)
      // setMonthOpen(false)
      // setMonthCanDates([])
      // setProject_list_Name(response.data.data)
      // getProjectList(response.data.data)
      // setShowWeekView(false)
      // setShowMonthView(true)
      // setMonthlyView(true)
      // const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //   user_id: userIdData,
      //   // year: moment(weekStartData).format('YYYY'),
      //   year: year,
      //   month: month,
      // })

      // setCandidate([])
      // let month_data = [data.data.data]
      // setMonthViewDates(month_data)
      // setMonth_View(true)
    }

    // when we click on  project view and immediately click on the next button without clicking on the month view button
    if (!userAuth && projectView && startingMonthView1) {
      console.log('if 3')

      if (tabName == ' ') {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          status: tabName,
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          status: tabName,
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }

    //when we click on employee week card and click on the next button this one will call

    //clicking on project view and clicking on the cards and click on next button this one will call
    if (userAuth && projectView && startingMonthView1) {
      console.log('if 4')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)

      // if (userIdData) {
      //   const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //     user_id: userIdData,
      //     year: moment(weekStartData).format('YYYY'),
      //     month: month,
      //   })

      //   setCandidate([])
      //   let month_data = [data.data.data]
      //   setMonthViewDates(month_data)
      //   setMonth_View(true)
      // }

      getUserAuth1(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView
      )
    }

    if (userAuth && projectView && !startingMonthView1) {
      console.log('if 5')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)

      // if (userIdData) {
      //   const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //     user_id: userIdData,
      //     year: moment(weekStartData).format('YYYY'),
      //     month: month,
      //   })

      //   setCandidate([])
      //   let month_data = [data.data.data]
      //   setMonthViewDates(month_data)
      //   setMonth_View(true)
      // }

      getUserAuth1(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView
      )
    }

    // if (!startingMonthView) {
    //   console.log('if 2')

    //   setCandidate([])
    //   setMonthViewDates([])
    //   setMonth_View(false)
    //   if (tabName == ' ') {
    //     const response: any = await Apiservices.monthViewOpen({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })

    //     setProject_list(response.data.data)

    //     setWeekDataProjectList1(response.data.data)
    //     setWeekDataProjectListMonth(response.data.monthName)
    //     setWeekDataProjectListYear(response.data.year)
    //     const response1: any = await Apiservices.pendingTimeSheet1({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setWeekDataProjectList(response1.data.data)
    //     if (userIdData) {
    //       const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
    //         user_id: userIdData,
    //         year: moment(weekStartData).format('YYYY'),
    //         month: month,
    //       })
    //       setCandidate([])
    //       let month_data = [data.data.data]
    //       setMonthViewDates(month_data)
    //       setMonth_View(true)
    //     }
    //   } else {
    //     const response: any = await Apiservices.monthViewOpen({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setWeekDataProjectList1(response.data.data)
    //     setProject_list(response.data.data)
    //     setWeekDataProjectListMonth(response.data.monthName)
    //     setWeekDataProjectListYear(response.data.year)
    //     const response1: any = await Apiservices.pendingTimeSheet1({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setWeekDataProjectList(response1.data.data)
    //     if (userIdData) {
    //       const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
    //         user_id: userIdData,
    //         year: year,
    //         month: month,
    //       })
    //       setCandidate([])
    //       let month_data = [data.data.data]
    //       setMonthViewDates(month_data)
    //       setMonth_View(true)
    //     }
    //   }
    // }

    // // when we click on  Project view and clicking  on the month view button and clicking on next button
    // if (!startingMonthView1) {
    //   setCandidate([])
    //   setMonthViewDates([])
    //   setMonth_View(false)
    //   if (tabName == ' ') {
    //     const response: any = await Apiservices.monthViewOpenProject({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setProject_list(response.data.data)

    //     setWeekDataProjectList1(response.data.data)
    //     setWeekDataProjectListMonth(response.data.monthName)
    //     setWeekDataProjectListYear(response.data.year)
    //     const response1: any = await Apiservices.pendingTimeSheet2({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setWeekDataProjectList(response1.data.data)
    //     if (userIdData) {
    //       const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
    //         user_id: userIdData,
    //         year: year,
    //         month: month,
    //       })
    //       setCandidate([])
    //       let month_data = [data.data.data]
    //       setMonthViewDates(month_data)
    //       setMonth_View(true)
    //     }
    //   } else {
    //     const response: any = await Apiservices.monthViewOpenProject({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setWeekDataProjectList1(response.data.data)
    //     setWeekDataProjectListMonth(response.data.monthName)
    //     setProject_list(response.data.data)

    //     setWeekDataProjectListYear(response.data.year)
    //     const response1: any = await Apiservices.pendingTimeSheet2({
    //       status: tabName,
    //       year: year,
    //       month: month,
    //       business_unit_id: business2 ? business2 : '',
    //     })
    //     setWeekDataProjectList(response1.data.data)
    //     if (userIdData) {
    //       const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
    //         user_id: userIdData,
    //         year: year,
    //         month: month,
    //       })
    //       setCandidate([])
    //       let month_data = [data.data.data]
    //       setMonthViewDates(month_data)
    //       setMonth_View(true)
    //     }
    //   }
    // }
    setShowBackDrop(false)
  }

  const prevWeek = async () => {
    // setSumofAllProj([])
    // setDocResultDataRes([])
    // setImagesArrayRes([])
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let start_day: any = getDays(-1)

    let data3: any = moment(start_day[0]).format('M')
    setDateData3(data3)
    setDataDateMonth(moment(start_day[0]).format('M'))
    setDataDateYear(moment(start_day[0]).format('YYYY'))
    setmonthYear(moment(start_day[0]).format('MMMM YYYY'))
    setShowBackDrop(true)

    const users = await Apiservices.getTotalHoursByWeek({
      week_start_date: start_day[0],
      week_end_date: start_day[1],
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)

    // when we click on  employee view and immediately click on the next button without clicking on the month view button
    if (!userAuth && employeeView && startingMonthView) {
      console.log('if next')
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      if (tabName == ' ') {
        // const response: any = await Apiservices.pendingTimeSheet1({
        //   year: year,
        //   month: month,
        //   business_unit_id: business2 ? business2 : '',
        // })

        const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          business_unit_id: business2 ? business2 : '',
        })
        console.log(response, 'next')
        setWeekDataProjectList(response.data.data)
        setMonthOpen(false)
        setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      } else {
        console.log('else next')

        // const response: any = await Apiservices.pendingTimeSheet1({
        //   status: tabName,
        //   year: year,
        //   month: month,
        //   business_unit_id: business2 ? business2 : '',
        // })
        const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          status: tabName,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setMonthOpen(false)
        setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      }
    }

    if (userAuth && employeeView && startingMonthView) {
      console.log(start_day[0], start_day[1], start_day, 'start')

      setTabName(tabName)
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)

      getUserAuth(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView,
        'gfjf'
      )

      // setCandidate([])
      // setMonthViewDates([])
      // setMonth_View(false)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   year: year,
      //   month: month,
      //   business_unit_id: business2 ? business2 : '',
      // })
      // setWeekDataProjectList(response.data.data)
      // setMonthOpen(false)
      // setMonthCanDates([])
      // setProject_list_Name(response.data.data)
      // getProjectList(response.data.data)
      // setShowWeekView(false)
      // setShowMonthView(true)
      // setMonthlyView(true)
      // const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //   user_id: userIdData,
      //   // year: moment(weekStartData).format('YYYY'),
      //   year: year,
      //   month: month,
      // })

      // setCandidate([])
      // let month_data = [data.data.data]
      // setMonthViewDates(month_data)
      // setMonth_View(true)
    }

    if (userAuth && employeeView && !startingMonthView) {
      console.log(start_day[0], start_day[1], start_day, 'start')

      setTabName(tabName)
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)

      getUserAuth(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView,
        'gfjf'
      )

      // setCandidate([])
      // setMonthViewDates([])
      // setMonth_View(false)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   year: year,
      //   month: month,
      //   business_unit_id: business2 ? business2 : '',
      // })
      // setWeekDataProjectList(response.data.data)
      // setMonthOpen(false)
      // setMonthCanDates([])
      // setProject_list_Name(response.data.data)
      // getProjectList(response.data.data)
      // setShowWeekView(false)
      // setShowMonthView(true)
      // setMonthlyView(true)
      // const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //   user_id: userIdData,
      //   // year: moment(weekStartData).format('YYYY'),
      //   year: year,
      //   month: month,
      // })

      // setCandidate([])
      // let month_data = [data.data.data]
      // setMonthViewDates(month_data)
      // setMonth_View(true)
    }

    // when we click on  project view and immediately click on the next button without clicking on the month view button
    if (!userAuth && projectView && startingMonthView1) {
      console.log('if 3')

      if (tabName == ' ') {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          status: tabName,
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          status: tabName,
          week_start_date: start_day[0],
          week_end_date: start_day[1],
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }

    //when we click on employee week card and click on the next button this one will call

    //clicking on project view and clicking on the cards and click on next button this one will call
    if (userAuth && projectView && startingMonthView1) {
      console.log('if 4')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)

      // if (userIdData) {
      //   const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //     user_id: userIdData,
      //     year: moment(weekStartData).format('YYYY'),
      //     month: month,
      //   })

      //   setCandidate([])
      //   let month_data = [data.data.data]
      //   setMonthViewDates(month_data)
      //   setMonth_View(true)
      // }

      getUserAuth1(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView
      )
    }

    if (userAuth && projectView && !startingMonthView1) {
      console.log('if 4')

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: start_day[0],
        week_end_date: start_day[1],
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)

      // if (userIdData) {
      //   const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //     user_id: userIdData,
      //     year: moment(weekStartData).format('YYYY'),
      //     month: month,
      //   })

      //   setCandidate([])
      //   let month_data = [data.data.data]
      //   setMonthViewDates(month_data)
      //   setMonth_View(true)
      // }

      getUserAuth1(
        emp_name,
        userIdData,
        start_day[0],
        start_day[1],
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView
      )
    }
    setShowBackDrop(false)
  }

  const singleWeekSelection = async (date: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)

    var today = moment(date)
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    let week1 = moment(startOfWeek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(endOfWeek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    let weekOnMonth = moment(startOfWeek, 'YYYY-MM-DD').format('M')
    let weekOnYear = moment(startOfWeek, 'YYYY-MM-DD').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)

    setseletedDate(moment(startOfWeek))
    setTimeTracker(moment(startOfWeek))
    var start = moment(startOfWeek).clone().startOf('isoWeek')
    var end = moment(endOfWeek).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let weeks_array: any = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      weeks_array.push(week1)

      setWeekDates(weeks_array)
    }
    let DateArray = weeks_array
    var startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    setCalendarView1(true)
    setMonthViewDates([])

    if (employeeView && !startingMonthView) {
      console.log('if 1')

      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpen({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        // const response: any = await Apiservices.pendingTimeSheet1({
        //   month: data3,
        //   year: yearData,
        // })

        // setWeekDataProjectList(response.data.data)
        // setProject_list_Name(response?.data?.data)
        // getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.monthViewOpen({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
      }
    }

    if (!userAuth && !projectView && startingMonthView) {
      console.log('if 2')

      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)

      if (tabName == ' ') {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
          week_start_date: startdate,
          week_end_date: enddate,
          status: 'SUBMITTED',
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
          week_start_date: startdate,
          week_end_date: enddate,
          status: 'SUBMITTED',
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }

    if (userAuth) {
      console.log('if 3')
      // setCalendarView(true)
      // let space = ' '
      // var monthNames = [
      //   'January',
      //   'February',
      //   'March',
      //   'April',
      //   'May',
      //   'June',
      //   'July',
      //   'August',
      //   'September',
      //   'October',
      //   'November',
      //   'December',
      // ]
      // const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      // let data3: any = moment(date).format('M')
      // setDateData3(data3)
      // setmonthYear(data1)
      // var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      // setseletedDate(lastDayOfPreviousMonth)
      // const yearData = date.getFullYear()
      // setYearData3(yearData)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   month: data3,
      //   year: yearData,
      //   business_unit_id: business2 ? business2 : '',
      // })

      // setShowWeekView(false)
      // setShowMonthView(true)
      // setMonthlyView(true)
      // const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      //   user_id: userIdData,
      //   year: moment(weekStartData).format('YYYY'),
      //   month: data3,
      // })

      // setCandidate([])
      // let month_data = [data.data.data]
      // setMonthViewDates(month_data)
      // setMonth_View(true)

      setTabName(tabName)
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: startdate,
        week_end_date: enddate,
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)

      getUserAuth(
        emp_name,
        userIdData,
        startdate,
        enddate,
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView,
        'gfjf'
      )
    }

    if (!userAuth && projectView && startingMonthView1) {
      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          week_start_date: startdate,
          week_end_date: enddate,
          status: 'SUBMITTED',
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          week_start_date: startdate,
          week_end_date: enddate,
          status: 'SUBMITTED',
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }

    if (projectView && !startingMonthView1) {
      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpenProject({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
      } else {
        const response: any = await Apiservices.monthViewOpenProject({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
      }
    }
    let space = ' '
    var monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
    const data2 = new Date(date).toISOString().slice(5, 7)
    let data3: any = moment(date).format('M')
    setDateData3(data3)
    setmonthYear(data1)
    var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
    setseletedDate(lastDayOfPreviousMonth)
    const yearData = date.getFullYear()
    setYearData3(yearData)

    const users = await Apiservices.getTotalHoursByWeek({
      week_start_date: startdate,
      week_end_date: enddate,
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)
    setShowBackDrop(false)
  }
  function getDays(e: any) {
    var days = []
    if (e == 0) {
      setTimeTracker(moment())
    } else {
      timeTracker.add(e, 'weeks')
    }
    var startOfWeek = timeTracker.clone().startOf('isoWeek')
    var endOfWeek = timeTracker.clone().endOf('isoWeek')
    var days = []
    var day: any = startOfWeek

    while (day.isSameOrBefore(endOfWeek)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []

    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)

      setWeekDates(arr)
    }

    let DatesArray = arr
    let week1 = moment(days[0], 'ddd MMM DD YYYY').format('MMM DD')
    let prestartweek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')

    let week2 = moment(days[6], 'ddd MMM DD YYYY').format('MMM DD, YYYY')
    let preendweek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
    let startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    let enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)

    let week = `${week1} - ${week2}`
    let prevWeek = `${prestartweek}T00:00:00Z-${preendweek}T00:00:00Z`

    setStartEnd(week)
    let weekOnMonth = moment(days[0], 'ddd MMM DD YYYY').format('M')
    let weekOnYear = moment(days[0], 'ddd MMM DD YYYY').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    setStartYearNumber(weekOnYear)
    setseletedDate(moment(days[0]))

    setPrevWeek(prevWeek)
    // return days.join('<br />')

    return [startdate, enddate]
  }
  const pendingTimeSheet = async (type: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    // let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let business = business1.filter((temp: any) => `${temp}`).join(',')
    let business2 = type == 'clear' ? '' : business

    setProjectViewDisabled(true)
    setShowingMonthDisable(true)
    setShowingMonthDisable1(true)
    setStartingDisabled(false)
    setUserAuth(false)
    setApproveTrue(false)
    setApproveTrue1(false)
    setRejectTrue(false)
    setRevertTrue(false)
    setMonthlyView(false)
    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    setPendingTimeSheetClick(false)
    setShowBackDrop(true)

    if (MonthCalendarName) {
      const users = await Apiservices.getTotalHours({
        month: datedata3,
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        business_unit_id: business2 ? business2 : '',
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }

    // (!userAuth && employeeView && startingMonthView)

    if (calendarView) {
      console.log('if 1')

      setTabName('SUBMITTED')
      setPendingTimeSheetClick(false)

      const response: any = await Apiservices.pendingTimeSheet1({
        status: 'REJECTED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
    }
    if (employeeView && !userAuth && !startingMonthView) {
      setTabName('SUBMITTED')
      console.log('if 2')
      // if (!startingMonthView) {
      const response: any = await Apiservices.monthViewOpen({
        status: 'SUBMITTED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
    }
    if (projectView && !userAuth && !startingMonthView1) {
      console.log('if 3')
      setTabName('SUBMITTED')
      // if (!startingMonthView1) {
      const response: any = await Apiservices.monthViewOpenProject({
        status: 'SUBMITTED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })

      const response1: any = await Apiservices.pendingTimeSheet2({
        status: 'SUBMITTED',
        // year: currentYear,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)

      setWeekDataProjectList(response1.data.data)
      setProject_list_Name(response1?.data?.data)
      getProjectList(response1?.data?.data)
    }
    if (projectView && startingMonthView1) {
      console.log('if 4')

      setTabName('SUBMITTED')
      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: 'SUBMITTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'SUBMITTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
    }
    if (projectView && userAuth && !startingMonthView1) {
      console.log('if 4')

      setTabName('SUBMITTED')
      setStartingMonthView1(true)

      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: 'SUBMITTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'SUBMITTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
    }
    if (employeeView && startingMonthView) {
      console.log('if 5')

      setTabName('SUBMITTED')
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: 'SUBMITTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'SUBMITTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
    }
    if (employeeView && userAuth && !startingMonthView) {
      console.log('if 6')

      setTabName('SUBMITTED')
      setProject_list([])
      setCandidate([])
      setMonthCanDates([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)
      setStartingMonthView(true)
      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: 'SUBMITTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'SUBMITTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
    }
    setShowBackDrop(false)
  }

  const rejectTimeSheet = async (type: any) => {
    setPendingTimeSheetClick(false)
    setShowBackDrop(true)

    setProjectViewDisabled(true)
    setShowingMonthDisable(true)
    setShowingMonthDisable1(true)
    setMonthlyView(false)
    setStartingDisabled(false)
    setMonthViewDates([])
    setUserAuth(false)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    // let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let business = business1.filter((temp: any) => `${temp}`).join(',')
    let business2 = type == 'clear' ? '' : business

    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (MonthCalendarName) {
      const users = await Apiservices.getTotalHours({
        month: datedata3,
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        business_unit_id: business2 ? business2 : '',
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    if (calendarView) {
      setTabName('REJECTED')
      // setPendingTimeSheetClick(true)

      const response: any = await Apiservices.pendingTimeSheet1({
        status: 'REJECTED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
    }
    if (employeeView && !userAuth && !startingMonthView) {
      setTabName('REJECTED')
      // if (!startingMonthView) {
      const response: any = await Apiservices.monthViewOpen({
        status: 'REJECTED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
    }

    if (projectView && !userAuth && !startingMonthView1) {
      setTabName('REJECTED')
      // if (!startingMonthView1) {
      const response: any = await Apiservices.monthViewOpenProject({
        status: 'REJECTED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      const response1: any = await Apiservices.pendingTimeSheet2({
        status: 'REJECTED',
        // year: currentYear,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)

      setWeekDataProjectList(response1.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response1.data.data)
      getProjectList(response1.data.data)
    }

    // click on project view and after click on reject button it will print
    if (projectView && startingMonthView1) {
      setPendingTimeSheetClick(false)
      setTabName('REJECTED')
      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: 'REJECTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'REJECTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    if (projectView && userAuth && !startingMonthView1) {
      setPendingTimeSheetClick(false)
      setTabName('REJECTED')
      setStartingMonthView1(true)

      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: 'REJECTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'REJECTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }

    // click on employee view and after click on reject button it will print
    if (employeeView && startingMonthView) {
      setTabName('REJECTED')
      setCandidate([])
      // setPendingTimeSheetClick(true)
      setMonthViewDates([])
      setMonth_View(false)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: 'REJECTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'REJECTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      setPendingTimeSheetClick(false)
      getProjectList(response.data.data)
    }

    if (employeeView && userAuth && !startingMonthView) {
      setTabName('REJECTED')
      setCandidate([])
      // setPendingTimeSheetClick(true)
      setMonthViewDates([])
      setMonth_View(false)
      setStartingMonthView(true)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: 'REJECTED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'REJECTED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      setPendingTimeSheetClick(false)
      getProjectList(response.data.data)
    }
    setShowBackDrop(false)
  }

  const approveTimeSheet = async (type: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    // let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let business = business1.filter((temp: any) => `${temp}`).join(',')
    let business2 = type == 'clear' ? '' : business

    setProjectViewDisabled(true)
    setShowBackDrop(true)

    setShowingMonthDisable(true)
    setShowingMonthDisable1(true)
    setMonthlyView(false)

    setStartingDisabled(false)
    if (MonthCalendarName) {
      const users = await Apiservices.getTotalHours({
        month: datedata3,
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        business_unit_id: business2 ? business2 : '',
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    setMonthViewDates([])
    setUserAuth(false)
    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    setPendingTimeSheetClick(false)
    if (calendarView) {
      console.log('if 1')
      setTabName('APPROVED')
      setPendingTimeSheetClick(true)

      const response: any = await Apiservices.pendingTimeSheet1({
        status: 'APPROVED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }

    if (employeeView && !userAuth && !startingMonthView) {
      console.log('if 2')
      setTabName('APPROVED')
      // if (!startingMonthView) {
      const response: any = await Apiservices.monthViewOpen({
        status: 'APPROVED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setProject_list(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
    }
    if (projectView && !userAuth && !startingMonthView1) {
      console.log('if 3')
      setTabName('APPROVED')

      // if (!startingMonthView1) {
      const response: any = await Apiservices.monthViewOpenProject({
        // status: 'SUBMITTED',
        status: 'APPROVED',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      const response1: any = await Apiservices.pendingTimeSheet2({
        status: 'APPROVED',
        // year: currentYear,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
      setWeekDataProjectList(response1.data.data)
      setApproveBtnDisable(true)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response1.data.data)
      getProjectList(response1.data.data)
    }
    if (projectView && startingMonthView1) {
      console.log('if 4')

      setPendingTimeSheetClick(false)
      setTabName('APPROVED')
      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: 'APPROVED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'APPROVED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    if (projectView && userAuth && !startingMonthView1) {
      console.log('if 4')

      setPendingTimeSheetClick(false)
      setTabName('APPROVED')
      setStartingMonthView1(true)
      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: 'APPROVED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'APPROVED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    if (employeeView && startingMonthView) {
      console.log('if 5')

      setPendingTimeSheetClick(false)
      setTabName('APPROVED')
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: 'APPROVED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })
      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'APPROVED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      setPendingTimeSheetClick(false)
      getProjectList(response.data.data)
    }
    if (employeeView && userAuth && !startingMonthView) {
      console.log('if 5')

      setPendingTimeSheetClick(false)
      setTabName('APPROVED')
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      setStartingMonthView(true)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: 'APPROVED',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })
      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: 'APPROVED',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      setPendingTimeSheetClick(false)
      getProjectList(response.data.data)
    }
    setShowBackDrop(false)
  }

  const allapproveTimeSheet = async (type: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    // let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let business = business1.filter((temp: any) => `${temp}`).join(',')
    let business2 = type == 'clear' ? '' : business

    setProjectViewDisabled(true)
    setShowBackDrop(true)

    setShowingMonthDisable(true)
    setShowingMonthDisable1(true)
    setMonthlyView(false)

    setShowAllData(true)
    setStartingDisabled(false)
    if (MonthCalendarName) {
      const users = await Apiservices.getTotalHours({
        month: datedata3,
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        business_unit_id: business2 ? business2 : '',
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    setMonthViewDates([])
    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    setUserAuth(false)
    if (employeeView && !userAuth && !startingMonthView) {
      console.log('if 1')
      setTabName('')
      // if (!startingMonthView) {
      const response: any = await Apiservices.monthViewOpen({
        status: '',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
    }
    if (projectView && !userAuth && !startingMonthView1) {
      console.log('if 2')

      setTabName('')
      // if (!startingMonthView1) {
      const response: any = await Apiservices.monthViewOpenProject({
        status: '',
        // year: yeardata3,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })

      const response1: any = await Apiservices.pendingTimeSheet2({
        status: '',
        // year: currentYear,
        year: moment(monthYear).format('YYYY'),
        month: datedata3,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
      setWeekDataProjectList(response1.data.data)
      setApproveBtnDisable(true)
      setPendingTimeSheetClick(false)
      setMonthCanDates([])
      setProject_list_Name(response1.data.data)
      getProjectList(response1.data.data)
    }
    if (projectView && startingMonthView1) {
      console.log('if 3')

      setTabName('')
      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: '',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: '',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setPendingTimeSheetClick(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    if (projectView && userAuth && !startingMonthView1) {
      console.log('if 4')

      setTabName('')
      setStartingMonthView1(true)

      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: '',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: '',
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setPendingTimeSheetClick(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    if (employeeView && startingMonthView) {
      console.log('if 5')

      setTabName('')
      setCandidate([])
      setPendingTimeSheetClick(true)
      setMonthOpen(false)
      setPendingTimeSheetClick(false)
      setMonthViewDates([])
      setMonth_View(false)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: '',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: '',
        business_unit_id: business2 ? business2 : '',
      })

      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    if (employeeView && userAuth && startingMonthView) {
      console.log('if 6')

      setTabName('')
      setCandidate([])
      setPendingTimeSheetClick(true)
      setMonthOpen(false)
      setPendingTimeSheetClick(false)
      setMonthViewDates([])
      setMonth_View(false)
      setStartingMonthView(true)

      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: '',
      //   // year: currentYear,
      //   year: moment(monthYear).format('YYYY'),
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: '',
        business_unit_id: business2 ? business2 : '',
      })

      setWeekDataProjectList(response.data.data)
      setApproveBtnDisable(true)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
    }
    setShowBackDrop(false)
  }

  const getProjectList = (res11: any) => {
    let resultArr: any = []
    let resultArrObj: any = []
    if (res11 && res11.length > 0) {
      for (let each of res11) {
        if (!resultArr?.includes(each?.employee_name)) {
          resultArr?.push(each?.employee_name)
          resultArrObj?.push(each)
        }
      }
    }
    setProject_list(resultArrObj)
  }

  // when we click on employee level week wise cards
  const getUserAuth = async (
    employee_name: any,
    userId: any,
    weekStart: any,
    weekEnd: any,
    projectId: any,
    buId: any,
    week: any,
    month: any,
    adpValidated: any
  ) => {
    setShowingMonthDisable(false)
    setShowingMonthDisable1(true)
    setMonthCalendarName(false)
    setUserAuth(true)
    setStartingDisabled(true)
    setProjectIdData(projectId)
    setAdpValidatedddIdData(adpValidated)
    setUserIdData(userId)
    setWeekStartData(weekStart)
    setWeekEndData(weekEnd)
    setBuIndividualRecord(buId)
    setCandidate([])
    setEmpName1(employee_name)
    setPendingTimeSheetClick(true)
    setMonthViewDates([])
    // setTabName('')
    let testArr: any = []
    let totalhour_othourArr: any = []
    let totalLeaveArr: any = []
    let orgEmail = localStorage.getItem('user_email')
    setShowBackDrop(true)
    const response: any = await Apiservices.editTimesheetData({
      user_id: userId,
      week_start_date: weekStart,
      week_end_date: weekEnd,
    })
    const qb_tranaction: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'send_project_wise_timesheet_to_qb',
      business_unit_id: Number(buId),
    })
    setQBtRANSACTIONSdATA(
      qb_tranaction?.data &&
        qb_tranaction?.data?.data &&
        qb_tranaction?.data?.data[0] &&
        qb_tranaction?.data?.data[0]?.is_enabled
    )
    setTimeClentData(response?.data?.data)
    if (response?.data?.data?.length > 0) {
      response.data.data?.forEach((obj: any, i: any) => {
        obj.week_day_approve = [false, false, false, false, false, false, false]
        obj.week_day_reject = [false, false, false, false, false, false, false]
        setValue(`details.${i}.ts_client_manager_name`, obj?.ts_client_manager_name)
        setValue(`details.${i}.ts_approver_notes`, obj?.ts_approver_notes)
      })
    }
    setShowBackDrop(false)
    setMonthWeekView(false)
    setMonthOpen(true)
    if (response && response?.data && response?.data?.data) {
      setCandidate(response?.data?.data)
    } else {
      setCandidate([])
    }
    setClientName(response?.data?.data?.[0][0]?.project_name)
    response &&
      response.data &&
      response.data?.data?.map(async (each: any, i: any) => {
        let date3 =
          each &&
          each.timesheet_data &&
          each.timesheet_data[0] &&
          each.timesheet_data[0].week_start_date
            ? each.timesheet_data[0].week_start_date
            : null
        let date4 =
          each && each.timesheet_data && each.timesheet_data[0].week_end_date
            ? each.timesheet_data[0].week_end_date
            : null
        let date5 =
          each && each.timesheet_data && each.timesheet_data[0].user_id
            ? each.timesheet_data[0].user_id
            : null

        const getImages = await Apiservices.docNameIdProjectList1({
          week_start_date: date3,
          week_end_date: date4,
          user_id: date5,
        })
        setDocResultDataRes(getImages.data.data)
      })
    let today =
      response &&
      response.data &&
      response.data.data &&
      response.data.data[0] &&
      response.data.data[0].timesheet_data &&
      response.data.data[0].timesheet_data[0]
    console.log(response, 'response', today)
    let startOfWeek = today?.week_start_date ? today?.week_start_date : null
    let endOfWeek = today?.week_end_date
    let start = moment(startOfWeek).clone().startOf('isoWeek')
    let end = moment(endOfWeek).clone().endOf('isoWeek')
    let days = []
    let day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day?.toDate())
      day = day?.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
      setWeekDates(arr)
    }
    setMon(
      String(new Date(days[0])?.getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0])?.getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[0])?.getFullYear())
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getFullYear())
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getFullYear())
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getFullYear())
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getFullYear())
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getFullYear())
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getFullYear())
    )
    if (response?.data?.data?.length > 0) {
      ShowHideApproveAllRejectAllRevertAll(response.data.data)
      let week_dates = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]
      for (let i = 0; i < response.data.data.length; i++) {
        let timesheetWeekData: any = []
        week_dates.forEach((e) => {
          let valuecount = 0
          response.data.data[i].timesheet_data?.forEach((e1: any) => {
            if (e == e1.day_name) {
              valuecount = 1
              timesheetWeekData.push(e1)
            }
          })
          if (valuecount == 0) {
            timesheetWeekData.push({
              day_name: e,
              user_id: response.data.data[i].user_id,
              project_id: response.data.data[i].id,
              ot_hours: response.data.data[i].ot_hours == undefined ? 0 : 0,
              absent_hours: response.data.data[i].absent_hours == undefined ? 0 : 0,
            })
          }
        })
        response.data.data[i].timesheet_data = timesheetWeekData
        setWeekdaysOtHours(response.data.data[i].timesheet_data)
      }
      response.data.data?.forEach((each: any, i: any) => {
        setValue(
          `details.${i}.monday_actual`,
          each.timesheet_data[0].regular_hours == null
            ? '0:00'
            : each.timesheet_data[0].regular_hours +
                ':' +
                (each.timesheet_data[0].regular_minutes <= 9
                  ? '0' + each.timesheet_data[0].regular_minutes
                  : each.timesheet_data[0].regular_minutes)
        )
        setValue(`details.${i}.ts_approver_notes`, each.ts_approver_notes)
        setValue(
          `details.${i}.tuesday_actual`,
          each.timesheet_data[1].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[1]?.regular_hours +
                ':' +
                (each.timesheet_data[1].regular_minutes <= 9
                  ? '0' + each.timesheet_data[1].regular_minutes
                  : each.timesheet_data[1].regular_minutes)
        )
        setValue(
          `details.${i}.wednesday_actual`,
          each.timesheet_data[2].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[2]?.regular_hours +
                ':' +
                (each.timesheet_data[2].regular_minutes <= 9
                  ? '0' + each.timesheet_data[2].regular_minutes
                  : each.timesheet_data[2].regular_minutes)
        )
        setValue(
          `details.${i}.thursday_actual`,
          each.timesheet_data[3].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[3]?.regular_hours +
                ':' +
                (each.timesheet_data[3].regular_minutes <= 9
                  ? '0' + each.timesheet_data[3].regular_minutes
                  : each.timesheet_data[3].regular_minutes)
        )
        setValue(
          `details.${i}.friday_actual`,
          each.timesheet_data[4].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[4]?.regular_hours +
                ':' +
                (each.timesheet_data[4].regular_minutes <= 9
                  ? '0' + each.timesheet_data[4].regular_minutes
                  : each.timesheet_data[4].regular_minutes)
        )
        setValue(
          `details.${i}.saturday_actual`,
          each.timesheet_data[5].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[5]?.regular_hours +
                ':' +
                (each.timesheet_data[5].regular_minutes <= 9
                  ? '0' + each.timesheet_data[5].regular_minutes
                  : each.timesheet_data[5].regular_minutes)
        )
        setValue(
          `details.${i}.sunday_actual`,
          each.timesheet_data[6].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[6]?.regular_hours +
                ':' +
                (each.timesheet_data[6].regular_minutes <= 9
                  ? '0' + each.timesheet_data[6].regular_minutes
                  : each.timesheet_data[6].regular_minutes)
        )
        setValue(`details.${i}.comments`, each?.ts_user_notes)
        setValue(
          `details.${i}.monday_ot`,
          each.timesheet_data[0].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[0]?.ot_hours +
                ':' +
                (each?.timesheet_data[0]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[0]?.ot_minutes
                  : each?.timesheet_data[0]?.ot_minutes)
        )
        setValue(
          `details.${i}.tuesday_ot`,
          each.timesheet_data[1].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[1]?.ot_hours +
                ':' +
                (each?.timesheet_data[1]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[1]?.ot_minutes
                  : each?.timesheet_data[1]?.ot_minutes)
        )
        setValue(
          `details.${i}.wednesday_ot`,
          each.timesheet_data[2].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[2]?.ot_hours +
                ':' +
                (each?.timesheet_data[2]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[2]?.ot_minutes
                  : each?.timesheet_data[2]?.ot_minutes)
        )
        setValue(
          `details.${i}.thursday_ot`,
          each.timesheet_data[3].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[3]?.ot_hours +
                ':' +
                (each?.timesheet_data[3]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[3]?.ot_minutes
                  : each?.timesheet_data[3]?.ot_minutes)
        )
        setValue(
          `details.${i}.friday_ot`,
          each.timesheet_data[4].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[4]?.ot_hours + ':' + each?.timesheet_data[4]?.ot_minutes
        )
        setValue(
          `details.${i}.saturday_ot`,
          each.timesheet_data[5].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[5]?.ot_hours +
                ':' +
                (each?.timesheet_data[5]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[5]?.ot_minutes
                  : each?.timesheet_data[5]?.ot_minutes)
        )
        setValue(
          `details.${i}.sunday_ot`,
          each.timesheet_data[6].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[6]?.ot_hours +
                ':' +
                (each?.timesheet_data[6]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[6]?.ot_minutes
                  : each?.timesheet_data[6]?.ot_minutes)
        )
        setValue(
          `details.${i}.monday_leave`,
          each.timesheet_data[0].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[0]?.absent_hours +
                ':' +
                (each?.timesheet_data[0]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[0]?.absent_minutes
                  : each?.timesheet_data[0]?.absent_minutes)
        )
        setValue(
          `details.${i}.tuesday_leave`,
          each.timesheet_data[1].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[1]?.absent_hours +
                ':' +
                (each?.timesheet_data[1]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[1]?.absent_minutes
                  : each?.timesheet_data[1]?.absent_minutes)
        )
        setValue(
          `details.${i}.wednesday_leave`,
          each.timesheet_data[2].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[2]?.absent_hours +
                ':' +
                (each?.timesheet_data[2]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[2]?.absent_minutes
                  : each?.timesheet_data[2]?.absent_minutes)
        )
        setValue(
          `details.${i}.thursday_leave`,
          each.timesheet_data[3].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[3]?.absent_hours +
                ':' +
                (each?.timesheet_data[3]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[3]?.absent_minutes
                  : each?.timesheet_data[3]?.absent_minutes)
        )

        setValue(
          `details.${i}.friday_leave`,
          each.timesheet_data[4].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[4]?.absent_hours +
                ':' +
                (each?.timesheet_data[4]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[4]?.absent_minutes
                  : each?.timesheet_data[4]?.absent_minutes)
        )

        setValue(
          `details.${i}.saturday_leave`,
          each.timesheet_data[5].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[5]?.absent_hours +
                ':' +
                (each?.timesheet_data[5]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[5]?.absent_minutes
                  : each?.timesheet_data[5]?.absent_minutes)
        )

        setValue(
          `details.${i}.sunday_leave`,
          each.timesheet_data[6].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[6]?.absent_hours +
                ':' +
                (each?.timesheet_data[6]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[6]?.absent_minutes
                  : each?.timesheet_data[6]?.absent_minutes)
        )
      })
    }
    if (response?.data?.data?.length > 0) {
      response.data.data?.forEach((obj: any, i: any) => {
        let data: any = obj?.timesheet_data
        data.forEach((timesheet_data: any, index: any) => {
          if (
            timesheet_data.regular_hours == undefined ||
            timesheet_data.regular_minutes == undefined ||
            timesheet_data.ot_hours == undefined ||
            timesheet_data.ot_minutes == undefined ||
            timesheet_data.absent_hours == undefined ||
            timesheet_data.absent_minutes == undefined
          ) {
            data[index].regular_hours = 0
            data[index].regular_minutes = 0
            data[index].ot_hours = 0
            data[index].ot_minutes = 0
            data[index].absent_hours = 0
            data[index].absent_minutes = 0
          }
        })
        const sumofhour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.regular_hours)
        }, 0)

        const sumofmin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.regular_minutes)
        }, 0)
        const sumofOthour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.ot_hours)
        }, 0)
        const sumofOtmin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.ot_minutes)
        }, 0)
        const sumofLeavehour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.absent_hours)
        }, 0)
        const sumofLeavemin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.absent_minutes)
        }, 0)

        const total_hour = sumofhour * 60
        const total_ot_hour = sumofOthour * 60
        const total_leave_hour = sumofLeavehour * 60
        const total_minutes = total_hour + sumofmin
        const total_ot_minutes = total_ot_hour + sumofOtmin
        const total_hour_othour = total_minutes + total_ot_minutes
        const th =
          Math.floor(total_hour_othour / 60) <= 9
            ? '0' + Math.floor(total_hour_othour / 60)
            : Math.floor(total_hour_othour / 60)

        const tm =
          total_hour_othour % 60 <= 9 ? '0' + (total_hour_othour % 60) : total_hour_othour % 60
        const total_leave_minutes = total_leave_hour + sumofLeavemin
        const hours = Math.floor(total_minutes / 60)
        const otHours = Math.floor(total_ot_minutes / 60)
        const leaveHours =
          Math.floor(total_leave_minutes / 60) <= 9
            ? '0' + Math.floor(total_leave_minutes / 60)
            : Math.floor(total_leave_minutes / 60)
        const min = total_minutes % 60
        const otMin = total_ot_minutes % 60
        const leaveMin =
          total_leave_minutes % 60 <= 9
            ? '0' + (total_leave_minutes % 60)
            : total_leave_minutes % 60
        const total_sum = `${hours}:${min} + ${otHours}:${otMin}`
        const total_hour_othour_sum = `${th}:${tm}`
        const total_leave_sum = `${leaveHours}:${leaveMin} `
        testArr.push(total_sum)
        totalhour_othourArr.push(total_hour_othour_sum)
        totalLeaveArr.push(total_leave_sum)
        setSumofAllProj(testArr)
        setSumofHour_otHourProject(totalhour_othourArr)
        setSumofAllProjLeave(totalLeaveArr)
      })
    }
  }

  //
  const monthView = async (userId: any, emp_name: any, proj_name: any, buId: any) => {
    setUserIdData(userId)
    setEmpName1(emp_name)
    setEmpNameProj(proj_name)
    setBuIndividualRecord(buId)
    setShowingMonthDisable(false)
    setShowingMonthDisable1(true)
    setProjectViewDisabled(false)
    setMonthCalendarName(true)
    setShowWeekView(false)
    setShowMonthView(true)
    setMonthlyView(true)
    setmonthcalendar(true)
    // setUserAuth(true)
    console.log(weekStartData, 'weekStartData', weekStartDate)
    setShowBackDrop(true)
    if (startingMonthView && startingMonthView1) {
      console.log('if 1')
      const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
        user_id: userIdData,
        year: moment(weekStartData).format('YYYY'),
        month: moment(weekStartData).format('M'),
      })

      setCandidate([])
      let month_data = [data.data.data]
      setMonthViewDates(month_data)
      setMonth_View(true)
      setshowBgBasedOnUserIdId(true)
    } else {
      console.log('if 2')

      setStartingDisabled(true)
      setPendingTimeSheetClick(true)
      const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
        user_id: userId,
        // year: moment(weekStartData).format('YYYY'),
        // month: moment(weekStartData).format('M'),
        year: weekDataProjectListYear,
        // month: weekDataProjectListMonth,
        month: moment(weekDataProjectListMonth, 'MMMM').format('M'),
      })

      // const response: any = await Apiservices.editTimesheetData({
      //   user_id: userId,
      //   week_start_date: moment(weekStartData).format('YYYY'),
      //   // week_end_date: weekEnd,
      // })
      setCandidate([])
      let month_data = [data.data.data]
      setMonthViewDates(month_data)
      setMonth_View(true)
      setshowBgBasedOnUserIdId(true)
    }
    setShowBackDrop(false)
  }
  //this is employeeView outside monthView
  const monthViewStarting = async () => {
    setShowBackDrop(true)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setMonthCalendarName(true)
    setShowingYearMonth(true)
    let currentYear = new Date().getFullYear()
    // let month = new Date().getMonth() + 1
    setEmployeeView(true)

    let new_date = moment(`${startYearNumber}-${startMonthNumber}-01`)
    let new_date1 = moment(new_date).format('YYYY-MM-DD')
    let prestartweek = moment(new_date1).startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment(new_date1).endOf('isoWeek').format('YYYY-MM-DD')
    let startdate = prestartweek
    let enddate = preendweek
    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    let week1 = moment(prestartweek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(preendweek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let week = `${week1} - ${week2}`
    setStartEnd(week)

    setMonthStartDate(startdate)
    setMonthEndDate(enddate)

    let month = moment(startMonthNumber, 'M').format('MMMM')
    console.log(startMonthNumber, 'startMonthNumber')
    // let year = moment(new Date()).format('YYYY')
    let year = moment(startYearNumber, 'YYYY').format('YYYY')
    const monthNo = moment().month() + 1
    const present = `${month} ${year}`
    setmonthYear(present)
    setMonthName(month)
    setYearName(year)
    setseletedDate(moment(weekStartDate))
    console.log(weekStartDate, 'weekStartDate')

    let month_number = moment(startMonthNumber, 'M').format('YYYY-MM-DD')
    setWeekStartData(month_number)
    setDataDateMonth(month)
    setDataDateYear(year)

    setDateData3(startMonthNumber)
    const users = await Apiservices.getTotalHours({
      year: startYearNumber,
      month: startMonthNumber,
      business_unit_id: business2 ? business2 : '',
    })

    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)

    // first if we click on calendar view and after click on employee view month week button then this if is call
    if (datedata3 && yeardata3) {
      console.log('if')
      const response: any = await Apiservices.monthViewOpen({
        // status: tabName,
        // year: currentYear,
        // month: month,
        status: tabName,
        // year: yeardata3,
        // month: datedata3,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setProject_list(response.data.data)
      setWeekDataProjectList1(response?.data?.data)
      setWeekDataProjectListMonth(response?.data?.monthName)
      setWeekDataProjectListYear(response?.data?.year)
      const response1: any = await Apiservices.pendingTimeSheet1({
        status: tabName,
        // year: dataDateYear == '' ? currentYear : dataDateYear,
        // month: dataDateMonth == '' ? month : dataDateMonth,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response1.data.data)
      setStartingMonthView(false)
    } else {
      console.log('else')

      //when we click on starting monthView at employee level by default this function will call
      //when we click on next and prev button and click on monthView button
      const response: any = await Apiservices.monthViewOpen({
        // if we click previos and next buttons it will take as dataDateYear and dataDateMonth
        status: tabName,
        // year: dataDateYear == '' ? currentYear : dataDateYear,
        // month: dataDateMonth == '' ? month : dataDateMonth,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setProject_list(response.data.data)

      setWeekDataProjectList1(response.data.data)
      // setWeekDataProjectList(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
      const response1: any = await Apiservices.pendingTimeSheet1({
        status: tabName,
        // year: dataDateYear == '' ? currentYear : dataDateYear,
        // month: dataDateMonth == '' ? month : dataDateMonth,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response1.data.data)
      setStartingMonthView(false)
    }
    setShowBackDrop(false)
  }

  const monthViewStarting1 = async () => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setMonthCalendarName(true)
    setShowingYearMonth(true)
    setShowBackDrop(true)
    let currentYear = new Date().getFullYear()
    // let month = new Date().getMonth() + 1
    // setEmployeeView(true)
    let new_date = moment(`${startYearNumber}-${startMonthNumber}-01`)
    let new_date1 = moment(new_date).format('YYYY-MM-DD')
    let prestartweek = moment(new_date1).startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment(new_date1).endOf('isoWeek').format('YYYY-MM-DD')
    let startdate = prestartweek
    let enddate = preendweek

    setWeekStartDate(startdate)
    setWeekEndDate(enddate)
    let week1 = moment(prestartweek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(preendweek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let week = `${week1} - ${week2}`
    setStartEnd(week)
    setmonthcalendar(false)

    setMonthStartDate(startdate)
    setMonthEndDate(enddate)

    let month = moment(startMonthNumber, 'M').format('MMMM')
    // let year = moment(new Date()).format('YYYY')
    let year = moment(startYearNumber, 'YYYY').format('YYYY')
    const monthNo = moment().month() + 1
    const present = `${month} ${year}`
    setmonthYear(present)
    setMonthName(month)
    setYearName(year)
    setseletedDate(moment(weekStartDate))
    setDateData3(startMonthNumber)
    console.log(weekStartDate, 'weekStartDate')

    let month_number = moment(startMonthNumber, 'M').format('YYYY-MM-DD')
    setWeekStartData(month_number)
    setDataDateMonth(month)
    setDataDateYear(year)
    setTabName(tabName)
    const users = await Apiservices.getTotalHours({
      year: startYearNumber,
      month: startMonthNumber,
      business_unit_id: business2 ? business2 : '',
    })

    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)

    if (datedata3 && yeardata3) {
      console.log('if 1')
      const response: any = await Apiservices.monthViewOpenProject({
        status: tabName,
        // year: yeardata3,
        // month: datedata3,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
      setProject_list(response.data.data)
      setWeekDataProjectList1(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)
      setPendingTimeSheetClick(false)
      setCalendarView(false)
      setProjectView(true)
      setEmployeeView(false)
      // setWeekDataProjectList1(response.data.data)

      const response1: any = await Apiservices.pendingTimeSheet2({
        status: tabName,
        // year: yeardata3,
        // month: datedata3,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response1.data.data)
      setStartingMonthView1(false)
    } else {
      console.log('if 2')

      const response: any = await Apiservices.monthViewOpenProject({
        status: tabName,
        // year: dataDateYear == '' ? currentYear : dataDateYear,
        // month: dataDateMonth == '' ? month : dataDateMonth,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
      setProject_list(response.data.data)
      setWeekDataProjectList1(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      setMonthOpen(false)
      setPendingTimeSheetClick(false)
      setCalendarView(false)
      setProjectView(true)
      setEmployeeView(false)
      // setWeekDataProjectList1(response.data.data)

      const response1: any = await Apiservices.pendingTimeSheet2({
        status: tabName,
        // year: dataDateYear == '' ? currentYear : dataDateYear,
        // month: dataDateMonth == '' ? month : dataDateMonth,
        year: startYearNumber,
        month: startMonthNumber,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response1.data.data)
      setStartingMonthView1(false)
    }
    setShowBackDrop(false)
  }

  const weekViewStarting = async () => {
    //month to year
    console.log(weekStartDate, 'weekStartDate')
    setMonthCalendarName(false)
    setShowBackDrop(true)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    setEmployeeView(true)

    let week1 = moment(monthStartDate, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(monthEndDate, 'YYYY-MM-DD').format('MMM DD, YYYY')
    console.log(monthStartDate, 'monthStartDate')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    let weekOnMonth = moment(monthStartDate, 'YYYY-MM-DD').format('M')
    let weekOnYear = moment(monthStartDate, 'YYYY-MM-DD').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    console.log(weekOnMonth, 'weekOnMonth  if')
    setStartYearNumber(weekOnYear)
    setseletedDate(moment(monthStartDate))
    setTimeTracker(moment(monthStartDate))
    var start = moment(monthStartDate).clone().startOf('isoWeek')
    var end = moment(monthEndDate).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr3 = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr3.push(week1)

      setWeekDates(arr3)
    }

    //when we click on datepicker and click on weekView in employeeView level
    if (datedata3 && yeardata3) {
      console.log('if 1')
      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   year: yeardata3,
      //   month: datedata3,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)

      setStartingMonthView(true)
    } else {
      console.log('if 2')

      //when we click on weekView in employeeView level this function will call
      // const response: any = await Apiservices.pendingTimeSheet1({
      //   status: tabName,
      //   year: dataDateYear == '' ? currentYear : dataDateYear,
      //   month: dataDateMonth == '' ? month : dataDateMonth,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)

      setStartingMonthView(true)
    }
    const users = await Apiservices.getTotalHoursByWeek({
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)
    setShowBackDrop(false)
  }

  const weekViewStarting1 = async () => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setMonthCalendarName(false)
    let currentYear = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    setShowBackDrop(true)

    let week1 = moment(monthStartDate, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(monthEndDate, 'YYYY-MM-DD').format('MMM DD, YYYY')
    console.log(monthStartDate, 'monthStartDate')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    let weekOnMonth = moment(monthStartDate, 'YYYY-MM-DD').format('M')
    let weekOnYear = moment(monthStartDate, 'YYYY-MM-DD').format('YYYY')
    setStartMonthNumber(weekOnMonth)
    console.log(weekOnMonth, 'weekOnMonth  if')
    setStartYearNumber(weekOnYear)
    setseletedDate(moment(monthStartDate))
    setTimeTracker(moment(monthStartDate))
    var start = moment(monthStartDate).clone().startOf('isoWeek')
    var end = moment(monthEndDate).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr3 = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr3.push(week1)

      setWeekDates(arr3)
    }
    // setEmployeeView(true)
    if (datedata3 && yeardata3) {
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        status: tabName,
        // year: yeardata3,
        // month: datedata3,
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)

      setStartingMonthView1(true)
    } else {
      // const response: any = await Apiservices.pendingTimeSheet2({
      //   status: tabName,
      //   year: dataDateYear == '' ? currentYear : dataDateYear,
      //   month: dataDateMonth == '' ? month : dataDateMonth,
      //   business_unit_id: business2 ? business2 : '',
      // })

      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)

      setStartingMonthView1(true)
    }
    setShowBackDrop(false)

    // setEmployeeView(false)
  }

  const reportView = async () => {
    setShowWeekView(false)
    setShowMonthView(true)
    setMonthlyView(true)
    const data: any = await Apiservices.reportViewData({
      pagenumber: 1,
      pagesize: 25,
      start_date: weekStartData,
      end_date: weekEndData,
    })
    setCandidate([])
    let month_data = [data.data.data]
    setMonthViewDates(month_data)
    setMonth_View(true)
    setshowBgBasedOnUserIdId(true)
  }

  const getUserAuth1 = async (
    full_name: any,
    userId: any,
    weekStart: any,
    weekEnd: any,
    projectId: any,
    buId: any,
    week: any,
    month: any
  ) => {
    setProjectViewDisabled(false)
    setmonthcalendar(true)
    setUserAuth(true)
    setStartingDisabled(true)
    setProjectIdData(projectId)
    setUserIdData(userId)
    setWeekStartData(weekStart)
    setBuIndividualRecord(buId)
    setWeekEndData(weekEnd)
    setCandidate([])
    setEmpName(full_name)
    setEmpNameProj(full_name)
    setPendingTimeSheetClick(true)
    setMonthViewDates([])
    let testArr: any = []
    let totalhour_othourArr: any = []
    let totalLeaveArr: any = []
    setShowBackDrop(true)

    let orgEmail = localStorage.getItem('user_email')
    const response: any = await Apiservices.editTimesheetData({
      user_id: userId,
      week_start_date: weekStart,
      week_end_date: weekEnd,
    })

    const qb_tranaction: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'send_project_wise_timesheet_to_qb',
      business_unit_id: Number(buId),
    })
    setQBtRANSACTIONSdATA(
      qb_tranaction?.data &&
        qb_tranaction?.data?.data &&
        qb_tranaction?.data?.data[0] &&
        qb_tranaction?.data?.data[0]?.is_enabled
    )
    setTimeClentData(response.data.data)
    if (response.data.data.length > 0) {
      response.data.data?.forEach((obj: any, i: any) => {
        obj.week_day_approve = [false, false, false, false, false, false, false]
        obj.week_day_reject = [false, false, false, false, false, false, false]
        setValue(`details.${i}.ts_client_manager_name`, obj?.ts_client_manager_name)
        setValue(`details.${i}.ts_approver_notes`, obj?.ts_approver_notes)
      })
    }
    setMonthWeekView(false)
    setMonthOpen(true)
    if (response && response.data && response.data.data) {
      setCandidate(response.data.data)
    } else {
      setCandidate([])
    }
    setClientName(response?.data?.data?.[0][0]?.project_name)
    let imgData: any = []
    if (response && response.data?.data) {
      response?.data?.data?.map(async (each: any, i: any) => {
        // let date3 = each[0]?.week_start_date
        // let date4 = each[0]?.week_end_date
        // const anillll = await Apiservices.docNameIdProjectList({
        // week_start_date: date3,
        // week_end_date: date4,
        // project_id: each[0]?.project_id,
        // })
        // imgData.push(anillll.data.data)
        const getImages = await Apiservices.docNameIdProjectList1({
          week_start_date: weekStart,
          week_end_date: weekEnd,
          user_id: userId,
        })

        setDocResultDataRes(getImages.data.data)

        // if (getImg?.data?.data && getImg?.data?.data.length > 0) {
        //   for (let each of getImg?.data?.data) {
        //     imgData.push(each)
        //   }
        // }
        // setDocResultDataRes(imgData)
      })
    }

    // setImagesArrayRes(imgData)
    let today =
      response &&
      response?.data &&
      response?.data?.data &&
      response?.data?.data?.[0] &&
      response?.data?.data?.[0]?.timesheet_data &&
      response?.data?.data?.[0]?.timesheet_data[0]
    let startOfWeek = today?.week_start_date
    let endOfWeek = today?.week_end_date
    setTimeTracker(moment(startOfWeek))
    let start = moment(startOfWeek).clone().startOf('isoWeek')
    let end = moment(endOfWeek).clone().endOf('isoWeek')
    let days = []
    let day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)

      setWeekDates(arr)
    }
    setMon(
      String(new Date(days[0])?.getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0])?.getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[0])?.getFullYear())
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getFullYear())
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getFullYear())
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getFullYear())
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getFullYear())
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getFullYear())
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getFullYear())
    )
    if (response.data.data?.length > 0) {
      ShowHideApproveAllRejectAllRevertAll(response.data.data)
      let week_dates = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]
      for (let i = 0; i < response.data.data.length; i++) {
        let timesheetWeekData: any = []
        week_dates.forEach((e) => {
          let valuecount = 0
          response.data.data[i].timesheet_data?.forEach((e1: any) => {
            if (e == e1.day_name) {
              valuecount = 1
              timesheetWeekData.push(e1)
            }
          })

          if (valuecount == 0) {
            timesheetWeekData.push({
              day_name: e,
              user_id: response.data.data[i].user_id,
              project_id: response.data.data[i].id,
              ot_hours: response.data.data[i].ot_hours == undefined ? 0 : 0,
              absent_hours: response.data.data[i].absent_hours == undefined ? 0 : 0,
            })
          }
        })
        response.data.data[i].timesheet_data = timesheetWeekData
        setWeekdaysOtHours(response.data.data[i].timesheet_data)
      }
      response.data.data?.forEach((each: any, i: any) => {
        setValue(
          `details.${i}.monday_actual`,
          each.timesheet_data[0].regular_hours == null
            ? '0:00'
            : each.timesheet_data[0].regular_hours +
                ':' +
                (each.timesheet_data[0].regular_minutes <= 9
                  ? '0' + each.timesheet_data[0].regular_minutes
                  : each.timesheet_data[0].regular_minutes)
        )
        setValue(`details.${i}.ts_approver_notes`, each.ts_approver_notes)
        setValue(
          `details.${i}.tuesday_actual`,
          each.timesheet_data[1].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[1]?.regular_hours +
                ':' +
                (each.timesheet_data[1].regular_minutes <= 9
                  ? '0' + each.timesheet_data[1].regular_minutes
                  : each.timesheet_data[1].regular_minutes)
        )
        setValue(
          `details.${i}.wednesday_actual`,
          each.timesheet_data[2].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[2]?.regular_hours +
                ':' +
                (each.timesheet_data[2].regular_minutes <= 9
                  ? '0' + each.timesheet_data[2].regular_minutes
                  : each.timesheet_data[2].regular_minutes)
        )
        setValue(
          `details.${i}.thursday_actual`,
          each.timesheet_data[3].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[3]?.regular_hours +
                ':' +
                (each.timesheet_data[3].regular_minutes <= 9
                  ? '0' + each.timesheet_data[3].regular_minutes
                  : each.timesheet_data[3].regular_minutes)
        )
        setValue(
          `details.${i}.friday_actual`,
          each.timesheet_data[4].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[4]?.regular_hours +
                ':' +
                (each.timesheet_data[4].regular_minutes <= 9
                  ? '0' + each.timesheet_data[4].regular_minutes
                  : each.timesheet_data[4].regular_minutes)
        )
        setValue(
          `details.${i}.saturday_actual`,
          each.timesheet_data[5].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[5]?.regular_hours +
                ':' +
                (each.timesheet_data[5].regular_minutes <= 9
                  ? '0' + each.timesheet_data[5].regular_minutes
                  : each.timesheet_data[5].regular_minutes)
        )
        setValue(
          `details.${i}.sunday_actual`,
          each.timesheet_data[6].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[6]?.regular_hours +
                ':' +
                (each.timesheet_data[6].regular_minutes <= 9
                  ? '0' + each.timesheet_data[6].regular_minutes
                  : each.timesheet_data[6].regular_minutes)
        )
        setValue(`details.${i}.comments`, each?.ts_user_notes)
        setValue(
          `details.${i}.monday_ot`,
          each.timesheet_data[0].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[0]?.ot_hours +
                ':' +
                (each?.timesheet_data[0]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[0]?.ot_minutes
                  : each?.timesheet_data[0]?.ot_minutes)
        )
        setValue(
          `details.${i}.tuesday_ot`,
          each.timesheet_data[1].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[1]?.ot_hours +
                ':' +
                (each?.timesheet_data[1]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[1]?.ot_minutes
                  : each?.timesheet_data[1]?.ot_minutes)
        )
        setValue(
          `details.${i}.wednesday_ot`,
          each.timesheet_data[2].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[2]?.ot_hours +
                ':' +
                (each?.timesheet_data[2]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[2]?.ot_minutes
                  : each?.timesheet_data[2]?.ot_minutes)
        )
        setValue(
          `details.${i}.thursday_ot`,
          each.timesheet_data[3].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[3]?.ot_hours +
                ':' +
                (each?.timesheet_data[3]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[3]?.ot_minutes
                  : each?.timesheet_data[3]?.ot_minutes)
        )
        setValue(
          `details.${i}.friday_ot`,
          each.timesheet_data[4].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[4]?.ot_hours + ':' + each?.timesheet_data[4]?.ot_minutes
        )
        setValue(
          `details.${i}.saturday_ot`,
          each.timesheet_data[5].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[5]?.ot_hours +
                ':' +
                (each?.timesheet_data[5]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[5]?.ot_minutes
                  : each?.timesheet_data[5]?.ot_minutes)
        )
        setValue(
          `details.${i}.sunday_ot`,
          each.timesheet_data[6].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[6]?.ot_hours +
                ':' +
                (each?.timesheet_data[6]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[6]?.ot_minutes
                  : each?.timesheet_data[6]?.ot_minutes)
        )
        setValue(
          `details.${i}.monday_leave`,
          each.timesheet_data[0].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[0]?.absent_hours +
                ':' +
                (each?.timesheet_data[0]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[0]?.absent_minutes
                  : each?.timesheet_data[0]?.absent_minutes)
        )
        setValue(
          `details.${i}.tuesday_leave`,
          each.timesheet_data[1].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[1]?.absent_hours +
                ':' +
                (each?.timesheet_data[1]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[1]?.absent_minutes
                  : each?.timesheet_data[1]?.absent_minutes)
        )
        setValue(
          `details.${i}.wednesday_leave`,
          each.timesheet_data[2].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[2]?.absent_hours +
                ':' +
                (each?.timesheet_data[2]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[2]?.absent_minutes
                  : each?.timesheet_data[2]?.absent_minutes)
        )
        setValue(
          `details.${i}.thursday_leave`,
          each.timesheet_data[3].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[3]?.absent_hours +
                ':' +
                (each?.timesheet_data[3]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[3]?.absent_minutes
                  : each?.timesheet_data[3]?.absent_minutes)
        )
        setValue(
          `details.${i}.friday_leave`,
          each.timesheet_data[4].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[4]?.absent_hours +
                ':' +
                (each?.timesheet_data[4]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[4]?.absent_minutes
                  : each?.timesheet_data[4]?.absent_minutes)
        )
        setValue(
          `details.${i}.saturday_leave`,
          each.timesheet_data[5].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[5]?.absent_hours +
                ':' +
                (each?.timesheet_data[5]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[5]?.absent_minutes
                  : each?.timesheet_data[5]?.absent_minutes)
        )
        setValue(
          `details.${i}.sunday_leave`,
          each.timesheet_data[6].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[6]?.absent_hours +
                ':' +
                (each?.timesheet_data[6]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[6]?.absent_minutes
                  : each?.timesheet_data[6]?.absent_minutes)
        )
      })
    }
    if (response.data.data?.length > 0) {
      response.data.data?.forEach((obj: any, i: any) => {
        let data: any = obj?.timesheet_data
        data.forEach((timesheet_data: any, index: any) => {
          if (
            timesheet_data.regular_hours == undefined ||
            timesheet_data.regular_minutes == undefined ||
            timesheet_data.ot_hours == undefined ||
            timesheet_data.ot_minutes == undefined ||
            timesheet_data.absent_hours == undefined ||
            timesheet_data.absent_minutes == undefined
          ) {
            data[index].regular_hours = 0
            data[index].regular_minutes = 0
            data[index].ot_hours = 0
            data[index].ot_minutes = 0
            data[index].absent_hours = 0
            data[index].absent_minutes = 0
          }
        })
        const sumofhour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.regular_hours)
        }, 0)
        const sumofmin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.regular_minutes)
        }, 0)
        const sumofOthour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.ot_hours)
        }, 0)
        const sumofOtmin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.ot_minutes)
        }, 0)
        const sumofLeavehour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.absent_hours)
        }, 0)
        const sumofLeavemin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.absent_minutes)
        }, 0)
        const total_hour = sumofhour * 60
        const total_ot_hour = sumofOthour * 60
        const total_leave_hour = sumofLeavehour * 60
        const total_minutes = total_hour + sumofmin
        const total_ot_minutes = total_ot_hour + sumofOtmin
        const total_hour_othour = total_minutes + total_ot_minutes
        const th =
          Math.floor(total_hour_othour / 60) <= 9
            ? '0' + Math.floor(total_hour_othour / 60)
            : Math.floor(total_hour_othour / 60)
        const tm =
          total_hour_othour % 60 <= 9 ? '0' + (total_hour_othour % 60) : total_hour_othour % 60
        const total_leave_minutes = total_leave_hour + sumofLeavemin
        const hours = Math.floor(total_minutes / 60)
        const otHours = Math.floor(total_ot_minutes / 60)
        const leaveHours =
          Math.floor(total_leave_minutes / 60) <= 9
            ? '0' + Math.floor(total_leave_minutes / 60)
            : Math.floor(total_leave_minutes / 60)
        const min = total_minutes % 60
        const otMin = total_ot_minutes % 60
        const leaveMin =
          total_leave_minutes % 60 <= 9
            ? '0' + (total_leave_minutes % 60)
            : total_leave_minutes % 60
        const total_sum = `${hours}:${min} + ${otHours}:${otMin}`
        const total_hour_othour_sum = `${th}:${tm}`
        const total_leave_sum = `${leaveHours}:${leaveMin} `
        testArr.push(total_sum)
        totalhour_othourArr.push(total_hour_othour_sum)
        totalLeaveArr.push(total_leave_sum)
        setSumofAllProj(testArr)
        setSumofHour_otHourProject(totalhour_othourArr)
        setSumofAllProjLeave(totalLeaveArr)
      })
    }
    setShowBackDrop(false)
  }

  const handleChange = async (e: any) => {
    setMondayNotes(e.target.value)
    setThursdayNotes(e.target.value)
    setFridayNotes(e.target.value)
    setSaturdayNotes(e.target.value)
    setSundayNotes(e.target.value)
    setTuesdayNotes(e.target.value)
    setWednesdayNotes(e.target.value)
  }
  const handleChange2 = async (e: any, i: any) => {
    candidate[i].ts_approver_notes = e.target.value.trim()
    setApproverCommentsData(candidate[i].ts_approver_notes)
  }

  const onClickHolidayRevertLeave = async (index: any, day?: any, date?: any, weekIndex?: any) => {
    setIndexData(index)
    setDateData(date)
    setWeekIndexData(weekIndex)
    setViewShow1(true)
    // if (propsConsultant) {
    //   let candiateResponse: any = candidate
    //   candiateResponse[index].week_day_approve[weekIndex] = false
    //   candiateResponse[index].timesheet_data[weekIndex].status = 'SUBMITTED'
    //   setCandidate([...candiateResponse])
    //   candiateResponse[index].week_day_reject[weekIndex] = false
    //   setCandidate([...candiateResponse])
    //   setRevertApproveButton(true)
    //   setDisableApproveButton(false)
    //   let partialApprove = {
    //     user_id: candidate[index].timesheet_data[0].user_id,
    //     project_id: candidate[index].timesheet_data[0].project_id,
    //     week_start_date: weekStartData,
    //     week_end_date: weekEndData,
    //     approver_notes: propsConsultant,
    //     year: weekStartData.slice(0, 4),
    //     month: weekStartData.slice(6, 7),
    //     timesheet_date: date,
    //   }
    //   setShowBackDrop(true)
    //   const getWeeks: any = await Apiservices.revertProjectWiseSingleDay(partialApprove)
    //   setShowBackDrop(false)
    //   setSinleApprovedData(getWeeks.message)
    //   if (getWeeks.message == 'Timesheet Reverted Successfully') {
    //     SuccessToast('Reverted Successfully')
    //   } else {
    //     WarnToast('Something Went Wrong')
    //   }
    //   ShowHideApproveAllRejectAllRevertAll(candiateResponse)
    // }
  }

  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 500,
    })
  }

  const WarnToast = (message: any) => {
    toast.warn(message, {
      position: 'top-right',
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }

  const approverSubmission = async (
    approverComments: any,
    startDate: any,
    endDate: any,
    userId: any,
    adp_validated: any
  ) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    // let startDate1 = startDate.slice()
    let startDate1 = startDate.slice(5, 7)
    let startMonth1 = startDate.slice(0, 4)
    setAdpValidatedddd(adp_validated)
    const response: any = await Apiservices.editTimesheetData({
      user_id: userId,
      week_start_date: startDate,
      week_end_date: endDate,
    })
    let timesheetsResponse = []
    if (response.data.data && response.data.data.length > 0) {
      for (let each of response.data.data) {
        timesheetsResponse.push({
          user_id: each.user_id,
          project_id: each.timesheet_data[0].project_id,
          approver_notes: 'APPROVED',
          timesheet_data: each.timesheet_data.map((each: any) => ({
            timesheet_date: each.timesheet_date,
          })),
        })
      }
    }
    let postData = {
      year: startMonth1,
      month: startDate1,
      week_start_date: startDate,
      week_end_date: endDate,
      timesheets: timesheetsResponse,
    }

    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.allApproverTimeSheetData(postData)
    if (getWeeks.message == 'Timesheet Approved Successfully') {
      SuccessToast('TimeSheet Approved Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    // const response3: any = await Apiservices.pendingTimeSheet1({
    //   status: tabName,
    //   month: startDate1,
    //   year: startMonth1,
    //   business_unit_id: business2 ? business2 : '',
    // })

    const response3: any = await Apiservices.pendingWeekWiseTimeSheet1({
      week_start_date: weekStartDate,
      week_end_date: weekEndDate,
      status: tabName,
      business_unit_id: business2 ? business2 : '',
    })
    setEmployeeView(true)
    setWeekDataProjectList(response3.data.data)
    setProject_list_Name(response3?.data?.data)
    getProjectList(response3?.data?.data)
    if (!startingMonthView || !startingMonthView1) {
      const users = await Apiservices.getTotalHours({
        year: startMonth1,
        month: startDate1,
        business_unit_id: business2 ? business2 : '',
      })
    } else {
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: weekStartDate,
        week_end_date: weekEndDate,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    setShowBackDrop(false)
  }

  const approverSubmission1 = async (monthData: any, yearData: any, userId: any) => {
    setShowBackDrop(true)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let postData = {
      year: yearData,
      // month: monthData,
      month: moment(monthData, 'MMMM').format('M'),
      user_id: userId,
      approver_notes: 'approved',
    }

    const getWeeks: any = await Apiservices.allApproverMonthlyTimeSheetData(postData)

    const response: any = await Apiservices.monthViewOpen({
      status: tabName,
      year: yearData,
      month: monthData,
      business_unit_id: business2 ? business2 : '',
    })
    setWeekDataProjectList1(response.data.data)

    // setMonthOpenViewData(response.data.data)

    setShowBackDrop(false)
  }

  const allRejcetedDataMonth = async (monthData: any, yearData: any, userId: any) => {
    // setShowBackDrop(true)
    // month: moment(dateed, 'MMM').format('M'),

    setRevertedDataMonth(monthData)
    setRevertedEndedData(yearData)
    setRevertedUserIdDataMonth(userId)
    setViewShow9(true)
  }

  const allRevertedDataMonth = async (monthData: any, yearData: any, userId: any) => {
    setRevertedDataMonth1(monthData)
    setRevertedEndedData1(yearData)
    setRevertedUserIdDataMonth1(userId)
    setViewShow10(true)
  }

  const allApprovedDataMonthProject = async (
    monthData: any,
    yearData: any,
    userId: any,
    projectId: any
  ) => {
    // let date  : moment(dateed).format('M')
    setShowBackDrop(true)
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let postData = {
      year: yearData,
      // month: monthData,
      month: moment(monthData, 'MMMM').format('M'),
      user_id: userId,
      project_id: projectId,
      approver_notes: 'approved',
    }

    const getWeeks: any = await Apiservices.allApproverMonthlyTimeSheetDataProject(postData)

    let month = moment(weekStartData).format('MMMM')
    let year = moment(weekStartData).format('YYYY')
    const present = `${month} ${year}`

    const response: any = await Apiservices.monthViewOpenProject({
      status: tabName,
      year: yearData,
      month: monthData,
      business_unit_id: business2 ? business2 : '',
    })

    setWeekDataProjectList1(response.data.data)

    setShowBackDrop(false)
  }

  const allRejectedDataMonthProject = async (
    monthData: any,
    yearData: any,
    userId: any,
    projectId: any
  ) => {
    // setShowBackDrop(true)
    // month: moment(dateed, 'MMM').format('M'),
    setRevertedDataMonth2(monthData)
    setRevertedEndedData2(yearData)
    setRevertedUserIdDataMonth2(userId)
    setRevertedProjectIdDataMonth2(projectId)
    setViewShow11(true)
  }

  const allRevertedDataMonthProject = async (
    monthData: any,
    yearData: any,
    userId: any,
    projectId: any
  ) => {
    // setShowBackDrop(true)
    // month: moment(dateed, 'MMM').format('M'),
    setRevertedDataMonth3(monthData)
    setRevertedEndedData3(yearData)
    setRevertedUserIdDataMonth3(userId)
    setRevertedProjectIdDataMonth3(projectId)
    setViewShow12(true)
  }

  const approverSubmissionProject = async (
    approverComments: any,
    startDate: any,
    endDate: any,
    userId: any,
    projectId: any,
    index: any
  ) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    // let startDate1 = startDate.slice()
    let startDate1 = startDate.slice(5, 7)
    let startMonth1 = startDate.slice(0, 4)
    var start = moment(startDate).clone().startOf('isoWeek')
    var end = moment(endDate).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
      setWeekDates(arr)
    }
    var tuesDayDate = moment(days[1], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var wednesyDate = moment(days[2], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var thursDayDate = moment(days[3], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var fridayDate = moment(days[4], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var saturdayDate = moment(days[5], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    let postData = {
      year: startMonth1,
      month: startDate1,
      week_start_date: startDate,
      week_end_date: endDate,
      user_id: userId,
      project_id: projectId,
      approver_notes: 'APPROVED',
      timesheet_data: [
        {
          timesheet_date: startDate,
        },
        {
          timesheet_date: tuesDayDate,
        },
        {
          timesheet_date: wednesyDate,
        },
        {
          timesheet_date: thursDayDate,
        },
        {
          timesheet_date: fridayDate,
        },
        {
          timesheet_date: saturdayDate,
        },
        {
          timesheet_date: endDate,
        },
      ],
    }

    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.allTimeSheetInsert(postData)

    if (getWeeks.message == 'Timesheet Approved Successfully') {
      SuccessToast('Timesheet Approved Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    const response3: any = await Apiservices.pendingTimeSheet2({
      status: tabName,
      month: startDate1,
      year: startMonth1,
      business_unit_id: business2 ? business2 : '',
    })
    // setEmployeeView(true)
    setWeekDataProjectList(response3.data.data)
    setProject_list_Name(response3?.data?.data)
    getProjectList(response3?.data?.data)
    const users = await Apiservices.getTotalHours({
      year: startMonth1,
      month: startDate1,
      business_unit_id: business2 ? business2 : '',
    })
    setShowBackDrop(false)
  }
  const onClickHolidayDisableLeave = async (index: any, day: any, date: any, weekIndex: any) => {
    // if (candidate[index].ts_approver_notes !== null && candidate[index].ts_approver_notes !== '') {
    let candiateResponse = candidate
    candiateResponse[index].week_day_approve[weekIndex] = true
    candiateResponse[index].timesheet_data[weekIndex].status = 'APPROVED'
    setCandidate([...candiateResponse])
    setDisableApproveButton(true)
    setDisableRejectButton(true)
    let partialApprove = {
      user_id: candidate[index].timesheet_data[0].user_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      approver_notes: 'approver comment',
      // approver_notes: candidate[index].ts_approver_notes,
      year: weekStartData.slice(0, 4),
      // month: weekStartData.slice(6, 7),
      month: moment(weekStartData).format('M'),
      timesheet_date: date,
    }
    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.allTimeSheetInsertSingle(partialApprove)
    let week = true
    let month = false

    getUserAuth(
      emp_name,
      userIdData,
      weekStartData,
      weekEndData,
      projectIdData,
      BuIndividualRecord,
      week,
      month,
      'fhfghf'
    )
    // allApprove(index)
    setShowBackDrop(false)
    setSinleApprovedData(getWeeks.message)

    if (getWeeks.message == 'Timesheet Approved Successfully') {
      SuccessToast('Approved Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    ShowHideApproveAllRejectAllRevertAll(candiateResponse)
    // } else {
    //   WarnToast('Please enter approver comments')
    // }
    // } else {
    //   WarnToast('Pleasr')

    // }
  }

  const allApproveData = async (index: any) => {
    let timesheetsResponse = []
    if (candidate && candidate.length > 0) {
      for (let each of candidate) {
        timesheetsResponse.push({
          user_id: each.user_id,
          project_id: each.timesheet_data[0].project_id,
          approver_notes: each.ts_approver_notes ? each.ts_approver_notes : 'approved',
          timesheet_data: each.timesheet_data.map((each: any) => ({
            timesheet_date: each.timesheet_date,
          })),
        })
      }
    }
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      timesheets: timesheetsResponse,
    }

    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.allApproverTimeSheetData(postData)
    setShowBackDrop(false)

    for (let j = 0; j < candidate?.length; j++) {
      for (let i = 0; i < candidate[j]?.timesheet_data?.length; i++) {
        if (candidate[j]?.timesheet_data[i]?.status == 'SUBMITTED') {
          candidate[j].timesheet_data[i].status = 'APPROVED'
        }
      }
    }
    setCandidate([...candidate])
    ShowHideApproveAllRejectAllRevertAll(candidate)
    SuccessToast('TimeSheet Approved Successfully')
    // } else {
    //   WarnToast('Approver comments is Required')
    // }
  }

  const allApproveMonthData = async (index: any) => {
    setShowBackDrop(true)
    let timesheetsResponse = []
    if (month_view_dates && month_view_dates.length > 0) {
      for (let each of month_view_dates[0]) {
        timesheetsResponse.push({
          timesheet_date: each.timesheet_date,
          week_start_date: each.week_start_date,
          week_end_date: each.week_end_date,
        })
      }
    }
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      user_id: userIdData,
      timesheet_data: timesheetsResponse,
    }

    const getWeeks: any = await Apiservices.allApproverMonthlyTimeSheetData(postData)
    const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      user_id: userIdData,
      year: moment(weekStartData).format('YYYY'),
      month: moment(weekStartData).format('M'),
    })

    setCandidate([])
    let month_data = [data.data.data]
    setMonthViewDates(month_data)
    setMonth_View(true)
    if (getWeeks.message == 'Timesheet Approved Successfully') {
      SuccessToast('Monthly Timesheet Approved Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    setShowBackDrop(false)
  }
  const allRejectMonthData = async (index: any) => {
    setShowBackDrop(true)
    let timesheetsResponse = []
    if (month_view_dates && month_view_dates.length > 0) {
      for (let each of month_view_dates[0]) {
        timesheetsResponse.push({
          timesheet_date: each.timesheet_date,
          week_start_date: each.week_start_date,
          week_end_date: each.week_end_date,
        })
      }
    }
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      user_id: userIdData,
      timesheet_data: timesheetsResponse,
    }
    const getWeeks: any = await Apiservices.allRejectMonthlyTimeSheetData(postData)
    const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
      user_id: userIdData,
      year: moment(weekStartData).format('YYYY'),
      month: moment(weekStartData).format('M'),
    })

    setCandidate([])
    let month_data = [data.data.data]
    setMonthViewDates(month_data)
    setMonth_View(true)
    if (getWeeks.message == 'Timesheet Rejected Successfully') {
      SuccessToast('Monthly Timesheet Rejected Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    setShowBackDrop(false)
  }

  const allApprove = async (index: any) => {
    setBooks(true)
    setApproveTrue(true)
    setApproveTrue1(true)
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      user_id: candidate[index].timesheet_data[0].user_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      approver_notes: candidate[index].ts_approver_notes
        ? candidate[index].ts_approver_notes
        : 'Approved',
      timesheet_data: [
        {
          timesheet_date: candidate[index]?.timesheet_data[0]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[1]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[2]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[3]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[4]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[5]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[6]?.timesheet_date,
        },
      ],
    }
    // if (candidate[index].ts_approver_notes !== null && candidate[index].ts_approver_notes !== '') {
    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.allTimeSheetInsert(postData)
    setSinleApprovedData(getWeeks.message)
    if (getWeeks.message == 'Timesheet Approved Successfully') {
      SuccessToast('TimeSheet Approved  Successfully')
      for (let i = 0; i < candidate[index]?.timesheet_data?.length; i++) {
        if (candidate[index]?.timesheet_data[i]?.status == 'SUBMITTED') {
          candidate[index].timesheet_data[i].status = 'APPROVED'
          candidate[index].ts_status = 'APPROVED'
        }
      }
      setCandidate([...candidate])
    } else {
      WarnToast('Approver comments is Required')
    }
    const getStatus: any = await Apiservices.editTimesheetData(postData)

    setRejectedDataStatusAll(getStatus.data.data[0]?.ts_status)
    ShowHideApproveAllRejectAllRevertAll(candidate)
    // } else {
    //   WarnToast('Please Enter Approver coments')
    // }
    setShowBackDrop(false)
  }

  const allRevert = async (index: any) => {
    setIndexDataEntireWeek(index)
    setViewShow2(true)
    // if (indexDataEntireWeek) {
    //   setRevertTrue(true)
    //   setApproveTrue(true)
    //   setApproveTrue1(false)
    //   let postData = {
    //     year: weekStartData.slice(0, 4),
    //     month: weekStartData.slice(5, 7),
    //     week_start_date: weekStartData,
    //     week_end_date: weekEndData,
    //     user_id: candidate[index].timesheet_data[0].user_id,
    //     project_id: candidate[index].timesheet_data[0].project_id,
    //     approver_notes: candidate[index].ts_approver_notes,
    //     timesheet_data: [
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[0]?.timesheet_date,
    //       },
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[1]?.timesheet_date,
    //       },
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[2]?.timesheet_date,
    //       },
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[3]?.timesheet_date,
    //       },
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[4]?.timesheet_date,
    //       },
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[5]?.timesheet_date,
    //       },
    //       {
    //         timesheet_date: candidate[index]?.timesheet_data[6]?.timesheet_date,
    //       },
    //     ],
    //   }
    //   // if (candidate[index].ts_approver_notes !== null && candidate[index].ts_approver_notes !== '') {
    //   setShowBackDrop(true)
    //   const getWeeks: any = await Apiservices.revertProjectWiseTimeSheet(postData)
    //   setShowBackDrop(false)
    //   setSinleApprovedData(getWeeks.message)
    //   if (getWeeks.message == 'Timesheet Reverted Successfully') {
    //     SuccessToast('TimeSheet Reverted  Successfully')
    //     for (let i = 0; i < candidate[index]?.timesheet_data?.length; i++) {
    //       if (candidate[index]?.timesheet_data[i]?.status) {
    //         candidate[index].timesheet_data[i].status = 'SUBMITTED'
    //       }
    //     }
    //     setCandidate([...candidate])
    //   } else {
    //     WarnToast('Something Went Wrong')
    //   }
    //   const getStatus: any = await Apiservices.editTimesheetData(postData)
    //   setRevertededDataStatusAll(getStatus.data.data[0]?.ts_status)
    //   ShowHideApproveAllRejectAllRevertAll(candidate)
    // }
    //  else {
    //   WarnToast('Please Enter Approver Comments')
    // }
    setShowBackDrop(false)
    // }
  }
  const allReject = async (index: any) => {
    setRejectTrue(true)
    setApproveTrue(true)
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      user_id: candidate[index].timesheet_data[0].user_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      approver_notes: candidate[index].ts_approver_notes,
      timesheet_data: [
        {
          timesheet_date: candidate[index]?.timesheet_data[0]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[1]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[2]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[3]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[4]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[5]?.timesheet_date,
        },
        {
          timesheet_date: candidate[index]?.timesheet_data[6]?.timesheet_date,
        },
      ],
    }
    if (candidate[index].ts_approver_notes !== null && candidate[index].ts_approver_notes !== '') {
      setShowBackDrop(true)
      const getWeeks: any = await Apiservices.rejectProjectWiseTimeSheet(postData)
      setShowBackDrop(false)
      setSinleApprovedData(getWeeks.message)
      if (getWeeks.message == 'Timesheet Rejected Successfully') {
        SuccessToast('TimeSheet Rejected  Successfully')
        for (let i = 0; i < candidate[index]?.timesheet_data?.length; i++) {
          if (candidate[index]?.timesheet_data[i]?.status == 'SUBMITTED') {
            candidate[index].timesheet_data[i].status = 'REJECTED'
          }
        }
        setCandidate([...candidate])
      } else {
        WarnToast('Something Went Wrong')
      }
      const getStatus: any = await Apiservices.editTimesheetData(postData)

      setRejectedDataStatusAll(getStatus.data.data[0]?.ts_status)
      ShowHideApproveAllRejectAllRevertAll(candidate)
    } else {
      WarnToast('Please Fill Approver Comments')
    }
    setShowBackDrop(false)
  }

  const revertSubmission = async (
    approverComments: any,
    startDate: any,
    endDate: any,
    userId: any
  ) => {
    setViewShow6(true)
    setRevertedStartData(startDate)
    setRevertedEndedData(endDate)
    setRevertedUserIdData(userId)
  }

  const revertSubmissionProject = async (
    approverComments: any,
    startDate: any,
    endDate: any,
    userId: any,
    projectId: any,
    month: any,
    year: any
  ) => {
    setViewShow7(true)
    setRevertedProjectStartData(startDate)
    setRevertedProjectEndedData(endDate)
    setRevertedProjectUserIdData(userId)
    setRevertedProjectProjectIdData(projectId)
    setRevertedProjectMonthData(month)
    setRevertedProjectYearData(year)
  }

  const rejectSubmission = async (
    approverComments: any,
    startDate: any,
    endDate: any,
    userId: any
  ) => {
    setRejectedStartData(startDate)
    setRejectedEndedData(endDate)
    setRejectedUserIdData(userId)
    setViewShow5(true)
  }

  const rejectSubmissionProject = async (
    approverComments: any,
    startDate: any,
    endDate: any,
    userId: any,
    projectId: any,
    month: any,
    year: any
  ) => {
    setRejectedStartDataProject(startDate)
    setRejectedEndedDataProject(endDate)
    setRejectedUserIdDataProject(userId)
    setRejectedProjectIdDataProject(projectId)
    setRejectedProjectDataMonth(month)
    setRejectedProjectDataYear(year)
    setViewShow8(true)
  }
  const allRejectData = async (index: any) => {
    let timesheetsResponse = []
    setShowBackDrop(true)
    if (candidate && candidate.length > 0) {
      for (let each of candidate) {
        timesheetsResponse.push({
          user_id: each.user_id,
          project_id: each.timesheet_data[0].project_id,
          approver_notes: each.ts_approver_notes,
          timesheet_data: each.timesheet_data.map((each: any) => ({
            timesheet_date: each.timesheet_date,
          })),
        })
      }
    }
    let postData = {
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      timesheets: timesheetsResponse,
    }
    if (
      candidate.every((obj: any) => obj.ts_approver_notes !== null && obj.ts_approver_notes !== '')
    ) {
      const getWeeks: any = await Apiservices.rejectAllProjectTimesheets(postData)
      for (let j = 0; j < candidate?.length; j++) {
        for (let i = 0; i < candidate[j]?.timesheet_data?.length; i++) {
          if (candidate[j]?.timesheet_data[i]?.status == 'SUBMITTED') {
            candidate[j].timesheet_data[i].status = 'REJECTED'
          }
        }
      }
      setCandidate([...candidate])
      ShowHideApproveAllRejectAllRevertAll(candidate)
    } else {
      WarnToast('Approver comments is Required')
    }
    setShowBackDrop(false)
  }

  const allRevertData = async (index: any) => {
    setViewShow4(true)
  }

  const ShowHideApproveAllRejectAllRevertAll = (candidate: any) => {
    var forApproveAll = []
    var forRejectAll = []
    var forRevertAll = []
    showApproveReject.length = 0
    showRevertAll.length = 0
    for (let i = 0; i < candidate?.length; i++)
      for (let j = 0; j < candidate[i].timesheet_data?.length; j++) {
        let approveAllTrueFalse = candidate[i].timesheet_data.find(
          (item: any) => item.status == 'SUBMITTED'
        )
          ? true
          : false
        forApproveAll.push(approveAllTrueFalse)
        let revertAllTrueFalse =
          candidate[i].timesheet_data.find((item: any) => item.status == 'APPROVED') ||
          candidate[i].timesheet_data.find((item: any) => item.status == 'REJECTED')
            ? true
            : false
        forRejectAll.push(revertAllTrueFalse)
      }
    setShowApproveReject([...forApproveAll])
    setShowRevertAll([...forRejectAll])
  }
  const openPopup = (e: any, index: any) => {}
  const cancelPopup = () => {
    setQuickbookOpenPop(false)
    setQuickbookShowPop(false)
  }
  const sendToQuickBooks = async (index: any, fromVal: any) => {
    let mondayDate: any = mon.slice(0, 2)
    let mondayMonth: any = mon.slice(3, 5)
    let mondayYear: any = mon.slice(6, 10)
    let tuesdayDate: any = tue.slice(0, 2)
    let tuesdayMonth: any = tue.slice(3, 5)
    let tuesdayYear: any = tue.slice(6, 10)
    let wednesdayDate: any = wed.slice(0, 2)
    let wednesdayMonth: any = wed.slice(3, 5)
    let wednesdayYear: any = wed.slice(6, 10)
    let thursdayDate: any = thu.slice(0, 2)
    let thursdayMonth: any = thu.slice(3, 5)
    let thursdayYear: any = thu.slice(6, 10)
    let fridayDate: any = fri.slice(0, 2)
    let fridayMonth: any = fri.slice(3, 5)
    let fridayYear: any = fri.slice(6, 10)
    let saturdayDate: any = sat.slice(0, 2)
    let saturdayMonth: any = sat.slice(3, 5)
    let saturdayYear: any = sat.slice(6, 10)
    let sundayDate: any = sun.slice(0, 2)
    let sundayMonth: any = sun.slice(3, 5)
    let sundayYear: any = sun.slice(6, 10)
    setindexState(index)
    let postData = {
      user_id: candidate[index].timesheet_data[0].user_id,
      business_unit_id: candidate[index].business_unit_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      user_notes: candidate[index].ts_user_notes,
      approver_id: candidate[index].ts_approver_id,
      approver_notes: candidate[index].ts_approver_notes,
      client_manager_email:
        candidate[index].client_manager_email == null
          ? candidate[index].ts_client_manager_email
          : candidate[index].client_manager_email,
      client_manager_name:
        candidate[index].client_manager_name == null
          ? candidate[index].ts_client_manager_name
          : candidate[index].client_manager_name,
      timesheet_data: [
        {
          timesheet_date: mondayYear + '-' + mondayMonth + '-' + mondayDate,
          qb_timesheet_id:
            candidate[index]?.timesheet_data[0]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[0]?.qb_timesheet_id,
          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[0]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[0]?.qb_ot_timesheet_id,
          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
        {
          timesheet_date: tuesdayYear + '-' + tuesdayMonth + '-' + tuesdayDate,

          qb_timesheet_id:
            candidate[index]?.timesheet_data[1]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[1]?.qb_timesheet_id,
          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[1]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[1]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
        {
          timesheet_date: wednesdayYear + '-' + wednesdayMonth + '-' + wednesdayDate,

          qb_timesheet_id:
            candidate[index]?.timesheet_data[2]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[2]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[2]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[2]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
        {
          timesheet_date: thursdayYear + '-' + thursdayMonth + '-' + thursdayDate,

          qb_timesheet_id:
            candidate[index]?.timesheet_data[3]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[3]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[3]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[3]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
        {
          timesheet_date: fridayYear + '-' + fridayMonth + '-' + fridayDate,

          qb_timesheet_id:
            candidate[index]?.timesheet_data[4]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[4]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[4]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[4]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
        {
          timesheet_date: saturdayYear + '-' + saturdayMonth + '-' + saturdayDate,

          qb_timesheet_id:
            candidate[index]?.timesheet_data[5]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[5]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[5]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[5]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
        {
          timesheet_date: sundayYear + '-' + sundayMonth + '-' + sundayDate,

          qb_timesheet_id:
            candidate[index]?.timesheet_data[6]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[6]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[6]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[6]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
      ],
    }
    if (
      candidate[index].ts_sent_to_quickbook == null ||
      candidate[index].ts_sent_to_quickbook == false
    ) {
      setShowBackDrop(true)
      const quick: any = await Apiservices.quickBooks(postData)
      if (quick.isError == false) {
        toast.success('Timesheet has been sent to QuickBooks')
      } else if (quick?.error?.fault?.type == 'AUTHENTICATION') {
        toast.error('Token expired.please login')
      } else {
        toast.error(quick.message)
      }
      let week = true
      let month = false
      getUserAuth(
        emp_name,
        userIdData,
        weekStartData,
        weekEndData,
        projectIdData,
        BuIndividualRecord,
        week,
        month,
        'fdgdh'
      )
      const response: any = await Apiservices.editTimesheetData({
        user_id: candidate[index].timesheet_data[0].user_id,
        week_start_date: weekStartData,
        week_end_date: weekEndData,
      })
      setShowBackDrop(false)
    }
  }
  const SendToNetSuite = async (index: any) => {
    // let mondayDate: any = mon.slice(0, 2)
    // let mondayMonth: any = mon.slice(3, 5)
    // let mondayYear: any = mon.slice(6, 10)
    // let tuesdayDate: any = tue.slice(0, 2)
    // let tuesdayMonth: any = tue.slice(3, 5)
    // let tuesdayYear: any = tue.slice(6, 10)
    // let wednesdayDate: any = wed.slice(0, 2)
    // let wednesdayMonth: any = wed.slice(3, 5)
    // let wednesdayYear: any = wed.slice(6, 10)
    // let thursdayDate: any = thu.slice(0, 2)
    // let thursdayMonth: any = thu.slice(3, 5)
    // let thursdayYear: any = thu.slice(6, 10)
    // let fridayDate: any = fri.slice(0, 2)
    // let fridayMonth: any = fri.slice(3, 5)
    // let fridayYear: any = fri.slice(6, 10)
    // let saturdayDate: any = sat.slice(0, 2)
    // let saturdayMonth: any = sat.slice(3, 5)
    // let saturdayYear: any = sat.slice(6, 10)
    // let sundayDate: any = sun.slice(0, 2)
    // let sundayMonth: any = sun.slice(3, 5)
    // let sundayYear: any = sun.slice(6, 10)
    setindexState(index)

    const daysOfWeek = [
      {data: mon},
      {data: tue},
      {data: wed},
      {data: thu},
      {data: fri},
      {data: sat},
      {data: sun},
    ]
    let timesheetData: any = []
    for (let i = 0; i < 7; i++) {
      let dayDate = daysOfWeek[i].data.slice(0, 2)
      let dayMonth = daysOfWeek[i].data.slice(3, 5)
      let dayYear = daysOfWeek[i].data.slice(6, 10)
      let regular_hours =
        candidate[index].timesheet_data[i].regular_hours +
        candidate[index].timesheet_data[i].regular_minutes / 60
      let ot_hours =
        candidate[index].timesheet_data[i].ot_hours +
        candidate[index].timesheet_data[i].ot_minutes / 60
      let timesheetData1: any = {
        date: `${dayMonth}/${dayDate}/${dayYear}`,
        regular_hours: regular_hours,
        ot_hours: ot_hours,
      }
      timesheetData.push(timesheetData1)
    }

    let postData = {
      user_id: candidate[index].timesheet_data[0].user_id,
      netsuite_consultant_id: candidate[index].netsuite_consultant_id,
      netsuite_project_id: candidate[index].netsuite_project_id,
      netsuite_item_id: candidate[index].netsuite_item_id,
      netsuite_ot_item_id: candidate[index].netsuite_ot_product_id
        ? candidate[index].netsuite_ot_product_id
        : candidate[index].netsuite_item_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      user_notes: candidate[index].ts_user_notes,
      approver_id: candidate[index].ts_approver_id,
      approver_notes: candidate[index].ts_approver_notes,
      timesheet_data: timesheetData,

      // timesheet_data: [
      //   {
      //     date: mondayMonth + '/' + mondayDate + '/' + mondayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[0].regular_hours +
      //       candidate[index].timesheet_data[0].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[0].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[0].ot_hours +
      //       candidate[index].timesheet_data[0].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[0].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      //   {
      //     date: tuesdayMonth + '/' + tuesdayDate + '/' + tuesdayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[1].regular_hours +
      //       candidate[index].timesheet_data[1].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[1].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[1].ot_hours +
      //       candidate[index].timesheet_data[1].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[1].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      //   {
      //     date: wednesdayMonth + '/' + wednesdayDate + '/' + wednesdayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[2].regular_hours +
      //       candidate[index].timesheet_data[2].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[2].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[2].ot_hours +
      //       candidate[index].timesheet_data[2].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[2].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      //   {
      //     date: thursdayMonth + '/' + thursdayDate + '/' + thursdayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[3].regular_hours +
      //       candidate[index].timesheet_data[3].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[3].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[3].ot_hours +
      //       candidate[index].timesheet_data[3].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[3].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      //   {
      //     date: fridayMonth + '/' + fridayDate + '/' + fridayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[4].regular_hours +
      //       candidate[index].timesheet_data[4].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[4].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[4].ot_hours +
      //       candidate[index].timesheet_data[4].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[4].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      //   {
      //     date: saturdayMonth + '/' + saturdayDate + '/' + saturdayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[5].regular_hours +
      //       candidate[index].timesheet_data[5].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[5].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[5].ot_hours +
      //       candidate[index].timesheet_data[5].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[5].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      //   {
      //     date: sundayMonth + '/' + sundayDate + '/' + sundayYear,
      //     regular_hours:
      //       candidate[index].timesheet_data[6].regular_hours +
      //       candidate[index].timesheet_data[6].regular_minutes / 60,
      //     // regular_minutes: candidate[index].timesheet_data[6].regular_minutes,
      //     ot_hours:
      //       candidate[index].timesheet_data[6].ot_hours +
      //       candidate[index].timesheet_data[6].ot_minutes / 60,
      //     // ot_minutes: candidate[index].timesheet_data[6].ot_minutes,
      //     itemvalue: candidate[index].netsuite_item_id,
      //     ot_itemvalue:candidate[index].netsuite_ot_product_id,
      //   },
      // ],
    }
    if (
      candidate[index].ts_sent_to_netsuite == null ||
      candidate[index].ts_sent_to_netsuite == false
    ) {
      if (
        candidate[index].is_netsuite_update == null ||
        candidate[index].is_netsuite_update == false
      ) {
        setShowBackDrop(true)
        const net_suite: any = await Apiservices.netSuite(postData)
        setShowBackDrop(false)
        if (net_suite.isError == false) {
          toast.success(net_suite.message)
        } else {
          toast.error(net_suite.message)
        }
      } else {
        setShowBackDrop(true)
        const net_suite_update: any = await Apiservices.netSuiteUpdate(postData)
        setShowBackDrop(false)
        if (net_suite_update.isError == false) {
          SuccessToast(net_suite_update.message)
        } else {
          toast.error(net_suite_update.message)
        }
      }
      let week = true
      let month = false
      getUserAuth(
        emp_name,
        userIdData,
        weekStartData,
        weekEndData,
        projectIdData,
        BuIndividualRecord,
        week,
        month,
        'fdgdh'
      )
      const response: any = await Apiservices.editTimesheetData({
        user_id: candidate[index].timesheet_data[0].user_id,
        week_start_date: weekStartData,
        week_end_date: weekEndData,
      })
    }
  }
  const sendToQuickBooksDayLevel = async (index: any, DayIndex: any, DayDate: any) => {
    let date = DayDate.slice(0, 2)
    let month = DayDate.slice(3, 5)
    let year = DayDate.slice(6, 10)
    setindexState(index)

    let postData = {
      user_id: candidate[index].timesheet_data[0].user_id,
      business_unit_id: candidate[index].business_unit_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      year: weekStartData.slice(0, 4),
      month: weekStartData.slice(5, 7),
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      user_notes: candidate[index].ts_user_notes,
      approver_id: candidate[index].ts_approver_id,
      approver_notes: candidate[index].ts_approver_notes,
      client_manager_email:
        candidate[index].client_manager_email == null
          ? candidate[index].ts_client_manager_email
          : candidate[index].client_manager_email,
      client_manager_name:
        candidate[index].client_manager_name == null
          ? candidate[index].ts_client_manager_name
          : candidate[index].client_manager_name,
      timesheet_data: [
        {
          timesheet_date: year + '-' + month + '-' + date,
          qb_timesheet_id:
            candidate[index]?.timesheet_data[DayIndex]?.qb_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[DayIndex]?.qb_timesheet_id,
          qb_ot_timesheet_id:
            candidate[index]?.timesheet_data[DayIndex]?.qb_ot_timesheet_id == undefined
              ? null
              : candidate[index]?.timesheet_data[DayIndex]?.qb_ot_timesheet_id,
          qb_status: null,
          bill_rate: candidate[index]?.bill_rate == null ? null : candidate[index]?.bill_rate,
          pay_rate: candidate[index]?.pay_rate == null ? null : candidate[index]?.pay_rate,
        },
      ],
    }
    if (
      candidate[index]?.timesheet_data[DayIndex]?.sent_to_quickbook == null ||
      candidate[index]?.timesheet_data[DayIndex]?.sent_to_quickbook == false
    ) {
      setShowBackDrop(true)
      const quick: any = await Apiservices.quickBooksDayLevel(postData)
      if (quick.isError == false) {
        toast.success('Timesheet has been sent to QuickBooks')
      } else if (quick?.error?.fault?.type == 'AUTHENTICATION') {
        toast.error('Token expired.please login')
      } else {
        toast.error(quick.message)
      }
      let week = true
      let month = false
      getUserAuth(
        emp_name,
        userIdData,
        weekStartData,
        weekEndData,
        projectIdData,
        BuIndividualRecord,
        week,
        month,
        'fdgdh'
      )
      const response: any = await Apiservices.editTimesheetData({
        user_id: candidate[index].timesheet_data[0].user_id,
        week_start_date: weekStartData,
        week_end_date: weekEndData,
      })
      // setShowBackDrop(false)
    }
  }
  const sendToQuickBooks1 = async (
    full_name: any,
    userId: any,
    weekStart: any,
    weekEnd: any,
    projectId: any,
    buId: any,
    showWeekView: any,
    showMonthView: any,
    index: any
  ) => {
    setProjectIdData(projectId)
    setUserIdData(userId)
    setWeekStartData(weekStart)
    setBuIndividualRecord(buId)
    setWeekEndData(weekEnd)
    setEmpName(full_name)
    setEmpNameProj(full_name)

    let testArr: any = []
    let totalhour_othourArr: any = []
    let totalLeaveArr: any = []

    let orgEmail = localStorage.getItem('user_email')
    const response: any = await Apiservices.editTimesheetData({
      user_id: userId,
      week_start_date: weekStart,
      week_end_date: weekEnd,
    })

    const qb_tranaction: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'send_project_wise_timesheet_to_qb',
      business_unit_id: Number(buId),
    })
    setQBtRANSACTIONSdATA(
      qb_tranaction?.data &&
        qb_tranaction?.data?.data &&
        qb_tranaction?.data?.data[0] &&
        qb_tranaction?.data?.data[0]?.is_enabled
    )
    setTimeClentData(response.data.data)
    if (response.data.data.length > 0) {
      response.data.data?.forEach((obj: any, i: any) => {
        obj.week_day_approve = [false, false, false, false, false, false, false]
        obj.week_day_reject = [false, false, false, false, false, false, false]
        setValue(`details.${i}.ts_client_manager_name`, obj?.ts_client_manager_name)
        setValue(`details.${i}.ts_approver_notes`, obj?.ts_approver_notes)
      })
    }
    let cand =
      response &&
      response.data &&
      response.data.data &&
      response.data.data.length > 0 &&
      response.data.data.filter((filt: any) => filt.id == projectId)
    if (response && response.data && response.data.data) {
      setCandidate(response.data.data)
    } else {
      setCandidate([])
    }
    setClientName(response?.data?.data?.[0][0]?.project_name)
    let imgData: any = []
    if (response && response.data?.data) {
      response?.data?.data?.map(async (each: any, i: any) => {
        const getImages = await Apiservices.docNameIdProjectList1({
          week_start_date: weekStart,
          week_end_date: weekEnd,
          user_id: userId,
        })

        setDocResultDataRes(getImages.data.data)
      })
    }
    let today =
      response &&
      response?.data &&
      response?.data?.data &&
      response?.data?.data?.[0] &&
      response?.data?.data?.[0]?.timesheet_data &&
      response?.data?.data?.[0]?.timesheet_data[0]
    let startOfWeek = today?.week_start_date
    let endOfWeek = today?.week_end_date
    setTimeTracker(moment(startOfWeek))
    let start = moment(startOfWeek).clone().startOf('isoWeek')
    let end = moment(endOfWeek).clone().endOf('isoWeek')
    let days = []
    let day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)

      setWeekDates(arr)
    }
    setMon(
      String(new Date(days[0])?.getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0])?.getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[0])?.getFullYear())
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getFullYear())
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getFullYear())
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getFullYear())
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getFullYear())
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getFullYear())
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getFullYear())
    )
    if (response.data.data?.length > 0) {
      ShowHideApproveAllRejectAllRevertAll(response.data.data)
      let week_dates = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]
      for (let i = 0; i < response.data.data.length; i++) {
        let timesheetWeekData: any = []
        week_dates.forEach((e) => {
          let valuecount = 0
          response.data.data[i].timesheet_data?.forEach((e1: any) => {
            if (e == e1.day_name) {
              valuecount = 1
              timesheetWeekData.push(e1)
            }
          })

          if (valuecount == 0) {
            timesheetWeekData.push({
              day_name: e,
              user_id: response.data.data[i].user_id,
              project_id: response.data.data[i].id,
              ot_hours: response.data.data[i].ot_hours == undefined ? 0 : 0,
              absent_hours: response.data.data[i].absent_hours == undefined ? 0 : 0,
            })
          }
        })
        response.data.data[i].timesheet_data = timesheetWeekData
        setWeekdaysOtHours(response.data.data[i].timesheet_data)
      }
      response.data.data?.forEach((each: any, i: any) => {
        setValue(
          `details.${i}.monday_actual`,
          each.timesheet_data[0].regular_hours == null
            ? '0:00'
            : each.timesheet_data[0].regular_hours +
                ':' +
                (each.timesheet_data[0].regular_minutes <= 9
                  ? '0' + each.timesheet_data[0].regular_minutes
                  : each.timesheet_data[0].regular_minutes)
        )
        setValue(`details.${i}.ts_approver_notes`, each.ts_approver_notes)
        setValue(
          `details.${i}.tuesday_actual`,
          each.timesheet_data[1].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[1]?.regular_hours +
                ':' +
                (each.timesheet_data[1].regular_minutes <= 9
                  ? '0' + each.timesheet_data[1].regular_minutes
                  : each.timesheet_data[1].regular_minutes)
        )
        setValue(
          `details.${i}.wednesday_actual`,
          each.timesheet_data[2].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[2]?.regular_hours +
                ':' +
                (each.timesheet_data[2].regular_minutes <= 9
                  ? '0' + each.timesheet_data[2].regular_minutes
                  : each.timesheet_data[2].regular_minutes)
        )
        setValue(
          `details.${i}.thursday_actual`,
          each.timesheet_data[3].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[3]?.regular_hours +
                ':' +
                (each.timesheet_data[3].regular_minutes <= 9
                  ? '0' + each.timesheet_data[3].regular_minutes
                  : each.timesheet_data[3].regular_minutes)
        )
        setValue(
          `details.${i}.friday_actual`,
          each.timesheet_data[4].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[4]?.regular_hours +
                ':' +
                (each.timesheet_data[4].regular_minutes <= 9
                  ? '0' + each.timesheet_data[4].regular_minutes
                  : each.timesheet_data[4].regular_minutes)
        )
        setValue(
          `details.${i}.saturday_actual`,
          each.timesheet_data[5].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[5]?.regular_hours +
                ':' +
                (each.timesheet_data[5].regular_minutes <= 9
                  ? '0' + each.timesheet_data[5].regular_minutes
                  : each.timesheet_data[5].regular_minutes)
        )
        setValue(
          `details.${i}.sunday_actual`,
          each.timesheet_data[6].regular_hours == null
            ? '0:00'
            : each?.timesheet_data[6]?.regular_hours +
                ':' +
                (each.timesheet_data[6].regular_minutes <= 9
                  ? '0' + each.timesheet_data[6].regular_minutes
                  : each.timesheet_data[6].regular_minutes)
        )
        setValue(`details.${i}.comments`, each?.ts_user_notes)
        setValue(
          `details.${i}.monday_ot`,
          each.timesheet_data[0].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[0]?.ot_hours +
                ':' +
                (each?.timesheet_data[0]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[0]?.ot_minutes
                  : each?.timesheet_data[0]?.ot_minutes)
        )
        setValue(
          `details.${i}.tuesday_ot`,
          each.timesheet_data[1].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[1]?.ot_hours +
                ':' +
                (each?.timesheet_data[1]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[1]?.ot_minutes
                  : each?.timesheet_data[1]?.ot_minutes)
        )
        setValue(
          `details.${i}.wednesday_ot`,
          each.timesheet_data[2].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[2]?.ot_hours +
                ':' +
                (each?.timesheet_data[2]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[2]?.ot_minutes
                  : each?.timesheet_data[2]?.ot_minutes)
        )
        setValue(
          `details.${i}.thursday_ot`,
          each.timesheet_data[3].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[3]?.ot_hours +
                ':' +
                (each?.timesheet_data[3]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[3]?.ot_minutes
                  : each?.timesheet_data[3]?.ot_minutes)
        )
        setValue(
          `details.${i}.friday_ot`,
          each.timesheet_data[4].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[4]?.ot_hours + ':' + each?.timesheet_data[4]?.ot_minutes
        )
        setValue(
          `details.${i}.saturday_ot`,
          each.timesheet_data[5].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[5]?.ot_hours +
                ':' +
                (each?.timesheet_data[5]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[5]?.ot_minutes
                  : each?.timesheet_data[5]?.ot_minutes)
        )
        setValue(
          `details.${i}.sunday_ot`,
          each.timesheet_data[6].ot_hours == null
            ? '0:00'
            : each?.timesheet_data[6]?.ot_hours +
                ':' +
                (each?.timesheet_data[6]?.ot_minutes <= 9
                  ? '0' + each?.timesheet_data[6]?.ot_minutes
                  : each?.timesheet_data[6]?.ot_minutes)
        )
        setValue(
          `details.${i}.monday_leave`,
          each.timesheet_data[0].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[0]?.absent_hours +
                ':' +
                (each?.timesheet_data[0]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[0]?.absent_minutes
                  : each?.timesheet_data[0]?.absent_minutes)
        )
        setValue(
          `details.${i}.tuesday_leave`,
          each.timesheet_data[1].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[1]?.absent_hours +
                ':' +
                (each?.timesheet_data[1]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[1]?.absent_minutes
                  : each?.timesheet_data[1]?.absent_minutes)
        )
        setValue(
          `details.${i}.wednesday_leave`,
          each.timesheet_data[2].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[2]?.absent_hours +
                ':' +
                (each?.timesheet_data[2]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[2]?.absent_minutes
                  : each?.timesheet_data[2]?.absent_minutes)
        )
        setValue(
          `details.${i}.thursday_leave`,
          each.timesheet_data[3].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[3]?.absent_hours +
                ':' +
                (each?.timesheet_data[3]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[3]?.absent_minutes
                  : each?.timesheet_data[3]?.absent_minutes)
        )
        setValue(
          `details.${i}.friday_leave`,
          each.timesheet_data[4].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[4]?.absent_hours +
                ':' +
                (each?.timesheet_data[4]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[4]?.absent_minutes
                  : each?.timesheet_data[4]?.absent_minutes)
        )
        setValue(
          `details.${i}.saturday_leave`,
          each.timesheet_data[5].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[5]?.absent_hours +
                ':' +
                (each?.timesheet_data[5]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[5]?.absent_minutes
                  : each?.timesheet_data[5]?.absent_minutes)
        )
        setValue(
          `details.${i}.sunday_leave`,
          each.timesheet_data[6].absent_hours == 0
            ? '0:00'
            : each?.timesheet_data[6]?.absent_hours +
                ':' +
                (each?.timesheet_data[6]?.absent_minutes <= 9
                  ? '0' + each?.timesheet_data[6]?.absent_minutes
                  : each?.timesheet_data[6]?.absent_minutes)
        )
      })
    }
    if (response.data.data?.length > 0) {
      response.data.data?.forEach((obj: any, i: any) => {
        let data: any = obj?.timesheet_data
        data.forEach((timesheet_data: any, index: any) => {
          if (
            timesheet_data.regular_hours == undefined ||
            timesheet_data.regular_minutes == undefined ||
            timesheet_data.ot_hours == undefined ||
            timesheet_data.ot_minutes == undefined ||
            timesheet_data.absent_hours == undefined ||
            timesheet_data.absent_minutes == undefined
          ) {
            data[index].regular_hours = 0
            data[index].regular_minutes = 0
            data[index].ot_hours = 0
            data[index].ot_minutes = 0
            data[index].absent_hours = 0
            data[index].absent_minutes = 0
          }
        })
        const sumofhour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.regular_hours)
        }, 0)
        const sumofmin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.regular_minutes)
        }, 0)
        const sumofOthour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.ot_hours)
        }, 0)
        const sumofOtmin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.ot_minutes)
        }, 0)
        const sumofLeavehour = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.absent_hours)
        }, 0)
        const sumofLeavemin = data?.reduce(function (acc: any, data: any) {
          return acc + Number(data?.absent_minutes)
        }, 0)
        const total_hour = sumofhour * 60
        const total_ot_hour = sumofOthour * 60
        const total_leave_hour = sumofLeavehour * 60
        const total_minutes = total_hour + sumofmin
        const total_ot_minutes = total_ot_hour + sumofOtmin
        const total_hour_othour = total_minutes + total_ot_minutes
        const th =
          Math.floor(total_hour_othour / 60) <= 9
            ? '0' + Math.floor(total_hour_othour / 60)
            : Math.floor(total_hour_othour / 60)
        const tm =
          total_hour_othour % 60 <= 9 ? '0' + (total_hour_othour % 60) : total_hour_othour % 60
        const total_leave_minutes = total_leave_hour + sumofLeavemin
        const hours = Math.floor(total_minutes / 60)
        const otHours = Math.floor(total_ot_minutes / 60)
        const leaveHours =
          Math.floor(total_leave_minutes / 60) <= 9
            ? '0' + Math.floor(total_leave_minutes / 60)
            : Math.floor(total_leave_minutes / 60)
        const min = total_minutes % 60
        const otMin = total_ot_minutes % 60
        const leaveMin =
          total_leave_minutes % 60 <= 9
            ? '0' + (total_leave_minutes % 60)
            : total_leave_minutes % 60
        const total_sum = `${hours}:${min} + ${otHours}:${otMin}`
        const total_hour_othour_sum = `${th}:${tm}`
        const total_leave_sum = `${leaveHours}:${leaveMin} `
        testArr.push(total_sum)
        totalhour_othourArr.push(total_hour_othour_sum)
        totalLeaveArr.push(total_leave_sum)
        setSumofAllProj(testArr)
        setSumofHour_otHourProject(totalhour_othourArr)
        setSumofAllProjLeave(totalLeaveArr)
      })
    }
    setindexState(index)
    let postData = {
      user_id: cand[0].timesheet_data[0].user_id,
      business_unit_id: cand[0].business_unit_id,
      project_id: cand[0].timesheet_data[0].project_id,
      year: weekStart.slice(0, 4),
      month: weekStart.slice(5, 7),
      week_start_date: weekStart,
      week_end_date: weekEnd,
      user_notes: cand[0].ts_user_notes,
      approver_id: cand[0].ts_approver_id,
      approver_notes: cand[0].ts_approver_notes,
      client_manager_email:
        cand[0].client_manager_email == null
          ? cand[0].ts_client_manager_email
          : cand[0].client_manager_email,
      client_manager_name:
        cand[0].client_manager_name == null
          ? cand[0].ts_client_manager_name
          : cand[0].client_manager_name,
      timesheet_data: [
        {
          timesheet_date:
            String(new Date(days[0])?.getFullYear()) +
            '-' +
            String(new Date(days[0])?.getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[0])?.getDate()).padStart(2, '0'),
          qb_timesheet_id:
            cand[0]?.timesheet_data[0]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[0]?.qb_timesheet_id,
          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[0]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[0]?.qb_ot_timesheet_id,
          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
        {
          timesheet_date:
            String(new Date(days[1]).getFullYear()) +
            '-' +
            String(new Date(days[1]).getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[1]).getDate()).padStart(2, '0'),

          qb_timesheet_id:
            cand[0]?.timesheet_data[1]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[1]?.qb_timesheet_id,
          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[1]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[1]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
        {
          timesheet_date:
            String(new Date(days[2]).getFullYear()) +
            '-' +
            String(new Date(days[2]).getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[2]).getDate()).padStart(2, '0'),

          qb_timesheet_id:
            cand[0]?.timesheet_data[2]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[2]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[2]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[2]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
        {
          timesheet_date:
            String(new Date(days[3]).getFullYear()) +
            '-' +
            String(new Date(days[3]).getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[3]).getDate()).padStart(2, '0'),

          qb_timesheet_id:
            cand[0]?.timesheet_data[3]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[3]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[3]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[3]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
        {
          timesheet_date:
            String(new Date(days[4]).getFullYear()) +
            '-' +
            String(new Date(days[4]).getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[4]).getDate()).padStart(2, '0'),

          qb_timesheet_id:
            cand[0]?.timesheet_data[4]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[4]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[4]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[4]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
        {
          timesheet_date:
            String(new Date(days[5]).getFullYear()) +
            '-' +
            String(new Date(days[5]).getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[5]).getDate()).padStart(2, '0'),

          qb_timesheet_id:
            cand[0]?.timesheet_data[5]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[5]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[5]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[5]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
        {
          timesheet_date:
            String(new Date(days[6]).getFullYear()) +
            '-' +
            String(new Date(days[6]).getMonth() + 1).padStart(2, '0') +
            '-' +
            String(new Date(days[6]).getDate()).padStart(2, '0'),

          qb_timesheet_id:
            cand[0]?.timesheet_data[6]?.qb_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[6]?.qb_timesheet_id,

          qb_ot_timesheet_id:
            cand[0]?.timesheet_data[6]?.qb_ot_timesheet_id == undefined
              ? null
              : cand[0]?.timesheet_data[6]?.qb_ot_timesheet_id,

          qb_status: null,
          bill_rate: cand[0]?.bill_rate == null ? null : cand[0]?.bill_rate,
          pay_rate: cand[0]?.pay_rate == null ? null : cand[0]?.pay_rate,
        },
      ],
    }
    if (cand[0].ts_sent_to_quickbook == null || cand[0].ts_sent_to_quickbook == false) {
      setShowBackDrop(true)
      const quick: any = await Apiservices.quickBooks(postData)
      if (quick.isError == false) {
        toast.success('Timesheet has been sent to QuickBooks')
      } else if (quick?.error?.fault?.type == 'AUTHENTICATION') {
        toast.error('Token expired.please login')
      } else {
        toast.error(quick.message)
      }

      if (projectView && startingMonthView1) {
        setTabName('APPROVED')
        const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
          week_start_date: weekStartDate,
          week_end_date: weekEndDate,
          status: 'APPROVED',
          business_unit_id: cand[0].business_unit_id ? cand[0].business_unit_id : '',
        })
        setWeekDataProjectList(response.data.data)
        // setApproveBtnDisable(true)
        // setMonthOpen(false)
        // setMonthCanDates([])
        setProject_list_Name(response.data.data)
        getProjectList(response.data.data)
      }

      setShowBackDrop(false)
    }
  }

  const sendToQuickBooksProjectMonthLevel = async (
    project_id: any,
    user_id: any,
    business_unit_id: any,
    month: any,
    year: any
  ) => {
    let postData = {
      user_id: user_id,
      business_unit_id: business_unit_id,
      project_id: project_id,
      year: year,
      month: moment(month, 'MMMM').format('M'),
    }
    setShowBackDrop(true)
    const quickProjectMonth: any = await Apiservices.quickBooksProjectWiseMonthly(postData)
    if (quickProjectMonth.isError == false) {
      toast.success('Timesheet has been sent to QuickBooks')
      monthViewStarting1()
    } else if (quickProjectMonth?.error?.fault?.type == 'AUTHENTICATION') {
      toast.error('Token expired.please login')
    } else {
      toast.error(quickProjectMonth.message)
    }
    setShowBackDrop(false)
  }
  const onClickRejectDisableLeave = async (
    index: any,
    day: any,
    date: any,
    weekIndex: any,
    dayNotes: any
  ) => {
    setNotesData(dayNotes)
    let candiateResponse = candidate
    candiateResponse[index].week_day_reject[weekIndex] = true
    candiateResponse[index].timesheet_data[weekIndex].status = 'REJECTED'
    setCandidate([...candiateResponse])
    let partialReject = {
      user_id: candidate[index].timesheet_data[0].user_id,
      project_id: candidate[index].timesheet_data[0].project_id,
      week_start_date: weekStartData,
      week_end_date: weekEndData,
      approver_notes: dayNotes,
      year: weekStartData.slice(0, 4),
      // month: weekStartData.slice(6, 7),
      month: moment(weekStartData).format('M'),
      timesheet_date: date,
    }
    setShowBackDrop(true)
    const getWeeks: any = await Apiservices.rejectProjectWiseSingleDay(partialReject)
    let week = true
    let month = false
    getUserAuth(
      emp_name,
      userIdData,
      weekStartData,
      weekEndData,
      projectIdData,
      BuIndividualRecord,
      week,
      month,
      'fhfghf'
    )
    setShowBackDrop(false)
    setSinleApprovedData(getWeeks.message)
    if (getWeeks.message == 'Timesheet Rejected Successfully') {
      SuccessToast('Rejected  Successfully')
    } else {
      WarnToast('Something Went Wrong')
    }
    // let week = true
    // let month = false

    setMondayNotes('')
    setTuesdayNotes('')
    setWednesdayNotes('')
    setThursdayNotes('')
    setFridayNotes('')
    setSaturdayNotes('')
    setSundayNotes('')
    // setApprover_comments('')
    setValue(`details.${index}.ts_approver_notes`, '')
    ShowHideApproveAllRejectAllRevertAll(candiateResponse)
  }

  const onSubmit = async (data: UserSubmitForm) => {}

  useEffect(() => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    var date: any = moment(new Date())
    const monthNo = moment(seletedDate).month()
    const nextMonth = seletedDate.format('MMMM')
    const nextMonth1 = seletedDate.format('M')
    const nextYear = seletedDate.format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)
    setMonthNumber(monthNo)
    getDays(-1)

    //In useEffect employee view total hours api is called
    const getUsers = async () => {
      let prestartweek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
      let preendweek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')

      // const users = await Apiservices.getTotalHours({
      //   month: datedata3,
      //   year: 2024,
      //   business_unit_id: business2 ? business2 : '',
      // })
      const users = await Apiservices.getTotalHoursByWeek({
        week_start_date: prestartweek,
        week_end_date: preendweek,
      })
      let hoursMinutes =
        users?.data?.data?.SUBMITTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.SUBMITTED?.total_hours_minutes
      let APPROVED =
        users?.data?.data?.APPROVED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.APPROVED?.total_hours_minutes
      let REJECTED =
        users?.data?.data?.REJECTED?.total_hours_minutes == null
          ? '00:00'
          : users?.data?.data?.REJECTED?.total_hours_minutes
      let arr: any = hoursMinutes?.split(':')
      let hour = arr[0]
      let min = arr[1]
      let sec = arr[2] + ' seconds'
      var Hour: any = hour + ':' + min
      let arr1: any = APPROVED.split(':')
      let hour1 = arr1[0]
      let min1 = arr1[1]
      let sec1 = arr1[2] + ' seconds'
      var Hour1: any = hour1 + ':' + min1
      let arr2: any = REJECTED.split(':')
      let hour2 = arr2[0]
      let min2 = arr2[1]
      let sec2 = arr2[2] + ' seconds'
      var Hour2: any = hour2 + ':' + min2
      setTotalHoursYear(Hour)
      setTotalHoursYear1(Hour1)
      setTotalHoursYear2(Hour2)
    }
    getUsers() // run it, run it
    return () => {}
  }, [])

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }
  useEffect(() => {
    renderThings()
    get_business_units_list()
    {
      localStorage.getItem('Announcement') == 'false' && getAnnouncement()
    }
  }, [])
  const AnnouncePop = (value: any) => {
    if (value) {
      localStorage.setItem('Announcement', 'true')
    }
    setAnnouncementOpenPoup(false)
  }
  const getAnnouncement = async () => {
    const res: any = await Apiservices.getLatestAnnouncement()
    if (res && res.data && res.data.data) {
      setAnnouncementList(res.data.data)

      setAnnouncementOpenPoup(true)
    } else {
      setAnnouncementList([])

      setAnnouncementOpenPoup(false)
    }
  }
  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    let arr = []
    if (res && res.status == 200) {
      let business: any = localStorage.getItem('allowed_business_unit_id')
      let numArray: any = business.split(',')
      for (let each of res.data.data) {
        for (let item of numArray) {
          if (each.business_unit_id == Number(item)) {
            arr.push(each)
          }
        }
      }
      if (arr.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        setBusinessUnitsList([obj, ...arr])
      }
      // setBusinessUnitsList(arr)
    }
  }

  // const handleBusinessUnit = async (business_id: any) => {
  //   setValue('business_unit_id', business_id)
  //   setBusinessUnitId(business_id)
  // }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnitId(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnitId(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnitId([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnitId(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            //setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              console.log('length')

              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnitId(selected)
      BusinessUnitsList.pop({
        name: 'Deselect All',
        value: 'empty',
      })
      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  // useEffect(() => {
  //   renderThings1()
  // }, [])
  useEffect(() => {
    setShowBackDrop(true)
    var Today = new Date()
    let date = moment(Today, 'ddd MMM DD YYYY').format('MMMM')
    employeeViewData()
    setMonthViewDates([])
    setShowBackDrop(false)
    getOrganizationSetting() //adp settings api
    // getQBTransactionsSettingByName() //transaction settings for send to quickbooks button
  }, [])

  const getQBTransactionsSettingByName = async () => {
    const res: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'send_project_wise_timesheet_to_qb',
    })
    if (res?.data?.data && res.data.data[0] && res.data.data[0]?.is_enabled == true) {
      setQBtRANSACTIONSdATA(true)
    }
  }

  const getOrganizationSetting = async () => {
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
    // setEnableADPdATA(res.data.data[0].enable_adp)
    if (
      res &&
      res.data &&
      res.data.data &&
      res.data.data[0] &&
      res.data.data[0].enable_adp == true
    ) {
      setEnableADPdATA(true)
    }
    if (res.data.data[0].validate_adp_approver == true) {
      setValidateADPdataApprover(true)
    }
    // if (res.data.data[0].enable_quickbooks == true) {
    //   setValidateQuickBooksdataApprover(true)
    // }
    if (res.data.isError == false) {
      setValidateQuickBooksdataApprover(res.data.data[0].enable_quickbooks)
      setValidateNetSuitedataApprover(res.data.data[0].enable_netsuite)
    }

    // setValidateADPdataApprover(res.data.data[0].validate_adp_approver)
  }

  const checkIfStatusEmpty = (arr: any) => {
    let stat = arr.every((obj: any) => {
      return obj.every((o: any) => o.ts_status === null)
    })
    return stat
  }
  const singleMonthSelection = async (date: any) => {
    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)

    setCalendarView1(true)
    setMonthViewDates([])
    console.log(weekStartDate, 'weekStartDate', userAuth)

    if (employeeView && !startingMonthView) {
      console.log('if 1')
      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpen({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setProject_list(response.data.data)
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet1({
          status: tabName,
          year: yearData,
          month: data3,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            year: moment(weekStartData).format('YYYY'),
            month: data3,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }

        // const response: any = await Apiservices.pendingTimeSheet1({
        //   month: data3,
        //   year: yearData,
        // })

        // setWeekDataProjectList(response.data.data)
        // setProject_list_Name(response?.data?.data)
        // getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.monthViewOpen({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setProject_list(response.data.data)
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet1({
          status: tabName,
          year: yearData,
          month: data3,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            year: moment(weekStartData).format('YYYY'),
            month: data3,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      }
    }

    if (employeeView && monthcalendar && !startingMonthView) {
      console.log('if 2')

      let data3: any = moment(date).format('M')
      const yearData = date.getFullYear()

      console.log('if 1')
      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)

      const response: any = await Apiservices.monthViewOpen({
        status: tabName,
        month: data3,
        year: yearData,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList1(response.data.data)
      setProject_list(response.data.data)
      setWeekDataProjectListMonth(response.data.monthName)
      setWeekDataProjectListYear(response.data.year)
      const response1: any = await Apiservices.pendingTimeSheet1({
        status: tabName,
        month: data3,
        year: yearData,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response1.data.data)
      if (userIdData) {
        const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
          user_id: userIdData,
          month: data3,
          year: yearData,
        })
        setCandidate([])
        let month_data = [data.data.data]
        setMonthViewDates(month_data)
        setMonth_View(true)
      }
    }

    if (projectView && !monthcalendar && !startingMonthView1) {
      console.log('if 3')

      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpenProject({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)

        const response1: any = await Apiservices.pendingTimeSheet2({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response1.data.data)
        setProject_list_Name(response1?.data?.data)
        getProjectList(response1?.data?.data)
      } else {
        const response: any = await Apiservices.monthViewOpenProject({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)

        const response1: any = await Apiservices.pendingTimeSheet2({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response1.data.data)
        setProject_list_Name(response1?.data?.data)
        getProjectList(response1?.data?.data)
      }
    }
    if (projectView && monthcalendar && !startingMonthView1) {
      console.log('if 2')
      let data3: any = moment(date).format('M')
      const yearData = date.getFullYear()

      setCandidate([])
      setMonthViewDates([])
      setMonth_View(false)
      if (tabName == ' ') {
        const response: any = await Apiservices.monthViewOpenProject({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setProject_list(response.data.data)

        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet2({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            month: data3,
            year: yearData,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      } else {
        const response: any = await Apiservices.monthViewOpenProject({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList1(response.data.data)
        setWeekDataProjectListMonth(response.data.monthName)
        setProject_list(response.data.data)

        setWeekDataProjectListYear(response.data.year)
        const response1: any = await Apiservices.pendingTimeSheet2({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })
        setWeekDataProjectList(response1.data.data)
        if (userIdData) {
          const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
            user_id: userIdData,
            month: data3,
            year: yearData,
          })
          setCandidate([])
          let month_data = [data.data.data]
          setMonthViewDates(month_data)
          setMonth_View(true)
        }
      }
    }

    if (!userAuth && !projectView && startingMonthView) {
      console.log('if 4')

      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.pendingTimeSheet1({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingTimeSheet1({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }
    if (!userAuth && projectView && startingMonthView1) {
      console.log('if 5')

      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data = new Date(date).toISOString().slice(0, 10)
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)
      if (tabName == ' ') {
        const response: any = await Apiservices.pendingTimeSheet2({
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      } else {
        const response: any = await Apiservices.pendingTimeSheet2({
          status: tabName,
          month: data3,
          year: yearData,
          business_unit_id: business2 ? business2 : '',
        })

        setWeekDataProjectList(response.data.data)
        setProject_list_Name(response?.data?.data)
        getProjectList(response?.data?.data)
      }
    }
    if (userAuth) {
      console.log('if 6')

      setCalendarView(true)
      let space = ' '
      var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
      const data2 = new Date(date).toISOString().slice(5, 7)
      let data3: any = moment(date).format('M')
      setDateData3(data3)
      setmonthYear(data1)
      var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
      setseletedDate(lastDayOfPreviousMonth)
      const yearData = date.getFullYear()
      setYearData3(yearData)

      const response: any = await Apiservices.pendingTimeSheet1({
        status: tabName,
        month: data3,
        year: yearData,
        business_unit_id: business2 ? business2 : '',
      })

      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)
      setShowWeekView(false)
      setShowMonthView(true)
      setMonthlyView(true)
      const data: any = await Apiservices.consultanttimesheetsbyyearandmonth({
        user_id: userIdData,
        year: moment(weekStartData).format('YYYY'),
        month: data3,
      })

      setCandidate([])
      let month_data = [data.data.data]
      setMonthViewDates(month_data)
      setMonth_View(true)
    }
    let space = ' '
    var monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const data1: any = monthNames[date.getMonth()] + space + date.getFullYear()
    const data2 = new Date(date).toISOString().slice(5, 7)
    let data3: any = moment(date).format('M')
    setDateData3(data3)
    setmonthYear(data1)
    var lastDayOfPreviousMonth = moment(moment(date)).endOf('month')
    setseletedDate(lastDayOfPreviousMonth)
    const yearData = date.getFullYear()
    setYearData3(yearData)

    const nextMonth = moment(date).format('MMMM')
    const nextYear = moment(date).format('YYYY')
    const next: any = `${nextMonth} ${nextYear}`
    setmonthYear(next)
    setMonthName(nextMonth)
    setYearName(nextYear)

    let prestartweek = moment(date).startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment(date).endOf('isoWeek').format('YYYY-MM-DD')

    let start = prestartweek
    let end = preendweek
    setMonthStartDate(start)
    setMonthEndDate(end)
    setWeekStartDate(start)
    setWeekEndDate(end)

    const users = await Apiservices.getTotalHours({
      month: data3,
      year: yearData,
      business_unit_id: business2 ? business2 : '',
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr: any = hoursMinutes?.split(':')
    let hour = arr[0]
    let min = arr[1]
    let sec = arr[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)
    setShowBackDrop(false)
  }

  const singleDateSelection = async (date: any, emp_name: any) => {
    let prestartweek = moment(date).startOf('isoWeek').format('YYYY-MM-DD')
    let preendweek = moment(date).endOf('isoWeek').format('YYYY-MM-DD')
    setEmpName1(emp_name)
    setSumofAllProjLeave([])
    setSumofAllProj([])
    setSumofHour_otHourProject([])
    setCandidate([])
    setMonthWeekView(false)
    setMonthViewDates([])
    var today = moment(date)
    setShowBackDrop(true)
    const data = new Date(date).toISOString().slice(0, 10)
    const users = await Apiservices.getTotalHoursByWeek({
      week_start_date: prestartweek,
      week_end_date: preendweek,
    })
    let hoursMinutes =
      users?.data?.data?.SUBMITTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.SUBMITTED?.total_hours_minutes
    let APPROVED =
      users?.data?.data?.APPROVED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.APPROVED?.total_hours_minutes
    let REJECTED =
      users?.data?.data?.REJECTED?.total_hours_minutes == null
        ? '00:00'
        : users?.data?.data?.REJECTED?.total_hours_minutes
    let arr5: any = hoursMinutes?.split(':')
    let hour = arr5[0]
    let min = arr5[1]
    let sec = arr5[2] + ' seconds'
    var Hour: any = hour + ':' + min
    let arr1: any = APPROVED.split(':')
    let hour1 = arr1[0]
    let min1 = arr1[1]
    let sec1 = arr1[2] + ' seconds'
    var Hour1: any = hour1 + ':' + min1
    let arr2: any = REJECTED.split(':')
    let hour2 = arr2[0]
    let min2 = arr2[1]
    let sec2 = arr2[2] + ' seconds'
    var Hour2: any = hour2 + ':' + min2
    setTotalHoursYear(Hour)
    setTotalHoursYear1(Hour1)
    setTotalHoursYear2(Hour2)

    let business1 = BusinessUnitId.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    // const getWeeksSingleDate: any = await Apiservices.getWeekDaysSingleDate({
    //   date: data ? data : null,
    // })
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    let week1 = moment(startOfWeek, 'YYYY-MM-DD').format('MMM DD')
    let week2 = moment(endOfWeek, 'YYYY-MM-DD').format('MMM DD, YYYY')
    let weeks = `${week1} - ${week2}`
    setStartEnd(weeks)
    setTimeTracker(moment(startOfWeek))
    var start = moment(startOfWeek).clone().startOf('isoWeek')
    var end = moment(endOfWeek).clone().endOf('isoWeek')
    var days = []
    var day: any = start
    while (day.isSameOrBefore(end)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
      setWeekDates(arr)
    }
    let DateArray = arr
    var startdate = moment(days[0], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    var enddate = moment(days[6], 'ddd MMM DD YYYY').format('YYYY-MM-DD')
    setseletedDate(moment(startOfWeek))
    setmonthYear(moment(days[0]).format('MMMM YYYY'))
    if (employeeView) {
      const response: any = await Apiservices.pendingWeekWiseTimeSheet1({
        week_start_date: prestartweek,
        week_end_date: preendweek,
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setWeekDataProjectList(response.data.data)
      setProject_list_Name(response?.data?.data)
      getProjectList(response?.data?.data)

      getUserAuth(
        emp_name,
        userIdData,
        startdate,
        enddate,
        DateArray,
        BuIndividualRecord,
        showWeekView,
        showMonthView,
        'gfjf'
      )
    } else {
      setCandidate([])
      setMonthViewDates([])
      // setMonth_View(false)
      const response: any = await Apiservices.pendingWeekWiseTimeSheet2({
        week_start_date: prestartweek,
        week_end_date: preendweek,
        status: tabName,
        business_unit_id: business2 ? business2 : '',
      })
      setMonthCalendarName(false)
      setWeekDataProjectList(response.data.data)
      // setMonthOpen(false)
      setMonthCanDates([])
      setProject_list_Name(response.data.data)
      getProjectList(response.data.data)
      // setShowWeekView(false)
      // setShowMonthView(true)
      // setMonthlyView(true)

      getUserAuth1(
        emp_name,
        userIdData,
        startdate,
        enddate,
        projectIdData,
        BuIndividualRecord,
        showWeekView,
        showMonthView
      )
    }
    setShowBackDrop(false)

    setMon(
      String(new Date(days[0]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[0]).getMonth() + 1).padStart(2, '0')
    )
    setTue(
      String(new Date(days[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[1]).getMonth() + 1).padStart(2, '0')
    )
    setWed(
      String(new Date(days[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[2]).getMonth() + 1).padStart(2, '0')
    )
    setThu(
      String(new Date(days[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[3]).getMonth() + 1).padStart(2, '0')
    )
    setFri(
      String(new Date(days[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[4]).getMonth() + 1).padStart(2, '0')
    )
    setSat(
      String(new Date(days[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[5]).getMonth() + 1).padStart(2, '0')
    )
    setSun(
      String(new Date(days[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(days[6]).getMonth() + 1).padStart(2, '0')
    )
    return days.join('<br />')
  }

  const getDataFun = async (data: any) => {
    const weekRange = data.dates[0] + '-' + data.dates[6]
    const weekData: any = await Apiservices.weekWiseData({
      week: weekRange,
    })
    setWeekDayWithData(weekData.data.message)
    setWeekDayWithSavedData(weekData.data.data)
  }

  const DateRanges = async (weekNumberInc: number) => {
    let testArr: any = []
    let totalhour_othourArr: any = []
    let totalLeaveArr: any = []
    setMonthViewDates([])
    const getWeeks: any = await Apiservices.getWeekDays({
      week: weekNumberInc ? weekNumberInc : null,
    })
    getDataFun(getWeeks.data)
    setWeekDates(getWeeks.data.dates)
    const weekRange = getWeeks.data.dates[0] + '-' + getWeeks.data.dates[6]
    setWeekRange(weekRange)

    const response: any = await Apiservices.allApproverWeekandStatus({
      week: weekRange ? weekRange : null,
      status: tabName,
    })

    let imgData: any = []
    for (const each of response.data.data) {
      let date3 = each[0].week_start_date
      let date4 = each[0].week_end_date
      const anillll: any = await Apiservices.docNameIdProjectList({
        week_start_date: date3,
        week_end_date: date4,
        project_id: each[0].project_id,
      })
      imgData.push(anillll.data.data ? anillll.data.data : [])
      setImagesArrayRes(imgData)
    }

    setCandidate(response.data.data)
    if (response && response.data && response.data.data && response.data.data.length > 0) {
      response.data.data.forEach((obj: any, i: any) => {
        const sumofhour = obj.reduce(function (acc: any, obj: any) {
          return acc + Number(obj.regular_hours)
        }, 0)
        const sumofmin = obj.reduce(function (acc: any, obj: any) {
          return acc + Number(obj.regular_minutes)
        }, 0)
        const sumofOthour = obj.reduce(function (acc: any, obj: any) {
          return acc + Number(obj.ot_hours)
        }, 0)
        const sumofOtmin = obj.reduce(function (acc: any, obj: any) {
          return acc + Number(obj.ot_minutes)
        }, 0)
        const sumofLeavehour = obj.reduce(function (acc: any, obj: any) {
          return acc + Number(obj.absent_hours)
        }, 0)
        const sumofLeavemin = obj.reduce(function (acc: any, obj: any) {
          return acc + Number(obj.absent_minutes)
        }, 0)
        const total_hour = sumofhour * 60
        const total_ot_hour = sumofOthour * 60
        const total_leave_hour = sumofLeavehour * 60
        const total_minutes = total_hour + sumofmin
        const total_ot_minutes = total_ot_hour + sumofOtmin
        const total_hour_othour = total_minutes + total_ot_minutes
        const th = Math.floor(total_hour_othour / 60)
        const tm = total_hour_othour % 60
        const total_leave_minutes = total_leave_hour + sumofLeavemin
        const hours = Math.floor(total_minutes / 60)
        const otHours = Math.floor(total_ot_minutes / 60)
        const leaveHours = Math.floor(total_leave_minutes / 60)
        const min = total_minutes % 60
        const otMin = total_ot_minutes % 60
        const leaveMin = total_leave_minutes % 60
        const total_sum = `${hours}:${min} + ${otHours}:${otMin}`
        const total_hour_othour_sum = `${th}:${tm}`
        const total_leave_sum = `${leaveHours}:${leaveMin} `
        testArr.push(total_sum)
        totalhour_othourArr.push(total_hour_othour_sum)
        totalLeaveArr.push(total_leave_sum)
        setSumofAllProj(testArr)
        setSumofHour_otHourProject(totalhour_othourArr)
        setSumofAllProjLeave(totalLeaveArr)
        setValue(`details.${i}.employee_name`, obj[0].employee_name)
        setValue(`details.${i}.project_name`, obj[0].project_name)
        setValue(`details.${i}.client_manager_name`, obj[0].client_manager_name)
        setValue(`details.${i}.client_manager_email`, obj[0].client_manager_email)
        setValue(`details.${i}.comments`, obj[0].comments)
        setValue(`details.${i}.ts_approver_notes`, obj[0].ts_approver_notes)
        setValue(`details.${i}.monday_actual`, obj[0].regular_hours + ':' + obj[0].regular_minutes)
        setValue(`details.${i}.monday_leave`, obj[0].absent_hours + ':' + obj[0].absent_minutes)
        setValue(`details.${i}.tuesday_leave`, obj[1].absent_hours + ':' + obj[1].absent_minutes)
        setValue(`details.${i}.wednesday_leave`, obj[2].absent_hours + ':' + obj[2].absent_minutes)
        setValue(`details.${i}.thursday_leave`, obj[3].absent_hours + ':' + obj[3].absent_minutes)
        setValue(`details.${i}.friday_leave`, obj[4].absent_hours + ':' + obj[4].absent_minutes)
        setValue(`details.${i}.saturday_leave`, obj[5].absent_hours + ':' + obj[5].absent_minutes)
        setValue(`details.${i}.sunday_leave`, obj[6]?.absent_hours + ':' + obj[6]?.absent_minutes)
        setValue(`details.${i}.monday_ot`, obj[0].ot_hours + ':' + obj[0].ot_minutes)
        setValue(`details.${i}.tuesday_actual`, obj[1].regular_hours + ':' + obj[1].regular_minutes)
        setValue(
          `details.${i}.wednesday_actual`,
          obj[2].regular_hours + ':' + obj[2].regular_minutes
        )
        setValue(
          `details.${i}.thursday_actual`,
          obj[3].regular_hours + ':' + obj[3].regular_minutes
        )
        setValue(`details.${i}.friday_actual`, obj[4].regular_hours + ':' + obj[4].regular_minutes)
        setValue(
          `details.${i}.saturday_actual`,
          obj[5].regular_hours + ':' + obj[5].regular_minutes
        )
        setValue(
          `details.${i}.sunday_actual`,
          obj[6]?.regular_hours + ':' + obj[6]?.regular_minutes
        )
        setValue(`details.${i}.tuesday_ot`, obj[1].ot_hours + ':' + obj[1].ot_minutes)
        setValue(`details.${i}.wednesday_ot`, obj[2].ot_hours + ':' + obj[2].ot_minutes)
        setValue(`details.${i}.thursday_ot`, obj[3].ot_hours + ':' + obj[3].ot_minutes)
        setValue(`details.${i}.friday_ot`, obj[4].ot_hours + ':' + obj[4].ot_minutes)
        setValue(`details.${i}.saturday_ot`, obj[5].ot_hours + ':' + obj[5].ot_minutes)
        setValue(`details.${i}.sunday_ot`, obj[6]?.ot_hours + ':' + obj[6]?.ot_minutes)
        setValue(`details.${i}.monday_notes`, obj[0].notes)
        setValue(`details.${i}.tuesday_notes`, obj[1].notes)
        setValue(`details.${i}.wednesday_notes`, obj[2].notes)
        setValue(`details.${i}.thursday_notes`, obj[3].notes)
        setValue(`details.${i}.friday_notes`, obj[4].notes)
        setValue(`details.${i}.saturday_notes`, obj[5].notes)
        setValue(`details.${i}.sunday_notes`, obj[6]?.notes)
      })
    }

    const startDay =
      new Date(getWeeks.data.dates[0]).toLocaleString('en-us', {month: 'short'}) +
      ' ' +
      new Date(getWeeks.data.dates[0]).getDate()
    const endDay =
      new Date(getWeeks.data.dates[6]).toLocaleString('en-us', {month: 'short'}) +
      ' ' +
      new Date(getWeeks.data.dates[6]).getDate() +
      ' , ' +
      new Date(getWeeks.data.dates[6]).getFullYear()
    const startEnd = startDay + ' -' + endDay
    setMon(
      String(new Date(response?.data?.data?.[0].week_start_date)?.getDate())?.padStart(2, '0') +
        '/' +
        String(new Date(response?.data?.data?.[0]?.week_start_date)?.getMonth() + 1)?.padStart(
          2,
          '0'
        )
    )
    setTue(
      String(new Date(getWeeks.data.dates[1]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(getWeeks.data.dates[1]).getMonth() + 1).padStart(2, '0')
    )
    setWed(
      String(new Date(getWeeks.data.dates[2]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(getWeeks.data.dates[2]).getMonth() + 1).padStart(2, '0')
    )
    setThu(
      String(new Date(getWeeks.data.dates[3]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(getWeeks.data.dates[3]).getMonth() + 1).padStart(2, '0')
    )
    setFri(
      String(new Date(getWeeks.data.dates[4]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(getWeeks.data.dates[4]).getMonth() + 1).padStart(2, '0')
    )
    setSat(
      String(new Date(getWeeks.data.dates[5]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(getWeeks.data.dates[5]).getMonth() + 1).padStart(2, '0')
    )
    setSun(
      String(new Date(getWeeks.data.dates[6]).getDate()).padStart(2, '0') +
        '/' +
        String(new Date(getWeeks.data.dates[6]).getMonth() + 1).padStart(2, '0')
    )
  }
  const [view_open, setViewOpen] = useState(false)
  const [view_blob, setViewBlob] = useState<any | []>([])
  const [show1, setShow1] = useState(false)
  const [viewshow, setViewShow] = useState(false)
  const downloadFile = (blob: any, index: any) => {
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = blob
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  const viewFile = (blob: any, index: any) => {
    setViewOpen(true)
    setViewBlob(blob)
    setViewShow(true)
  }
  const viewFile1 = (blob: any, index: any) => {
    setViewOpen(true)
    setViewBlob(blob)
    setViewShow3(true)
  }

  const handleCancel = () => {
    setViewShow(false)
  }
  const colors = [
    'text-primary',
    'text-success',
    'text-warning',
    'text-danger',
    'text-info',

    'text-primary',
    'text-success',
    'text-warning',
    'text-danger',
    'text-info',
  ]

  const forBackgroundChange = (id: any, userId: any) => {
    // setshowBgBasedOnUserIdId(true)
    setBgId(id)
    setBgUserId(userId)
  }
  const getRandom = (index: any) => {
    let RandomColor = colors[index % 10]
    let main = RandomColor.split('-')
    let color = main[1]
    let BgColor = 'bg-light-' + color + ' ' + RandomColor
    return BgColor
  }
  const [showHideSearchButton, setShowHideSearchButton] = useState(false)
  const searchShowHide = () => {
    setShowHideSearchButton(!showHideSearchButton)
  }

  let filterData: any =
    weekDataProjectList &&
    weekDataProjectList?.filter((each: any) =>
      each?.full_name?.toLowerCase()?.includes(search?.toLowerCase())
    )
  let filterData1: any =
    weekDataProjectList &&
    weekDataProjectList?.filter((each: any) =>
      each?.project_name?.toLowerCase()?.includes(search?.toLowerCase())
    )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.APPROVER'})}</PageTitle>
      <BackDrop showBackDrop={showBackDrop} />
      {AdpPopupOpen ? (
        <UpdateConsultant
          setAdpPopupOpen={setAdpPopupOpen}
          AdpPopupOpen={AdpPopupOpen}
          cancel={handleCancel1}
          AdpUserId={AdpUserId}
          setAdpUserId={setAdpUserId}
        />
      ) : (
        ''
      )}
      {announcement_open_popup ? (
        <AnnouncementPop
          close={AnnouncePop}
          closeModal={announcement_open_popup}
          setAnnouncementOpenPoup={setAnnouncementOpenPoup}
          announcement_list={announcement_list}
        />
      ) : (
        ''
      )}
      {viewshow5 && (
        <RejectSubmissionPopup
          setConsultant3={setConsultant3}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler5}
          // cancel={handleCancel}
        />
      )}
      {viewshow8 && (
        <RejectProjectSubmissionPopup
          setConsultant8={setConsultant8}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler8}
          // cancel={handleCancel}
        />
      )}
      {viewshow6 && (
        <RevertSubmissionPopup
          setConsultant4={setConsultant4}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler6}
          // cancel={handleCancel}
        />
      )}
      {viewshow7 && (
        <RevertProjectSubmissionPopup
          setConsultant7={setConsultant7}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler7}
          // cancel={handleCancel}
        />
      )}

      {viewshow9 && (
        <RejectMonthPop
          setConsultant9={setConsultant9}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler9}
          // cancel={handleCancel}
        />
      )}

      {viewshow10 && (
        <RevertMonthPop
          setConsultant10={setConsultant10}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler10}
          // cancel={handleCancel}
        />
      )}

      {viewshow11 && (
        <RejectMonthProjectPop
          setConsultant11={setConsultant11}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler11}
          // cancel={handleCancel}
        />
      )}
      {viewshow12 && (
        <RevertMonthProjectPopup
          setConsultant12={setConsultant12}
          // checkAdpData={checkAdpData}
          // setCheckAdpData={setCheckAdpData}
          onCancel={closeModalHandler12}
          // cancel={handleCancel}
        />
      )}
      <div>
        <div className=' mb-4 d-flex flex-wrap justify-content-between gap-3 align-items-center'>
          <div className=''>
            <div className='d-flex gap-3 flex-wrap'>
              <div className='card card-custom card-flush h-100 d-none d-md-block'>
                <div className='card-body p-4 py-5'>
                  <div className='d-flex gap-3'>
                    <div className='col-auto'>
                      <button
                        type='button'
                        className={`  ${
                          employeeView
                            ? `btn btn-sm  btn-primary`
                            : ` btn btn-sm btn-light btn-color-gray-800 btn-active-color-primary`
                        }`}
                        onClick={employeeViewData}
                      >
                        Employee View
                      </button>
                    </div>
                    <div className='col-auto'>
                      <div className='d-flex gap-2 flex-wrap'>
                        <button
                          type='button'
                          className={`  ${
                            projectView
                              ? `btn btn-sm  btn-primary`
                              : ` btn btn-sm btn-light btn-color-gray-800 btn-active-color-primary`
                          }`}
                          onClick={projectViewData}
                        >
                          Project View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {startingDisabled == true && candidate && candidate[0]?.user_full_name && (
                <div className=''>
                  <div className='card card-custom card-flush h-100 d-none d-lg-block'>
                    <div className='card-body h-100 p-4 py-5'>
                      <div className='d-flex  h-100 align-items-center'>
                        <div className='fs-5 fw-regular text-gray-600'>
                          Consultant Name:{' '}
                          <span className='text-gray-800 fw-semibold'>
                            {userName(candidate[0]?.user_full_name)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {startingDisabled == false && (
                <div className=''>
                  <>
                    <div className='card card-custom card-flush h-100'>
                      <div className='card-body p-4 py-5'>
                        <div className=''>
                          <div className='d-flex flex-wrap  align-items-center gap-3 gap-md-1'>
                            <div className=''>
                              <button
                                onClick={pendingTimeSheet}
                                value={pendingTimeSheetClick}
                                className={` ${
                                  tabName === 'SUBMITTED'
                                    ? `btn btn-sm fs-7  p-2   btn-light-warning btn-active-light-warning`
                                    : `btn btn-sm fs-7  p-2   btn-active-light btn-color-gray-600`
                                }`}
                              >
                                Pending
                              </button>
                            </div>
                            <div className=''>
                              <button
                                onClick={rejectTimeSheet}
                                className={` ${
                                  tabName === 'REJECTED'
                                    ? `btn btn-sm fs-7  p-2    btn-light-danger btn-active-light-danger`
                                    : `btn btn-sm fs-7  p-2  btn-active-light btn-color-gray-600`
                                }`}
                              >
                                Rejected
                              </button>
                            </div>
                            <div className=''>
                              <button
                                onClick={approveTimeSheet}
                                className={`   ${
                                  tabName === 'APPROVED'
                                    ? `btn btn-sm fs-7  p-2    btn-light-success btn-active-light-success `
                                    : `btn btn-sm fs-7  p-2   btn-active-light btn-color-gray-600`
                                }`}
                              >
                                Approved
                              </button>
                            </div>
                            <div className=''>
                              <button
                                type='button'
                                onClick={allapproveTimeSheet}
                                className={` ${
                                  tabName === ''
                                    ? `btn btn-sm fs-7  p-2 btn-light-primary btn-active-light-primary`
                                    : `btn btn-sm fs-7  p-2 btn-active-light btn-color-gray-600`
                                }`}
                              >
                                All
                              </button>
                            </div>
                            <div className=''>
                              <div onClick={searchShowHide} className='ms-3 mx-1' role='button'>
                                <i className='bi bi-search'></i>
                              </div>
                            </div>
                            {showHideSearchButton && (
                              <div className=''>
                                <input
                                  type='text'
                                  value={search}
                                  onChange={(event) => setSearch(event.target.value)}
                                  className='form-control p-2 mx-1 rounded-pill'
                                  placeholder='Search'
                                  aria-label='search'
                                  aria-describedby='addon-wrapping'
                                />
                              </div>
                            )}
                            <div className='d-none d-sm-block'>
                              <button
                                type='button'
                                className='btn btn-sm btn-flex btn-light btn-active-primary  '
                                onClick={openTooltip}
                              >
                                {/* <KTSVG
                                  path='/media/icons/duotune/general/gen031.svg'
                                  className='svg-icon-5 svg-icon-gray-500 me-1'
                                /> */}
                                Filter
                              </button>

                              <Popup
                                ref={ref}
                                trigger={<div></div>}
                                position={['top center', 'bottom right', 'bottom left']}
                                closeOnDocumentClick
                                arrow={false}
                              >
                                <div className='w-250px w-md-300px'>
                                  <div className='px-7 py-5'>
                                    <div className='fs-5 text-gray-700 fw-semibold'>
                                      Filter Options
                                    </div>
                                  </div>
                                  <div className='separator border-gray-200'></div>
                                  <div className='px-7 py-5'>
                                    {/* <div onClick={handleFocus} className='mb-7'> */}
                                    <div className='mb-7'>
                                      <label className='form-label'>Business Unit</label>
                                      <div onClick={handleFocus}>
                                        {/* <Typeahead
                                        multiple
                                        id='keep-menu-open'
                                        onChange={(e) => {
                                          handleBusinessUnit(e)
                                          // typeaheadRef4.current.toggleMenu()
                                        }}
                                        options={BusinessUnitsList}
                                        placeholder='Select Business Unit'
                                        // ref={typeaheadRef4}
                                        selected={BusinessUnitId}
                                        labelKey={(BusinessUnitsList: any) =>
                                          BusinessUnitsList.name
                                        }
                                      /> */}
                                        <Typeahead
                                          multiple
                                          id='typeahead-example'
                                          ref={typeaheadRef6}
                                          onChange={handleItemSelection} // Handle item selection
                                          onBlur={(e) => {
                                            handleCloseAllPopups()
                                          }}
                                          options={BusinessUnitsList}
                                          placeholder='Select Business Unit'
                                          selected={BusinessUnitId}
                                          labelKey={(BusinessUnitsList: any) =>
                                            BusinessUnitsList.name
                                          }
                                          open={isMenuOpen} // Control menu visibility
                                        />
                                      </div>
                                    </div>
                                    <div className='d-flex justify-content-end gap-3'>
                                      <button
                                        type='button'
                                        onClick={closeResetTooltip}
                                        className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                                      >
                                        Reset
                                      </button>

                                      <button
                                        type='submit'
                                        className='btn btn-sm btn-primary btn-sm'
                                        onClick={closeTooltip}
                                        data-kt-menu-dismiss='true'
                                      >
                                        Apply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Popup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='border-top '></div>
                  </>
                </div>
              )}
            </div>
          </div>

          <div className=''>
            <div className='card card-custom card-flush h-100'>
              <div className='card-body p-2 py-5 px-4'>
                <div className='row align-items-center h-100 flex-nowrap'>
                  {userAuth !== false && (
                    <>
                      <div className='col-auto d-none d-md-block'>
                        <button
                          type='button'
                          className='btn btn-sm   btn-light  btn-active-color-primary'
                          onClick={() =>
                            monthView(userIdData, emp_name1, emp_nameProj, BuIndividualRecord)
                          }
                        >
                          Month View
                        </button>
                      </div>
                      {/* <div className='col-auto d-block d-md-none ps-1 pe-0'>
                        <button
                          className='btn btn-icon btn-light  btn-active-color-primary btn-sm '
                          onClick={() => monthView('1')}
                        >
                          <i className='bi bi-grid-3x3-gap-fill fs-2'></i>
                        </button>
                      </div> */}
                    </>
                  )}
                  {!projectView && (
                    <>
                      {showingMonthDisable == true && (
                        <>
                          {startingMonthView && (
                            <div className='col-auto'>
                              <button
                                type='button'
                                className='btn btn-sm   btn-light  btn-active-color-primary'
                                onClick={() => monthViewStarting()}
                              >
                                Month View
                              </button>
                            </div>
                          )}

                          {!startingMonthView && (
                            <div className='col-auto'>
                              <button
                                type='button'
                                className='btn btn-sm   btn-light  btn-active-color-primary'
                                onClick={() => weekViewStarting()}
                              >
                                Week View
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {projectViewDisabled && (
                    <>
                      {projectView && (
                        <>
                          {showingMonthDisable1 == true && (
                            <>
                              {startingMonthView1 && (
                                <div className='col-auto'>
                                  <button
                                    type='button'
                                    className='btn btn-sm   btn-light  btn-active-color-primary'
                                    onClick={() => monthViewStarting1()}
                                  >
                                    Month View
                                  </button>
                                </div>
                              )}

                              {!startingMonthView1 && (
                                <div className='col-auto'>
                                  <button
                                    type='button'
                                    className='btn btn-sm   btn-light  btn-active-color-primary'
                                    onClick={() => weekViewStarting1()}
                                  >
                                    Week View
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {MonthCalendarName && (
                    <div className='col-auto'>
                      <div className='d-flex'>
                        <div className='col'>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              onClick={() => {
                                prevMonth()
                              }}
                            >
                              <i className='bi bi-chevron-left'></i>
                            </button>
                            <span className='fs-7 fw-semibold text-gray-700 px-3 dateChangeMinWidth text-center'>
                              {monthYear}
                            </span>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              onClick={() => {
                                nextMonth()
                              }}
                            >
                              <i className='bi bi-chevron-right'></i>
                            </button>
                          </div>
                        </div>
                        <div className='col'>
                          <DatePicker
                            selected={startDate}
                            onChange={(date: any) => singleMonthSelection(date)}
                            customInput={<ExampleCustomInput />}
                            className='tableTdBlueBg'
                            showMonthYearPicker
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!MonthCalendarName && (
                    <div className='col-auto'>
                      <div className='d-flex'>
                        <div className='col'>
                          <div className='d-flex align-items-center'>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              onClick={() => {
                                prevWeek()
                              }}
                            >
                              <i className='bi bi-chevron-left'></i>
                            </button>
                            <span className='fs-7 fw-semibold text-gray-700 px-3 dateChangeMinWidth text-center'>
                              {startEnd}
                            </span>
                            <button
                              className='btn btn-icon btn-primary btn-sm   '
                              onClick={() => {
                                nextWeek()
                              }}
                            >
                              <i className='bi bi-chevron-right'></i>
                            </button>
                          </div>
                        </div>
                        <div className='col'>
                          <DatePicker
                            selected={startDate}
                            onChange={(date: any) => singleWeekSelection(date)}
                            customInput={<ExampleCustomInput />}
                            className='tableTdBlueBg'
                            calendarStartDay={1}
                            // maxDate={addDays(new Date(), disableCalendarDays)}
                            // minDate={subDays(new Date(), disableCalendarDays)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='position-relative '>
          <>
            {startingDisabled && (
              <div className='position-md-fixed position-unset'>
                <div className='card card-custom card-flush h-100 approverSearchCard '>
                  <div className='card-body p-0'>
                    {employeeView || projectView || calendarView ? (
                      <div className='p-3'>
                        <div className='d-flex justify-content-between'>
                          <button
                            onClick={pendingTimeSheet}
                            value={pendingTimeSheetClick}
                            className={` ${
                              tabName === 'SUBMITTED'
                                ? `btn btn-sm fs-7  p-2   text-gray-800 btn-light `
                                : `btn btn-sm fs-7  p-2   btn-active-light btn-color-gray-600`
                            }`}
                          >
                            Pending
                          </button>
                          <button
                            onClick={rejectTimeSheet}
                            className={` ${
                              tabName === 'REJECTED'
                                ? `btn btn-sm fs-7  p-2    text-gray-800 btn-light `
                                : `btn btn-sm fs-7  p-2  btn-active-light btn-color-gray-600`
                            }`}
                          >
                            Rejected
                          </button>
                          <button
                            onClick={approveTimeSheet}
                            className={`   ${
                              tabName === 'APPROVED'
                                ? `btn btn-sm fs-7  p-2    text-gray-800 btn-light   `
                                : `btn btn-sm fs-7  p-2   btn-active-light btn-color-gray-600`
                            }`}
                          >
                            Approved
                          </button>
                          <button
                            type='button'
                            onClick={allapproveTimeSheet}
                            className={` ${
                              tabName === ''
                                ? `btn btn-sm fs-7  p-2 text-gray-800 btn-light `
                                : `btn btn-sm fs-7  p-2 btn-active-light btn-color-gray-600`
                            }`}
                          >
                            All
                          </button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='border-top '></div>
                    <div className='input-group flex-nowrap px-2'>
                      <span
                        className='input-group-text border-0 bg-transparent pe-0'
                        id='addon-wrapping'
                      >
                        <i className='bi bi-search'></i>
                      </span>
                      <input
                        type='text'
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        className='form-control border-0'
                        placeholder='Search'
                        aria-label='search'
                        aria-describedby='addon-wrapping'
                      />
                    </div>

                    <div className='border-top '></div>
                    <div className='approverSearchResult'>
                      {projectView && (
                        <>
                          {project_list && project_list.length > 0 ? (
                            <>
                              {weekDataProjectList
                                ?.filter((value: any) =>
                                  value?.project_name
                                    ?.toLowerCase()
                                    ?.includes(search?.toLowerCase())
                                )
                                ?.map((each: any, index: number) => {
                                  {
                                    /* {weekDataProjectList?.map((each: any, index: number) => { */
                                  }
                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        role='button'
                                        key={index}
                                        className={clsx(
                                          'd-flex p-2 gap-2 align-items-center',
                                          bgId == each.id ? 'bg-gray-200' : ''
                                        )}
                                        onClick={() => {
                                          getUserAuth1(
                                            each.project_name,
                                            each.user_id,
                                            each.week_start_date,
                                            each.week_end_date,
                                            each.project_id,
                                            each.business_unit_id,
                                            showWeekView,
                                            showMonthView
                                          )
                                          forBackgroundChange(each.id, each.user_id)
                                        }}
                                      >
                                        <Tooltip text={each?.project_name}>
                                          <div
                                            className={clsx('d-flex p-2 gap-2 align-items-center')}
                                          >
                                            <div className='col-auto'>
                                              <span
                                                className={clsx(
                                                  ' approverBadge badge    text-capitalize',
                                                  getRandom(index)
                                                )}
                                              >
                                                {each?.project_name?.slice(0, 1)}
                                              </span>
                                            </div>
                                            <div className='col'>
                                              <div
                                                className={
                                                  'text-dark  p-1 fs-7 fw-semibold overflowHidden'
                                                }
                                                style={{textTransform: 'capitalize'}}
                                              >
                                                {each?.project_name}
                                              </div>
                                              <div className='px-1 text-gray-600 fs-8'>
                                                {each.week_start_date &&
                                                  moment(each.week_start_date).format(
                                                    'MM/DD/YYYY'
                                                  )}{' '}
                                                to{' '}
                                                {each.week_end_date &&
                                                  moment(each.week_end_date).format('MM/DD/YYYY')}
                                              </div>
                                            </div>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <div className='border-top '></div>
                                    </React.Fragment>
                                  )
                                })}
                            </>
                          ) : (
                            <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5'>
                              No Records
                            </div>
                          )}
                          {filterData1 && filterData1?.length == 0 && (
                            <div className='text-center  fw-bold text-gray-800'>
                              No Records found{' '}
                            </div>
                          )}
                        </>
                      )}

                      {employeeView && (
                        <>
                          {project_list?.length ? (
                            <>
                              {weekDataProjectList
                                ?.filter((value: any) =>
                                  value?.full_name?.toLowerCase()?.includes(search?.toLowerCase())
                                )
                                .map((each: any, index: number) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        role='button'
                                        key={index}
                                        // className={clsx(
                                        //   'd-flex p-2 gap-2 align-items-center',
                                        //   each.id == bgId ? 'bg-gray-200' : ''
                                        // )}

                                        // className={clsx(
                                        //   'd-flex p-2 gap-2 align-items-center',
                                        //   bgUserId == each.user_id && bgId == each.week_start_date
                                        //     ? 'bg-gray-200'
                                        //     : ''
                                        // )}
                                        className={clsx(
                                          'd-flex p-2 gap-2 align-items-center',
                                          bgUserId == each.user_id ? 'bg-gray-200' : ''
                                        )}
                                        onClick={() => {
                                          getUserAuth(
                                            each.full_name,
                                            each.user_id,
                                            each.week_start_date,
                                            each.week_end_date,
                                            each.project_id,
                                            each.business_unit_id,
                                            showWeekView,
                                            showMonthView,
                                            each.adp_validated
                                          )
                                          // forBackgroundChange(each.id, each.user_id)
                                          forBackgroundChange(each.week_start_date, each.user_id)
                                        }}
                                      >
                                        <Tooltip text={each?.full_name}>
                                          <div
                                            className={clsx('d-flex p-2 gap-2 align-items-center')}
                                          >
                                            <div className='col-auto'>
                                              <span
                                                className={clsx(
                                                  ' approverBadge badge    text-capitalize',
                                                  getRandom(index)
                                                )}
                                              >
                                                {each.full_name.slice(0, 1)}
                                                {each.user_full_name}
                                              </span>
                                            </div>
                                            <div className='col'>
                                              <div
                                                className={'text-dark  p-1 fs-7 fw-semibold '}
                                                style={{textTransform: 'capitalize'}}
                                              >
                                                {each.full_name}
                                              </div>
                                              <div className='px-1 text-gray-500 fs-8'>
                                                {each.week_start_date &&
                                                  moment(each.week_start_date).format(
                                                    'MM/DD/YYYY'
                                                  )}{' '}
                                                to{' '}
                                                {each.week_end_date &&
                                                  moment(each.week_end_date).format('MM/DD/YYYY')}
                                              </div>
                                            </div>
                                          </div>
                                        </Tooltip>
                                      </div>
                                      <div className='border-top '></div>
                                      {weekDataProjectList && weekDataProjectList?.length == 0 && (
                                        <div className='text-center  fw-bold text-gray-800'>
                                          No Records found{' '}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                            </>
                          ) : (
                            <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5'>
                              No Records Found
                            </div>
                          )}
                          {filterData && filterData?.length == 0 && (
                            <div className='text-center  fw-bold text-gray-800'>
                              No Records found{' '}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>

          <div className={clsx(startingDisabled ? 'margin-left-250' : '')}>
            {startingDisabled &&
              (pendingTimeSheetClick == false ? (
                <>
                  <div className={clsx(startingDisabled ? 'ps-5' : '')}>
                    <div className=' row  flex-wrap align-items-center  '>
                      <div className='col-md-6 col-lg-6 col-xl-4 pb-3'>
                        <div className='pending d-flex align-items-center pendingBg'>
                          <div className='d-flex'>
                            <div className='col-auto '>
                              <div className='imgDiv'>
                                <img
                                  src={sandTime}
                                  width='30px'
                                  height='30px'
                                  className='PendingImage'
                                />
                              </div>
                            </div>
                            <div className='col d-flex'>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <div className='fs-5 fw-regular text-white py-1'>
                                    Pending For Approval
                                  </div>
                                  <div className='fs-3 fw-semibold text-white py-2'>
                                    {totalHoursYear}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6 col-lg-6 col-xl-4 pb-3'>
                        <div className='pending d-flex align-items-center rejectBg'>
                          <div className='d-flex'>
                            <div className='col-auto'>
                              <div className='imgDiv'>
                                <img
                                  src={Rejected}
                                  width='30px'
                                  height='30px'
                                  className='PendingImage'
                                />
                              </div>
                            </div>
                            <div className='col d-flex'>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <div className='fs-5 fw-regular text-white py-1'>Rejected</div>
                                  <div className='fs-3 fw-semibold text-white py-2'>
                                    {totalHoursYear2 == null ? '00:00' : totalHoursYear2}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-lg-6 col-xl-4 pb-3'>
                        <div className='pending d-flex align-items-center approveBg'>
                          <div className='d-flex'>
                            <div className='col-auto'>
                              <div className='imgDiv'>
                                <img
                                  src={Approved}
                                  width='30px'
                                  height='30px'
                                  className='PendingImage'
                                />
                              </div>
                            </div>
                            <div className='col d-flex'>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <div className='fs-5 fw-regular text-white py-1'>Approved</div>
                                  <div className='fs-3 fw-semibold text-white py-2'>
                                    {totalHoursYear1 == null ? '00:00' : totalHoursYear1}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              ))}

            {startingDisabled == false && (
              <div className='timesheetScrollContainer heightInc'>
                <div className='card card-custom card-flush w-100  '>
                  <div className='card-body  px-4 py-5'>
                    <div className=' d-none d-md-block'>
                      <div className=' row  flex-wrap align-items-center '>
                        <div className='col-md-6 col-lg-6 col-xl-4 pb-3'>
                          <div className='pending d-flex align-items-center pendingBg'>
                            <div className='d-flex'>
                              <div className='col-auto '>
                                <div className='imgDiv'>
                                  <img
                                    src={sandTime}
                                    width='30px'
                                    height='30px'
                                    className='PendingImage'
                                  />
                                </div>
                              </div>
                              <div className='col d-flex'>
                                <div className='d-flex align-items-center'>
                                  <div>
                                    <div className='fs-5 fw-regular text-white py-1'>
                                      Pending For Approval
                                    </div>
                                    <div className='fs-3 fw-semibold text-white py-2'>
                                      {totalHoursYear}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-6 col-lg-6 col-xl-4 pb-3'>
                          <div className='pending d-flex align-items-center rejectBg'>
                            <div className='d-flex'>
                              <div className='col-auto'>
                                <div className='imgDiv'>
                                  <img
                                    src={Rejected}
                                    width='30px'
                                    height='30px'
                                    className='PendingImage'
                                  />
                                </div>
                              </div>
                              <div className='col d-flex'>
                                <div className='d-flex align-items-center'>
                                  <div>
                                    <div className='fs-5 fw-regular text-white py-1'>Rejected</div>
                                    <div className='fs-3 fw-semibold text-white py-2'>
                                      {totalHoursYear2 == null ? '00:00' : totalHoursYear2}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6 col-lg-6 col-xl-4 pb-3'>
                          <div className='pending d-flex align-items-center approveBg'>
                            <div className='d-flex'>
                              <div className='col-auto'>
                                <div className='imgDiv'>
                                  <img
                                    src={Approved}
                                    width='30px'
                                    height='30px'
                                    className='PendingImage'
                                  />
                                </div>
                              </div>
                              <div className='col d-flex'>
                                <div className='d-flex align-items-center'>
                                  <div>
                                    <div className='fs-5 fw-regular text-white py-1'>Approved</div>
                                    <div className='fs-3 fw-semibold text-white py-2'>
                                      {totalHoursYear1 == null ? '00:00' : totalHoursYear1}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      {startingMonthView1 && (
                        <>
                          {projectView && (
                            <>
                              {project_list && project_list.length > 0 ? (
                                <>
                                  <div>
                                    <div className='row g-4 flex-wrap'>
                                      {weekDataProjectList
                                        ?.filter((value: any) =>
                                          value?.project_name
                                            ?.toLowerCase()
                                            ?.includes(search?.toLowerCase())
                                        )
                                        ?.map((each: any, index: number) => {
                                          let arr =
                                            each &&
                                            each.total_hours_data &&
                                            each?.total_hours_data[0] &&
                                            each?.total_hours_data[0]?.total_hours_minutes &&
                                            each?.total_hours_data[0]?.total_hours_minutes
                                          let arr1 = arr?.split(':')
                                          let arr2 = arr1 && arr1[0]
                                          let arr3 = arr1 && arr1[1]
                                          let arr4 = arr2 + ':' + arr3
                                          return (
                                            <React.Fragment key={index}>
                                              <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
                                                <div
                                                  className={clsx(
                                                    'p-3 fs-5 fw-semibold text-center      text-capitalize approverEmployeeCard',
                                                    getRandom(index)
                                                  )}
                                                >
                                                  <div
                                                    role='button'
                                                    key={index}
                                                    onClick={() => {
                                                      getUserAuth1(
                                                        each.project_name,
                                                        each.user_id,
                                                        each.week_start_date,
                                                        each.week_end_date,
                                                        each.project_id,
                                                        each.business_unit_id,
                                                        showWeekView,
                                                        showMonthView
                                                      )
                                                      forBackgroundChange(each.id, each.user_id)
                                                    }}
                                                  >
                                                    {each.project_name}
                                                    {/* <div className='text-gray-500 fs-7 py-1 '>
                                              {' '}
                                              {each.week_start_date}&nbsp; To &nbsp;
                                              {each.week_end_date}
                                            </div> */}
                                                    <div className='text-gray-800 fs-7 py-1 '>
                                                      {/* {each.is_placement_project
                                                        ? 'Placement'
                                                        : 'Bench'} */}
                                                      {each.is_placement_project
                                                        ? each.is_placement_project == 1
                                                          ? 'Placement'
                                                          : each.is_placement_project == 2
                                                          ? 'Bench'
                                                          : each.is_placement_project == 3
                                                          ? 'Internal'
                                                          : '-'
                                                        : '-'}
                                                    </div>

                                                    <div className='text-gray-500 fs-7 py-1 '>
                                                      {moment(
                                                        each.week_start_date,
                                                        'YYYY/MM/DD'
                                                      ).format('MMM DD ')}
                                                      &nbsp;-&nbsp;
                                                      {moment(
                                                        each.week_end_date,
                                                        'YYYY/MM/DD'
                                                      ).format(' MMM DD, YYYY')}
                                                      &nbsp;&nbsp;
                                                    </div>

                                                    <div className='text-gray-500 fs-7 py-1 '>
                                                      Total Hours:{' '}
                                                      {/* {each &&
                                                        each.total_hours_data &&
                                                        each?.total_hours_data[0] &&
                                                        each?.total_hours_data[0]
                                                          ?.total_hours_minutes &&
                                                        each?.total_hours_data[0]?.total_hours_minutes} */}
                                                      {arr4}
                                                      &nbsp;&nbsp;
                                                    </div>
                                                    <div className='text-gray-500 fs-7 py-1 '>
                                                      Business Unit:{' '}
                                                      {each.project_business_unit_name
                                                        ? each.project_business_unit_name
                                                        : each.user_business_unit_name
                                                        ? each.user_business_unit_name
                                                        : '-'}
                                                      &nbsp;&nbsp;
                                                    </div>

                                                    {tabName == ' ' && (
                                                      <div className='text-gray-500 fs-7 py-1 '>
                                                        {/* Status:"gdfhfdhfgh" */}

                                                        {each.status}
                                                      </div>
                                                    )}

                                                    {tabName == ' ' && (
                                                      <>
                                                        {each.status == 'REJECTED' && (
                                                          <>
                                                            <div className='text-gray-500 fs-9  pt-1'>
                                                              <div className='text-danger'>
                                                                {each.status}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {each.status == 'APPROVED' && (
                                                          <>
                                                            <div className='text-gray-500 fs-9  pt-1'>
                                                              <div className='text-success'>
                                                                {each.status}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {each.status == 'SUBMITTED' && (
                                                          <>
                                                            <div className='text-gray-500 fs-9  pt-1'>
                                                              <div className='text-warning'>
                                                                {'WAITING'}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                  <div className='mt-2'>
                                                    {/* <div className='d-flex justify-content-center align-items-center g-2'> */}
                                                    <div className='d-flex  justify-content-center gap-3 py-1 pt-5'>
                                                      {each.status == 'APPROVED' && (
                                                        <>
                                                          <div>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                revertSubmissionProject(
                                                                  'approver comments',
                                                                  each.week_start_date,
                                                                  each.week_end_date,
                                                                  each.user_id,
                                                                  each.project_id,
                                                                  each.month,
                                                                  each.year
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}

                                                      {localStorage.getItem('refresh_Token') ==
                                                        'false' && (
                                                        <>
                                                          {(numArray?.includes('superadmin') ||
                                                            numArray?.includes('admin') ||
                                                            numArray?.includes('accounts')) && (
                                                            <>
                                                              {each.status == 'APPROVED' && (
                                                                <>
                                                                  {(each.ts_sent_to_quickbook ==
                                                                    null ||
                                                                    each.ts_sent_to_quickbook ==
                                                                      false) && (
                                                                    <>
                                                                      <div>
                                                                        <button
                                                                          disabled={
                                                                            validateQuickBooksdataApprover
                                                                              ? each.is_bu_enabled
                                                                                ? each.is_enabled
                                                                                  ? false
                                                                                  : true
                                                                                : true
                                                                              : true
                                                                          }
                                                                          onClick={() => {
                                                                            sendToQuickBooks1(
                                                                              each.project_name,
                                                                              each.user_id,
                                                                              each.week_start_date,
                                                                              each.week_end_date,
                                                                              each.project_id,
                                                                              each.business_unit_id,
                                                                              showWeekView,
                                                                              showMonthView,
                                                                              index
                                                                            )
                                                                          }}
                                                                          className='btn btn-success btn-sm fs-7  p-2'
                                                                        >
                                                                          Send To QB
                                                                        </button>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {localStorage.getItem('refresh_Token') ==
                                                        'false' && (
                                                        <>
                                                          {(numArray?.includes('superadmin') ||
                                                            numArray?.includes('admin') ||
                                                            numArray?.includes('accounts')) && (
                                                            <>
                                                              {each.status == 'APPROVED' && (
                                                                <>
                                                                  {each.ts_sent_to_quickbook && (
                                                                    <span className='badge badge-light-primary  p-2 px-3 fw-semibold fs-6 '>
                                                                      Submitted to QB
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {each.status == 'REJECTED' && (
                                                        <>
                                                          <div>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                revertSubmissionProject(
                                                                  'approver comments',
                                                                  each.week_start_date,
                                                                  each.week_end_date,
                                                                  each.user_id,
                                                                  each.project_id,
                                                                  each.month,
                                                                  each.year
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}

                                                      {each.status == 'SUBMITTED' && (
                                                        <>
                                                          <div>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                approverSubmissionProject(
                                                                  'approver comments',
                                                                  each.week_start_date,
                                                                  each.week_end_date,
                                                                  each.user_id,
                                                                  each.project_id,
                                                                  1
                                                                )
                                                              }
                                                            >
                                                              <img src={Check} alt='Approve' />
                                                            </button>
                                                          </div>
                                                          <div>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover me-1'
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                rejectSubmissionProject(
                                                                  'approver comments',
                                                                  each.week_start_date,
                                                                  each.week_end_date,
                                                                  each.user_id,
                                                                  each.project_id,
                                                                  each.month,
                                                                  each.year
                                                                )
                                                              }
                                                            >
                                                              <img src={Cross} alt='reject' />{' '}
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}

                                                      {each.employee_type == 'W2' && (
                                                        <>
                                                          {validateADPdataApprover &&
                                                            enableADPdATA &&
                                                            !each.adp_validated && (
                                                              <Tooltip text={' Validate ADP ID'}>
                                                                <Link
                                                                  to='/masters/manageconsultants/updateconsultant'
                                                                  state={{
                                                                    id: each.user_id,
                                                                    view: false,
                                                                    login: 'Approver',
                                                                  }}
                                                                  className=''
                                                                >
                                                                  <button className='p-2 btn btn-sm btn-light-primary'>
                                                                    Validate ADP
                                                                  </button>
                                                                </Link>
                                                              </Tooltip>
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        })}
                                    </div>
                                    {validateADPdataApprover &&
                                      enableADPdATA &&
                                      candidate.every((obj: any) => !obj.adp_validate == false) && (
                                        <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 '>
                                          Note : ADP ID is Mandatory to Approve Timesheet
                                        </div>
                                      )}
                                  </div>
                                </>
                              ) : (
                                <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5'>
                                  {' '}
                                  Projects Not Found
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!startingMonthView1 && (
                        <>
                          {projectView && (
                            <>
                              {project_list && project_list.length > 0 ? (
                                <>
                                  <div>
                                    <div className='row g-4 flex-wrap'>
                                      {weekDataProjectList1
                                        ?.filter((value: any) =>
                                          value?.project_name
                                            ?.toLowerCase()
                                            ?.includes(search?.toLowerCase())
                                        )
                                        ?.map((each: any, index: number) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
                                                <div
                                                  className={clsx(
                                                    'p-3 fs-5 fw-semibold text-center      text-capitalize approverEmployeeCard',
                                                    getRandom(index)
                                                  )}
                                                >
                                                  <div
                                                    role='button'
                                                    key={index}
                                                    onClick={() => {
                                                      monthView(
                                                        each.user_id,
                                                        each.full_name,
                                                        each.project_name,
                                                        each.business_unit_id
                                                      )
                                                      forBackgroundChange(each.id, each.user_id)
                                                    }}
                                                  >
                                                    {each.project_name}
                                                    {/* <div className='text-gray-500 fs-7 py-1 '>
                                              {' '}
                                              {each.week_start_date}&nbsp; To &nbsp;
                                              {each.week_end_date}
                                            </div> */}
                                                    <div className='text-gray-800 fs-7 py-1 '>
                                                      {/* {each.is_placement_project
                                                        ? 'Placement'
                                                        : 'Bench'} */}
                                                      {each.is_placement_project
                                                        ? each.is_placement_project == 1
                                                          ? 'Placement'
                                                          : each.is_placement_project == 2
                                                          ? 'Bench'
                                                          : each.is_placement_project == 3
                                                          ? 'Internal'
                                                          : '-'
                                                        : '-'}
                                                    </div>
                                                    <div className='text-gray-500 fs-7 py-1 '>
                                                      {weekDataProjectListMonth}
                                                      &nbsp;-&nbsp;
                                                      {weekDataProjectListYear}
                                                      &nbsp;&nbsp;
                                                    </div>
                                                    {tabName == ' ' && (
                                                      <div className='text-gray-500 fs-7 py-1 '>
                                                        Status:
                                                        {each.status}
                                                      </div>
                                                    )}
                                                    <div className='text-gray-600 fs-8 '>
                                                      Total Hours: {each.total_hours_minutes}
                                                    </div>
                                                    <div className='text-gray-500 fs-7 py-1 '>
                                                      Business Unit:{' '}
                                                      {each.project_business_unit_name
                                                        ? each.project_business_unit_name
                                                        : each.user_business_unit_name
                                                        ? each.user_business_unit_name
                                                        : '-'}
                                                      &nbsp;&nbsp;
                                                    </div>

                                                    {tabName == ' ' && (
                                                      <>
                                                        {each.status == 'REJECTED' && (
                                                          <>
                                                            <div className='text-gray-500 fs-9  pt-1'>
                                                              <div className='text-danger'>
                                                                {each.status}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {each.status == 'APPROVED' && (
                                                          <>
                                                            <div className='text-gray-500 fs-9  pt-1'>
                                                              <div className='text-success'>
                                                                {each.status}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {each.status == 'SUBMITTED' && (
                                                          <>
                                                            <div className='text-gray-500 fs-9  pt-1'>
                                                              <div className='text-warning'>
                                                                {'WAITING'}
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                  <div className='mt-2'>
                                                    {/* <div className='d-flex justify-content-center align-items-center g-2'> */}
                                                    <div className='d-flex  justify-content-center gap-3 py-1 pt-5'>
                                                      {each.final_status == 'APPROVED' && (
                                                        <>
                                                          <div>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                allRevertedDataMonthProject(
                                                                  weekDataProjectListMonth,
                                                                  weekDataProjectListYear,
                                                                  each.user_id,
                                                                  each.project_id
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}
                                                      {localStorage.getItem('refresh_Token') ==
                                                        'false' && (
                                                        <>
                                                          {(numArray?.includes('superadmin') ||
                                                            numArray?.includes('admin') ||
                                                            numArray?.includes('accounts')) && (
                                                            <>
                                                              {each.final_status == 'APPROVED' && (
                                                                <>
                                                                  {(each.ts_sent_to_quickbook ==
                                                                    null ||
                                                                    each.ts_sent_to_quickbook ==
                                                                      false) && (
                                                                    <>
                                                                      <div>
                                                                        <button
                                                                          disabled={
                                                                            validateQuickBooksdataApprover
                                                                              ? each.is_bu_enabled
                                                                                ? each.is_enabled
                                                                                  ? false
                                                                                  : true
                                                                                : true
                                                                              : true
                                                                          }
                                                                          onClick={() => {
                                                                            sendToQuickBooksProjectMonthLevel(
                                                                              each.project_id,
                                                                              each.user_id,
                                                                              each.business_unit_id,
                                                                              weekDataProjectListMonth,
                                                                              weekDataProjectListYear
                                                                            )
                                                                          }}
                                                                          className='btn btn-success btn-sm fs-7  p-2'
                                                                        >
                                                                          Send To QB
                                                                        </button>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {localStorage.getItem('refresh_Token') ==
                                                        'false' && (
                                                        <>
                                                          {(numArray?.includes('superadmin') ||
                                                            numArray?.includes('admin') ||
                                                            numArray?.includes('accounts')) && (
                                                            <>
                                                              {each.final_status == 'APPROVED' && (
                                                                <>
                                                                  {each.ts_sent_to_quickbook && (
                                                                    <span className='badge badge-light-primary  p-2 px-3 fw-semibold fs-6 '>
                                                                      Submitted to QB
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}

                                                      {each.final_status == 'REJECTED' && (
                                                        <>
                                                          <div>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                allRevertedDataMonthProject(
                                                                  weekDataProjectListMonth,
                                                                  weekDataProjectListYear,
                                                                  each.user_id,
                                                                  each.project_id
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}

                                                      {each.final_status == 'SUBMITTED' && (
                                                        <>
                                                          <div>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                allApprovedDataMonthProject(
                                                                  weekDataProjectListMonth,
                                                                  weekDataProjectListYear,
                                                                  each.user_id,
                                                                  each.project_id
                                                                )
                                                              }
                                                            >
                                                              <img src={Check} alt='Approve' />
                                                            </button>
                                                          </div>
                                                          <div>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover me-1'
                                                              disabled={
                                                                validateADPdataApprover &&
                                                                enableADPdATA &&
                                                                each.employee_type == 'W2' &&
                                                                !each.adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                allRejectedDataMonthProject(
                                                                  weekDataProjectListMonth,
                                                                  weekDataProjectListYear,
                                                                  each.user_id,
                                                                  each.project_id
                                                                )
                                                              }
                                                            >
                                                              <img src={Cross} alt='reject' />{' '}
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}

                                                      {each.employee_type == 'W2' && (
                                                        <>
                                                          {validateADPdataApprover &&
                                                            enableADPdATA &&
                                                            !each.adp_validated && (
                                                              <Tooltip text={' Validate ADP ID'}>
                                                                <Link
                                                                  to='/masters/manageconsultants/updateconsultant'
                                                                  state={{
                                                                    id: each.user_id,
                                                                    view: false,
                                                                    login: 'Approver',
                                                                  }}
                                                                  className=''
                                                                >
                                                                  <button className='p-2 btn btn-sm btn-light-primary'>
                                                                    Validate ADP
                                                                  </button>
                                                                </Link>
                                                              </Tooltip>
                                                            )}
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )
                                        })}
                                    </div>
                                    {validateADPdataApprover &&
                                      enableADPdATA &&
                                      candidate.every((obj: any) => !obj.adp_validate == false) && (
                                        <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 '>
                                          Note : ADP ID is Mandatory to Approve Timesheet
                                        </div>
                                      )}
                                  </div>
                                </>
                              ) : (
                                <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5'>
                                  {' '}
                                  Projects Not Found
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {startingMonthView && (
                        <>
                          {employeeView && (
                            <>
                              {project_list?.length ? (
                                <div>
                                  <div className='row g-4 flex-wrap'>
                                    {weekDataProjectList
                                      ?.filter((value: any) =>
                                        value?.full_name
                                          ?.toLowerCase()
                                          ?.includes(search?.toLowerCase())
                                      )
                                      .map((each: any, index: number) => {
                                        return (
                                          <>
                                            <React.Fragment key={index}>
                                              {startingDisabled == false && (
                                                <>
                                                  <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
                                                    <div
                                                      className={clsx(
                                                        'p-3 fs-5 fw-semibold text-center text-capitalize approverEmployeeCard',
                                                        getRandom(index)
                                                      )}
                                                    >
                                                      <div
                                                        role='button'
                                                        // className="badge "

                                                        onClick={() => {
                                                          getUserAuth(
                                                            each.full_name,
                                                            each.user_id,
                                                            each.week_start_date,
                                                            each.week_end_date,
                                                            each.project_id,
                                                            each.business_unit_id,
                                                            showWeekView,
                                                            showMonthView,
                                                            each.adp_validated
                                                          )
                                                          // forBackgroundChange(each.id, each.user_id)

                                                          forBackgroundChange(
                                                            each.week_start_date,
                                                            each.user_id
                                                          )
                                                        }}
                                                      >
                                                        {each.full_name}

                                                        <div className='text-gray-500 fs-7 py-1 '>
                                                          {moment(
                                                            each.week_start_date,
                                                            'YYYY/MM/DD'
                                                          ).format('MMM DD ')}
                                                          &nbsp;-&nbsp;
                                                          {moment(
                                                            each.week_end_date,
                                                            'YYYY/MM/DD'
                                                          ).format(' MMM DD, YYYY')}
                                                          &nbsp;&nbsp;
                                                        </div>

                                                        {tabName == ' ' && (
                                                          <div className='text-gray-500 fs-7 py-1 '>
                                                            Status:
                                                            {each.final_status}
                                                          </div>
                                                        )}
                                                        <div className='text-gray-600 fs-8 '>
                                                          Total Hours:{' '}
                                                          {each &&
                                                            // each?.total_hours_minutes.slice(0, 5)}
                                                            each?.total_hours_minutes}
                                                        </div>
                                                        <div className='text-gray-500 fs-7 py-1 '>
                                                          Business Unit:{' '}
                                                          {each.project_business_unit_name
                                                            ? each.project_business_unit_name
                                                            : each.user_business_unit_name
                                                            ? each.user_business_unit_name
                                                            : '-'}
                                                          &nbsp;&nbsp;
                                                        </div>
                                                        {tabName == ' ' && (
                                                          <>
                                                            {each.status == 'REJECTED' && (
                                                              <>
                                                                <div className='text-gray-500 fs-9  pt-1'>
                                                                  <div className='text-danger'>
                                                                    {each.status}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            )}
                                                            {each.status == 'APPROVED' && (
                                                              <>
                                                                <div className='text-gray-500 fs-9  pt-1'>
                                                                  <div className='text-success'>
                                                                    {each.status}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            )}
                                                            {each.status == 'SUBMITTED' && (
                                                              <>
                                                                <div className='text-gray-500 fs-9  pt-1'>
                                                                  <div className='text-warning'>
                                                                    {'WAITING'}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                      {
                                                        <>
                                                          <div className='mt-2'>
                                                            {each.final_status == 'SUBMITTED' && (
                                                              <div className='d-flex justify-content-center align-items-center g-2'>
                                                                <div>
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                    disabled={
                                                                      validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      each.employee_type == 'W2' &&
                                                                      !each.adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onClick={() =>
                                                                      approverSubmission(
                                                                        'approver comments',
                                                                        each.week_start_date,
                                                                        each.week_end_date,
                                                                        each.user_id,
                                                                        each.adp_validated
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={Check}
                                                                      alt='Approve'
                                                                    />
                                                                  </button>
                                                                </div>

                                                                <div>
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                    disabled={
                                                                      validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      each.employee_type == 'W2' &&
                                                                      !each.adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onClick={() =>
                                                                      rejectSubmission(
                                                                        'approver comments',
                                                                        each.week_start_date,
                                                                        each.week_end_date,
                                                                        each.user_id
                                                                      )
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                </div>

                                                                {each.employee_type == 'W2' && (
                                                                  <>
                                                                    {validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      !each.adp_validated && (
                                                                        <Tooltip
                                                                          text={' Validate ADP ID'}
                                                                        >
                                                                          <div>
                                                                            <Link
                                                                              to='/masters/manageconsultants/updateconsultant'
                                                                              state={{
                                                                                id: each.user_id,
                                                                                view: false,
                                                                                login: 'Approver',
                                                                              }}
                                                                              className=''
                                                                            >
                                                                              <div>
                                                                                <button className='p-2 btn btn-sm btn-light-primary'>
                                                                                  Validate ADP
                                                                                </button>
                                                                              </div>
                                                                            </Link>
                                                                          </div>
                                                                        </Tooltip>
                                                                      )}
                                                                  </>
                                                                )}
                                                              </div>
                                                            )}

                                                            {each.final_status == 'APPROVED' && (
                                                              <>
                                                                <div className='d-flex justify-content-center align-items-center g-2'>
                                                                  <div>
                                                                    <button
                                                                      className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      disabled={
                                                                        validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        each.employee_type ==
                                                                          'W2' &&
                                                                        !each.adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        revertSubmission(
                                                                          'approver comments',
                                                                          each.week_start_date,
                                                                          each.week_end_date,
                                                                          each.user_id
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {each.employee_type == 'W2' && (
                                                                    <>
                                                                      {validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        !each.adp_validated && (
                                                                          <Link
                                                                            to='/masters/manageconsultants/updateconsultant'
                                                                            state={{
                                                                              id: each.user_id,
                                                                              view: false,
                                                                              login: 'Approver',
                                                                            }}
                                                                            className=''
                                                                          >
                                                                            <div>
                                                                              <button className='p-2 btn btn-sm btn-light-primary'>
                                                                                Validate ADP
                                                                              </button>
                                                                            </div>
                                                                          </Link>
                                                                        )}
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}

                                                            {each.final_status == 'REJECTED' && (
                                                              <>
                                                                <div className='d-flex justify-content-center align-items-center g-2'>
                                                                  <div>
                                                                    <button
                                                                      className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      disabled={
                                                                        validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        each.employee_type ==
                                                                          'W2' &&
                                                                        !each.adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        revertSubmission(
                                                                          'approver comments',
                                                                          each.week_start_date,
                                                                          each.week_end_date,
                                                                          each.user_id
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {each.employee_type == 'W2' && (
                                                                    <>
                                                                      {validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        !each.adp_validated && (
                                                                          <Link
                                                                            to='/masters/manageconsultants/updateconsultant'
                                                                            state={{
                                                                              id: each.user_id,
                                                                              view: false,
                                                                              login: 'Approver',
                                                                            }}
                                                                            className=''
                                                                          >
                                                                            <div>
                                                                              <button className='p-2 btn btn-sm btn-light-primary'>
                                                                                Validate ADP
                                                                              </button>
                                                                            </div>
                                                                          </Link>
                                                                        )}
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                        </>
                                                      }
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </React.Fragment>
                                          </>
                                        )
                                      })}
                                  </div>
                                  {validateADPdataApprover &&
                                    enableADPdATA &&
                                    candidate.every((obj: any) => !obj.adp_validate == false) && (
                                      <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 '>
                                        Note : ADP ID is Mandatory to Approve Timesheet
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 '>
                                  Employees Not Found
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {!startingMonthView && (
                        <>
                          {employeeView && (
                            <>
                              {project_list?.length ? (
                                <div>
                                  <div className='row g-4 flex-wrap'>
                                    {weekDataProjectList1
                                      ?.filter((value: any) =>
                                        value?.full_name
                                          ?.toLowerCase()
                                          ?.includes(search?.toLowerCase())
                                      )
                                      .map((each: any, index: number) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {startingDisabled == false && (
                                              <>
                                                <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
                                                  <div
                                                    className={clsx(
                                                      'p-3 fs-5 fw-semibold text-center text-capitalize approverEmployeeCard',
                                                      getRandom(index)
                                                    )}
                                                  >
                                                    <div
                                                      role='button'
                                                      // className="badge "

                                                      onClick={() => {
                                                        monthView(
                                                          each.user_id,
                                                          each.full_name,
                                                          each.project_name,
                                                          each.business_unit_id
                                                        )
                                                        forBackgroundChange(each.id, each.user_id)
                                                      }}
                                                    >
                                                      {each.full_name}

                                                      <div className='text-gray-500 fs-7 py-1 '>
                                                        {/* {moment(
                                                        each.week_start_date,
                                                        'YYYY/MM/DD'
                                                      ).format('MMM DD ')} */}
                                                        {weekDataProjectListMonth}
                                                        &nbsp;-&nbsp;
                                                        {weekDataProjectListYear}
                                                        &nbsp;&nbsp;
                                                      </div>

                                                      {tabName == ' ' && (
                                                        <div className='text-gray-500 fs-7 py-1 '>
                                                          Status:
                                                          {each.final_status}
                                                        </div>
                                                      )}
                                                      <div className='text-gray-600 fs-8 '>
                                                        Total Hours:{' '}
                                                        {each &&
                                                          // each?.total_hours_minutes.slice(0, 5)}
                                                          each?.total_hours_minutes}
                                                      </div>
                                                      <div className='text-gray-500 fs-7 py-1 '>
                                                        Business Unit:{' '}
                                                        {each.project_business_unit_name
                                                          ? each.project_business_unit_name
                                                          : each.user_business_unit_name
                                                          ? each.user_business_unit_name
                                                          : '-'}
                                                        &nbsp;&nbsp;
                                                      </div>

                                                      {tabName == ' ' && (
                                                        <>
                                                          {each.status == 'REJECTED' && (
                                                            <>
                                                              <div className='text-gray-500 fs-9  pt-1'>
                                                                <div className='text-danger'>
                                                                  {each.status}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {each.status == 'APPROVED' && (
                                                            <>
                                                              <div className='text-gray-500 fs-9  pt-1'>
                                                                <div className='text-success'>
                                                                  {each.status}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                          {each.status == 'SUBMITTED' && (
                                                            <>
                                                              <div className='text-gray-500 fs-9  pt-1'>
                                                                <div className='text-warning'>
                                                                  {'WAITING'}
                                                                </div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                    {
                                                      <>
                                                        <div className='mt-2'>
                                                          {each.final_status == 'SUBMITTED' && (
                                                            <div className='d-flex justify-content-center align-items-center g-2'>
                                                              <div>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                  disabled={
                                                                    validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    each.employee_type == 'W2' &&
                                                                    !each.adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    approverSubmission1(
                                                                      weekDataProjectListMonth,
                                                                      weekDataProjectListYear,
                                                                      each.user_id
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Check} alt='Approve' />
                                                                </button>
                                                              </div>

                                                              <div>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                  disabled={
                                                                    validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    each.employee_type == 'W2' &&
                                                                    !each.adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    allRejcetedDataMonth(
                                                                      weekDataProjectListMonth,
                                                                      weekDataProjectListYear,
                                                                      each.user_id
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Cross} alt='reject' />{' '}
                                                                </button>
                                                              </div>

                                                              {each.employee_type == 'W2' && (
                                                                <>
                                                                  {validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    !each.adp_validated && (
                                                                      <Tooltip
                                                                        text={' Validate ADP ID'}
                                                                      >
                                                                        <Link
                                                                          to='/masters/manageconsultants/updateconsultant'
                                                                          state={{
                                                                            id: each.user_id,
                                                                            view: false,
                                                                            login: 'Approver',
                                                                          }}
                                                                          className=''
                                                                        >
                                                                          <div>
                                                                            <button className='p-2 btn btn-sm btn-light-primary'>
                                                                              Validate ADP
                                                                            </button>
                                                                          </div>
                                                                        </Link>
                                                                      </Tooltip>
                                                                    )}
                                                                </>
                                                              )}
                                                            </div>
                                                          )}

                                                          {each.final_status == 'APPROVED' && (
                                                            <>
                                                              <div className='d-flex justify-content-center align-items-center g-2'>
                                                                <div>
                                                                  <button
                                                                    className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      each.employee_type == 'W2' &&
                                                                      !each.adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onClick={() =>
                                                                      allRevertedDataMonth(
                                                                        weekDataProjectListMonth,
                                                                        weekDataProjectListYear,
                                                                        each.user_id
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={Revert}
                                                                      alt='Revert'
                                                                    />
                                                                  </button>
                                                                </div>
                                                                {each.employee_type == 'W2' && (
                                                                  <>
                                                                    {validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      !each.adp_validated && (
                                                                        <Link
                                                                          to='/masters/manageconsultants/updateconsultant'
                                                                          state={{
                                                                            id: each.user_id,
                                                                            view: false,
                                                                            login: 'Approver',
                                                                          }}
                                                                          className=''
                                                                        >
                                                                          <div>
                                                                            <button className='p-2 btn btn-sm btn-light-primary'>
                                                                              Validate ADP
                                                                            </button>
                                                                          </div>
                                                                        </Link>
                                                                      )}
                                                                  </>
                                                                )}
                                                              </div>
                                                            </>
                                                          )}

                                                          {each.final_status == 'REJECTED' && (
                                                            <>
                                                              <div className='d-flex justify-content-center align-items-center g-2'>
                                                                <div>
                                                                  <button
                                                                    className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      each.employee_type == 'W2' &&
                                                                      !each.adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    onClick={() =>
                                                                      allRevertedDataMonth(
                                                                        weekDataProjectListMonth,
                                                                        weekDataProjectListYear,
                                                                        each.user_id
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      src={Revert}
                                                                      alt='Revert'
                                                                    />
                                                                  </button>
                                                                </div>
                                                                {each.employee_type == 'W2' && (
                                                                  <>
                                                                    {validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      !each.adp_validated && (
                                                                        <Link
                                                                          to='/masters/manageconsultants/updateconsultant'
                                                                          state={{
                                                                            id: each.user_id,
                                                                            view: false,
                                                                            login: 'Approver',
                                                                          }}
                                                                          className=''
                                                                        >
                                                                          <div>
                                                                            <button className='p-2 btn btn-sm btn-light-primary'>
                                                                              Validate ADP
                                                                            </button>
                                                                          </div>
                                                                        </Link>
                                                                      )}
                                                                  </>
                                                                )}
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                      </>
                                                    }
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </React.Fragment>
                                        )
                                      })}
                                  </div>
                                  {validateADPdataApprover &&
                                    enableADPdATA &&
                                    candidate.every((obj: any) => !obj.adp_validate == false) && (
                                      <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 '>
                                        Note : ADP ID is Mandatory to Approve Timesheet
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <div className='text-center  fw-semi-bold text-gray-700 fs-7 pt-5 '>
                                  Employees Not Found
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {pendingTimeSheetClick == true ? (
              <>
                <div className='d-flex gap-2 flex-wrap justify-content-between ps-0 ps-md-5 pt-5 pt-md-0'>
                  {pendingTimeSheetClick == true ? (
                    <>
                      <div className='col'>
                        <div className='d-flex gap-2 flex-wrap gap-3'>
                          {month_open && <></>}
                          {monthCanDates &&
                            monthCanDates?.length > 0 &&
                            monthCanDates?.map((value: any, index: any) => (
                              <button
                                key={index}
                                type='button'
                                className=' btn btn-sm text-nowrap    bg-body  btn-active-color-primary fw-normal btn-color-warning'
                                onClick={() =>
                                  singleDateSelection(
                                    new Date(value.timesheet_date.slice(0, 10)),
                                    value.full_name
                                  )
                                }
                              >
                                {moment(new Date(value.timesheet_date.slice(0, 10))).format(
                                  'DD MMM, YYYY'
                                )}
                                &nbsp;TO&nbsp;
                                {moment(new Date(value.timesheet_date.slice(21, 31))).format(
                                  'DD MMM, YYYY'
                                )}
                              </button>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            {startingDisabled && (
              <>
                {monthlyView && (
                  <>
                    {month_view
                      ? month_view_dates &&
                        month_view_dates.length > 0 &&
                        month_view_dates.map((list: any, index: any) => (
                          <>
                            {!checkIfStatusEmpty(month_view_dates) ? (
                              <div className=' ps-5' key={index}>
                                <div
                                  className={
                                    list.find((item: any) => item.ts_status == 'SUBMITTED')
                                      ? 'timesheetScrollContainer ApproverCalWithButton test2'
                                      : 'timesheetScrollContainer ApproverCalWithOutButton test2'
                                  }
                                >
                                  <div className='card shadow-sm '>
                                    <div className='card shadow-sm '>
                                      <div className='monthName'>
                                        <div className='text-center bg-light-primary text-primary fw-semibold fs-2 calMonth'>
                                          <span>
                                            {projectView ? emp_nameProj : userName(emp_name1)}
                                            <br />
                                            {moment(list[15]?.timesheet_date)?.format('MMMM')}{' '}
                                            {/* {weekStartData?.slice(0, 4)} */}
                                            {moment(list[15]?.timesheet_date)?.format('YYYY')}
                                            <br />
                                          </span>
                                        </div>
                                      </div>
                                      <div className='calMonthView'>
                                        <table className='table table-row-bordered'>
                                          <thead>
                                            <tr>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                MON
                                              </th>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                TUE
                                              </th>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                WED
                                              </th>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                THU
                                              </th>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                FRI
                                              </th>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                SAT
                                              </th>
                                              <th className='fs-6 py-3 fw-normal text-gray-600  text-center'>
                                                SUN
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className='text-center'>
                                            {list &&
                                              list.length > 0 &&
                                              list?.map((temp: any, index: any) => (
                                                <React.Fragment key={index}>
                                                  {index % 7 == 0 ? (
                                                    <tr key={index}>
                                                      {list.length > 0 &&
                                                        list
                                                          .slice(index, index + 7)
                                                          .map((temp: any, indexTwo: any) => (
                                                            <td key={indexTwo}>
                                                              <div className='col-auto'>
                                                                <Popup
                                                                  trigger={
                                                                    <a
                                                                      // href='#'
                                                                      onClick={() =>
                                                                        singleDateSelection(
                                                                          temp.timesheet_date,
                                                                          temp.full_name
                                                                        )
                                                                      }
                                                                      role='button'
                                                                    >
                                                                      <div className='fs-6 py-1 fw-normal text-gray-700  '>
                                                                        {/* {temp.timesheet_date} */}
                                                                        {moment(
                                                                          temp.timesheet_date
                                                                        ).format('DD')}
                                                                      </div>

                                                                      <div className='fs-7  monthHours fw-semibold'>
                                                                        {temp.regular_hours_minutes}{' '}
                                                                        +{temp.ot_hours_minutes}
                                                                      </div>
                                                                      <div className='fs-9  fw-regular text-gray-400  text-center'>
                                                                        {temp.absent_hours_minutes}
                                                                      </div>

                                                                      <div
                                                                        className={clsx(
                                                                          'fs-7 fw-semibold  bg-light py-2 ',
                                                                          temp.ts_status ==
                                                                            'APPROVED'
                                                                            ? 'text-success'
                                                                            : temp.ts_status ==
                                                                              'REJECTED'
                                                                            ? 'text-danger'
                                                                            : temp.ts_status ==
                                                                              'SUBMITTED'
                                                                            ? 'text-warning'
                                                                            : temp.ts_status ==
                                                                              'SAVED'
                                                                            ? 'text-primary'
                                                                            : 'text-gray-600'
                                                                        )}
                                                                      >
                                                                        {temp.ts_status ==
                                                                        'APPROVED'
                                                                          ? 'Approved'
                                                                          : temp.ts_status ==
                                                                            'REJECTED'
                                                                          ? 'Rejected'
                                                                          : temp.ts_status ==
                                                                            'SUBMITTED'
                                                                          ? 'Waiting'
                                                                          : temp.ts_status ==
                                                                            'SAVED'
                                                                          ? 'Draft'
                                                                          : 'No Entry'}
                                                                      </div>
                                                                    </a>
                                                                  }
                                                                  on={['hover', 'focus']}
                                                                  // position='bottom center'
                                                                  // arrow={false}
                                                                  // on='hover'
                                                                  keepTooltipInside={true}
                                                                >
                                                                  <div>
                                                                    {temp.project_wise_data &&
                                                                      temp.project_wise_data.map(
                                                                        (
                                                                          project_name: any,
                                                                          index: any
                                                                        ) => (
                                                                          <div className='p-3'>
                                                                            <div
                                                                              className='text-gray-900 fs-7 fw-semibold'
                                                                              style={{
                                                                                textTransform:
                                                                                  'capitalize',
                                                                              }}
                                                                            >
                                                                              Project Name:
                                                                              {
                                                                                project_name.project_name
                                                                              }
                                                                            </div>
                                                                            <div className='text-gray-900 fs-7 fw-semibold'>
                                                                              Total Hours:{' '}
                                                                              <span className='fs-7  monthHours fw-semibold'>
                                                                                {project_name.regular_hours <=
                                                                                9
                                                                                  ? '0' +
                                                                                    project_name.regular_hours
                                                                                  : project_name.regular_hours}
                                                                                :
                                                                                {project_name.regular_minutes <=
                                                                                9
                                                                                  ? '0' +
                                                                                    project_name.regular_minutes
                                                                                  : project_name.regular_minutes}{' '}
                                                                                +
                                                                                {project_name.ot_hours <=
                                                                                9
                                                                                  ? '0' +
                                                                                    project_name.ot_hours
                                                                                  : project_name.ot_hours}
                                                                                :
                                                                                {project_name.ot_minutes <=
                                                                                9
                                                                                  ? '0' +
                                                                                    project_name.ot_minutes
                                                                                  : project_name.ot_minutes}
                                                                              </span>
                                                                            </div>
                                                                            <div className='text-gray-900 fs-7 fw-semibold'>
                                                                              Status:{' '}
                                                                              <span
                                                                                className={clsx(
                                                                                  'fs-7 monthHours fw-semibold',
                                                                                  project_name.status ==
                                                                                    'APPROVED'
                                                                                    ? 'text-success'
                                                                                    : project_name.status ==
                                                                                      'REJECTED'
                                                                                    ? 'text-danger'
                                                                                    : project_name.status ==
                                                                                      'SUBMITTED'
                                                                                    ? 'text-warning'
                                                                                    : project_name.status ==
                                                                                      'SAVED'
                                                                                    ? 'text-primary'
                                                                                    : 'text-gray-600'
                                                                                )}
                                                                              >
                                                                                {project_name.status ==
                                                                                'APPROVED'
                                                                                  ? 'Approved'
                                                                                  : project_name.status ==
                                                                                    'REJECTED'
                                                                                  ? 'Rejected'
                                                                                  : project_name.status ==
                                                                                    'SUBMITTED'
                                                                                  ? 'Waiting'
                                                                                  : project_name.status ==
                                                                                    'SAVED'
                                                                                  ? 'Draft'
                                                                                  : 'No Entry'}
                                                                              </span>
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                    {(temp.project_wise_data ==
                                                                      '' ||
                                                                      temp.project_wise_data ==
                                                                        null) && (
                                                                      <div className='text-gray-900 fs-7 fw-semibold p-5'>
                                                                        No Data Found
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </Popup>{' '}
                                                              </div>
                                                            </td>
                                                          ))}
                                                    </tr>
                                                  ) : (
                                                    ''
                                                  )}
                                                </React.Fragment>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {list.find((item: any) => item.ts_status == 'SUBMITTED') && (
                                  <div className='d-flex justify-content-end gap-3 pt-5'>
                                    <div>
                                      <button
                                        onClick={() => {
                                          const index = 0
                                          allApproveMonthData(index)
                                        }}
                                        className='btn btn-success btn-sm'
                                      >
                                        Approve
                                      </button>
                                    </div>

                                    <div>
                                      <button
                                        onClick={() => {
                                          const index = 0

                                          allRejectMonthData(index)
                                        }}
                                        className='btn btn-danger btn-sm'
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className='text-center  fw-semi-bold text-gray-600 fs-5'>
                                No Records on this Month
                              </div>
                            )}
                          </>
                        ))
                      : ''}
                  </>
                )}
              </>
            )}

            {startingDisabled == false && (
              <>
                {employeeView && (
                  <>
                    {filterData && filterData?.length == 0 && (
                      <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                    )}
                  </>
                )}
              </>
            )}
            {startingDisabled == false && (
              <>
                {projectView && (
                  <>
                    {filterData1 && filterData1?.length == 0 && (
                      <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                    )}
                  </>
                )}
              </>
            )}
            {pendingTimeSheetClick == true ? (
              <>
                <>
                  {timeClientData == null ? (
                    <div className='text-center  fw-semi-bold text-gray-600 fs-5'>
                      No Records on this Dates
                    </div>
                  ) : (
                    ''
                  )}
                </>
                <div className='ps-0 ps-md-5 pt-5 pt-md-0'>
                  <div
                    className={
                      (candidate &&
                        candidate?.length > 0 &&
                        candidate.every((obj: any) => obj.employee_type == 'W2')) ||
                      (candidate && candidate?.length == 1)
                        ? 'timesheetScrollContainer ApproverMultiWithoutAll test3'
                        : 'timesheetScrollContainer ApproverMultiWithAll  test3'
                    }
                  >
                    {candidate &&
                      candidate?.length > 0 &&
                      candidate?.map((value: any, index: any) => {
                        return (
                          <>
                            {quickbook_show_pop ? (
                              <ModalPop
                                quickbook_show_pop={quickbook_show_pop}
                                setQuickbookShowPop={setQuickbookShowPop}
                                setQuickbookOpenPop={setQuickbookOpenPop}
                                quickbook_open_pop={quickbook_open_pop}
                                cancelPopup={cancelPopup}
                                openPopup={openPopup}
                                index={indexState}
                              />
                            ) : (
                              ''
                            )}
                            <div
                              className={clsx(
                                ' d-flex',
                                candidate?.length !== index + 1 ? ' mb-5' : ''
                              )}
                              key={index}
                            >
                              <div className='card card-custom card-flush w-100'>
                                <div className='card-body p-0'>
                                  <div className='row align-items-center g-2'>
                                    <div className='col-xs-12  col-lg-10'>
                                      <div className='row row-cols-xs-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 px-6 pt-6 align-items-center gy-2'>
                                        <div className='col overflow-hidden'>
                                          <div className='overflowHidden fs-7 text-gray-600 fw-regular'>
                                            Consultant Name
                                          </div>

                                          <div
                                            className=' overflowHidden text-dark  fs-5 fw-semibold text-nowrap'
                                            style={{textTransform: 'capitalize'}}
                                          >
                                            {value.user_full_name}
                                          </div>
                                        </div>
                                        {/* <div className='col overflow-hidden'>
                                          <div className='overflowHidden fs-7 text-gray-600 fw-regular'>
                                            Project Name
                                          </div>
                                          <div
                                            className=' overflowHidden text-dark  fs-5 fw-semibold text-nowrap'
                                            style={{textTransform: 'capitalize'}}
                                          >
                                            <Tooltip
                                              text={value?.project_name ? value?.project_name : '-'}
                                            >
                                              <span>{value?.project_name}</span>
                                            </Tooltip>
                                          </div>
                                        </div> */}
                                        <div className='col overflow-hidden'>
                                          <div className='overflowHidden fs-7 text-gray-600 fw-regular'>
                                            Project Name
                                          </div>
                                          <div
                                            className=' overflowHidden text-dark  fs-5 fw-semibold text-nowrap'
                                            style={{textTransform: 'capitalize'}}
                                          >
                                            <Tooltip
                                              text={
                                                value?.direct_customer_engagement
                                                  ? value?.end_customer_name
                                                  : value?.project_vendors &&
                                                    value?.project_vendors[0] &&
                                                    value?.project_vendors[0].client_name &&
                                                    value?.project_vendors[0].client_name
                                              }
                                            >
                                              <span>
                                                {value?.direct_customer_engagement
                                                  ? value?.end_customer_name
                                                  : value?.project_vendors &&
                                                    value?.project_vendors[0] &&
                                                    value?.project_vendors[0].client_name &&
                                                    value?.project_vendors[0].client_name}
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className='col overflow-hidden'>
                                          <div className='overflowHidden fs-7 text-gray-600 fw-regular'>
                                            {' '}
                                            Manager Name
                                          </div>

                                          <div className='overflowHidden text-dark  fs-5 fw-semibold text-nowrap'>
                                            {value?.ts_client_manager_name}
                                          </div>
                                        </div>
                                        <div className='col overflow-hidden'>
                                          <div className='overflowHidden fs-7 text-gray-600 fw-regular'>
                                            Manager Email
                                          </div>

                                          <div className='overflowHidden text-dark  fs-5 fw-semibold text-nowrap text-transform-unset'>
                                            {value?.ts_client_manager_email}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-xs-12 col-lg-2'>
                                      {value?.is_primary_project ? (
                                        <span
                                          className={
                                            'badge badge-light-primary  p-2 px-3 fw-semibold fs-6 mt-5 ms-5 '
                                          }
                                        >
                                          Primary
                                        </span>
                                      ) : (
                                        <span
                                          className={
                                            'badge badge-light-danger  p-2 px-3 fw-semibold fs-6 mt-5 ms-5 '
                                          }
                                        >
                                          Secondary
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className='border-top mt-3'></div>
                                  <Accordion defaultActiveKey='0' flush>
                                    <Accordion.Item eventKey='0'>
                                      <Accordion.Header className='accordianHeaderButton'>
                                        <div className='w-100'>
                                          <div className='d-flex flex-wrap gap-3 justify-content-between  align-items-center'>
                                            <div className=''>
                                              <div className='fs-6 fw-semibold text-gray-900'>
                                                {moment(mon, 'DD/MM/YYYY').format('MMM DD ')}
                                                &nbsp;-&nbsp;
                                                {moment(sun, 'DD/MM/YYYY').format(' MMM DD, YYYY')}
                                                &nbsp;&nbsp;
                                              </div>
                                            </div>
                                            <div className='d-none d-md-block'>
                                              <span className='me-5'>
                                                <span className='fs-6 fw-semibold text-gray-600'>
                                                  Start Date :
                                                  {candidate[index]?.joining_date ? (
                                                    <span className='fs-6 fw-semibold text-gray-900'>
                                                      {' '}
                                                      {moment(
                                                        candidate[index]?.joining_date,
                                                        'YYYY/MM/DD'
                                                      ).format(' MMM DD, YYYY')}
                                                    </span>
                                                  ) : (
                                                    <span className='fs-6 fw-semibold text-gray-900'>
                                                      {' '}
                                                      {moment(
                                                        candidate[index]?.start_date,
                                                        'YYYY/MM/DD'
                                                      ).format(' MMM DD, YYYY')}
                                                    </span>
                                                  )}
                                                </span>
                                              </span>
                                            </div>
                                            <div>
                                              <span className='me-5 d-none d-md-inline-block'>
                                                <span className='fs-6 fw-semibold text-gray-600'>
                                                  Total Hours:{' '}
                                                </span>
                                                <span className='fs-6 fw-semibold text-gray-900'>
                                                  {sumofHour_otHourProject[index]}
                                                </span>
                                              </span>
                                            </div>
                                            <div className=' '>
                                              <div className='me-2'>
                                                {localStorage.getItem('refresh_Token') ==
                                                  'false' && (
                                                  <>
                                                    {(numArray?.includes('admin') ||
                                                      numArray?.includes('accounts')) && (
                                                      <>
                                                        {candidate[index].ts_status ==
                                                          'APPROVED' && (
                                                          <>
                                                            {candidate[index]
                                                              .ts_sent_to_netsuite && (
                                                              <span className='fs-8 text-primary fw-bold fw-regular mt-1 p-2 text-right'>
                                                                Submitted to NetSuite
                                                              </span>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    {(numArray?.includes('admin') ||
                                                      numArray?.includes('accounts')) && (
                                                      <>
                                                        {candidate[index].ts_status ==
                                                          'APPROVED' && (
                                                          <>
                                                            {candidate[index]
                                                              .ts_sent_to_netsuite && (
                                                              <>
                                                                {candidate[index]
                                                                  .ts_sent_to_quickbook && (
                                                                  <>{'|'}</>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    {(numArray?.includes('superadmin') ||
                                                      numArray?.includes('admin') ||
                                                      numArray?.includes('accounts')) && (
                                                      <>
                                                        {candidate[index].ts_status ==
                                                          'APPROVED' && (
                                                          <>
                                                            {candidate[index]
                                                              .ts_sent_to_quickbook && (
                                                              // <span className='badge badge-light-primary  p-2 px-3 fw-semibold fs-6'>
                                                              //   Submitted to QB
                                                              // </span>
                                                              <span className='fs-8 text-primary fw-bold fw-regular mt-1 p-2 text-right'>
                                                                Submitted to QB
                                                              </span>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                {/* <span className='me-5 d-none d-md-inline-block'>
                                                  <span className='fs-6 fw-semibold text-gray-600'>
                                                    Total Hours:{' '}
                                                  </span>
                                                  <span className='fs-6 fw-semibold text-gray-900'>
                                                    {sumofHour_otHourProject[index]}
                                                  </span>
                                                </span> */}
                                                {candidate[index]?.timesheet_data?.find(
                                                  (item: any) => item.status == ''
                                                ) ? (
                                                  <span
                                                    className={
                                                      'badge badge-light-primary  p-2 px-3 fw-semibold fs-6'
                                                    }
                                                  >
                                                    Yet to submit
                                                  </span>
                                                ) : candidate[index]?.timesheet_data?.find(
                                                    (item: any) => item?.status == 'REJECTED'
                                                  ) ? (
                                                  <span
                                                    className={
                                                      'badge badge-light-danger  p-2 px-3 fw-semibold fs-6'
                                                    }
                                                  >
                                                    Rejected
                                                  </span>
                                                ) : candidate[index]?.timesheet_data?.find(
                                                    (item: any) => item?.status == 'SAVE'
                                                  ) ? (
                                                  <span
                                                    className={
                                                      'badge badge-light-primary  p-2 px-3 fw-semibold fs-6'
                                                    }
                                                  >
                                                    Yet to submit
                                                  </span>
                                                ) : candidate[index]?.timesheet_data?.find(
                                                    (item: any) => item?.status == 'REJECTED'
                                                  ) ? (
                                                  <span
                                                    className={
                                                      'badge badge-light-danger  p-2 px-3 fw-semibold fs-6'
                                                    }
                                                  >
                                                    Rejected
                                                  </span>
                                                ) : candidate[index]?.timesheet_data?.find(
                                                    (item: any) => item?.status == 'SUBMITTED'
                                                  ) ? (
                                                  <span
                                                    className={
                                                      'badge badge-light-warning  p-2 px-3 fw-normal fs-6'
                                                    }
                                                  >
                                                    Waiting For Approval
                                                  </span>
                                                ) : (
                                                  <span
                                                    className={
                                                      'badge badge-light-success  p-2 px-3 fw-semibold fs-6'
                                                    }
                                                  >
                                                    Approved
                                                  </span>
                                                )}
                                              </div>
                                            </div>

                                            {approvedDataStatusAll}
                                          </div>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body className='p-0'>
                                        <div>
                                          <div className='border-top '></div>

                                          <div className='  px-6'>
                                            <div className='table-responsive timesheetEntry disabledColorForApprover approverTableTdAlignTop'>
                                              <table className='w-100'>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3 ',
                                                        value?.timesheet_data === null
                                                          ? 'tableTdYellowBg'
                                                          : value?.timesheet_data[0]
                                                              ?.absent_type === 1
                                                          ? 'tableTdYellowBg'
                                                          : value?.timesheet_data[0]
                                                              ?.absent_type === 2
                                                          ? 'tableTdPinkBg'
                                                          : value?.timesheet_data[0]
                                                              ?.absent_type === 'null'
                                                          ? 'tableTdBlueBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          MON:{mon ? mon?.slice(0, 5) : ''}
                                                        </label>
                                                        {value?.timesheet_data[0]?.status && (
                                                          <button
                                                            className='btn btn-icon   btn-active-color-light buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                value?.id,
                                                                value?.timesheet_data[0]
                                                                  ?.week_start_date,
                                                                value?.timesheet_data[0]
                                                                  ?.week_end_date,
                                                                mon
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        {value?.timesheet_data[0]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        {value?.timesheet_data[0]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        <div className='invisibleBlock'></div>
                                                      </div>
                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              {...register(
                                                                `details.${index}.monday_actual`
                                                              )}
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[0]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              aria-label='Hours'
                                                            />

                                                            {(candidate[index]?.timesheet_data[0]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[0]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body disabledBg   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.monday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>

                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.monday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div> </div>
                                                      </div>

                                                      <div className='d-flex justify-content-center gap-1 py-2 '>
                                                        {candidate[index]?.timesheet_data[0]
                                                          ?.status && (
                                                          <div className='col-auto'></div>
                                                        )}
                                                        {value?.timesheet_data[0]?.status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[0]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'monday_approve',
                                                                    value?.timesheet_data[0]
                                                                      ?.timesheet_date,
                                                                    0
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>
                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[0]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                onClose={() => {
                                                                  if (monday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'monday_reject',
                                                                      value?.timesheet_data[0]
                                                                        ?.timesheet_date,
                                                                      0,
                                                                      monday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.monday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={monday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {/* {(value.timesheet_data[0]?.status ==
                                                          'APPROVED' ||
                                                          value.timesheet_data[0]?.status ==
                                                            'REJECTED') && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                              // disabled={
                                                              //   value?.timesheet_data[0]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[0]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'monday_revert',
                                                                  value?.timesheet_data[0]
                                                                    ?.timesheet_date,
                                                                  0
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )} */}

                                                        {value.timesheet_data[0]?.status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                              // disabled={
                                                              //   value?.timesheet_data[0]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[0]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'monday_revert',
                                                                  value?.timesheet_data[0]
                                                                    ?.timesheet_date,
                                                                  0
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      {value?.timesheet_data[0]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[0]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                          // disabled={
                                                                          //   value?.timesheet_data[0]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[0]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'monday_revert',
                                                                              value
                                                                                ?.timesheet_data[0]
                                                                                ?.timesheet_date,
                                                                              0
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[0]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[0]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                          // disabled={
                                                                          //   value?.timesheet_data[0]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[0]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'monday_revert',
                                                                              value
                                                                                ?.timesheet_data[0]
                                                                                ?.timesheet_date,
                                                                              0
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>

                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[0]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[0]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'monday_revert',
                                                                                value
                                                                                  ?.timesheet_data[0]
                                                                                  ?.timesheet_date,
                                                                                0
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>
                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        {/* </div>
                                                                      <div className='d-flex  justify-content-center gap-3 py-1 pt-5'> */}
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                0,
                                                                                mon
                                                                              )
                                                                            }}
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            {' '}
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[0]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'monday_revert',
                                                                          value?.timesheet_data[0]
                                                                            ?.timesheet_date,
                                                                          0
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}

                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[0]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'monday_revert',
                                                                      value?.timesheet_data[0]
                                                                        ?.timesheet_date,
                                                                      0
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[0]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[0]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3 ',
                                                        value.timesheet_data[1]?.absent_type === 1
                                                          ? 'tableTdYellowBg'
                                                          : value.timesheet_data[1]?.absent_type ===
                                                            2
                                                          ? 'tableTdPinkBg'
                                                          : value.timesheet_data[1]?.absent_type ===
                                                            'null'
                                                          ? 'tableTdBlueBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          TUE:{tue ? tue.slice(0, 5) : ''}
                                                        </label>
                                                        {value?.timesheet_data[1]?.status && (
                                                          <button
                                                            className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                value.id,
                                                                value.timesheet_data[0]
                                                                  .week_start_date,
                                                                value.timesheet_data[0]
                                                                  .week_end_date,
                                                                tue
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        {value.timesheet_data[1]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                        {value.timesheet_data[1]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        <div className='invisibleBlock'></div>
                                                      </div>
                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              // className='form-control px-1 my-2 fs-6 formControlMinWidth90'
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[1]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              {...register(
                                                                `details.${index}.tuesday_actual`
                                                              )}
                                                            />
                                                            {(candidate[index]?.timesheet_data[1]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[1]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  // className='form-control border-start-0 px-1 my-2 fs-6'
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.tuesday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.tuesday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='d-flex justify-content-center gap-1 py-2'>
                                                        {candidate[index]?.timesheet_data[1]
                                                          ?.status && (
                                                          <div className='col-auto'>
                                                            {/* <Popup
                                                                trigger={
                                                                  <button className='btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'>
                                                                    <img src={Info} alt='Info' />
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                              >
                                                                <div>
                                                                  <div className='p-3'>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Bill Rate:{value.bill_rate}
                                                                    </div>
                                                                    <div className='border-top my-3'></div>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Pay Rate:{value.pay_rate}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Popup> */}
                                                          </div>
                                                        )}
                                                        {value.timesheet_data[1].status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[1]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'tuesday_approve',
                                                                    value?.timesheet_data[1]
                                                                      ?.timesheet_date,
                                                                    1
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>

                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[1]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                // disabled={
                                                                //   ((validateADPdataApprover && enableADPdATA) && !value.adp_validated) ? true : false
                                                                // }
                                                                onClose={() => {
                                                                  if (tuesday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'tuesday_reject',
                                                                      value?.timesheet_data[1]
                                                                        ?.timesheet_date,
                                                                      1,
                                                                      tuesday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.tuesday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={tuesday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {/* {(value.timesheet_data[1].status ==
                                                          'APPROVED' ||
                                                          value.timesheet_data[1].status ==
                                                            'REJECTED') && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[1]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[1]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'tuesday_revert',
                                                                  value?.timesheet_data[1]
                                                                    ?.timesheet_date,
                                                                  1
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )} */}

                                                        {value.timesheet_data[1].status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[1]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[1]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'tuesday_revert',
                                                                  value?.timesheet_data[1]
                                                                    ?.timesheet_date,
                                                                  1
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      {value?.timesheet_data[1]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[1]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[1]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[1]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'tuesday_revert',
                                                                              value
                                                                                ?.timesheet_data[1]
                                                                                ?.timesheet_date,
                                                                              1
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[1]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[1]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[1]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[1]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'tuesday_revert',
                                                                              value
                                                                                ?.timesheet_data[1]
                                                                                ?.timesheet_date,
                                                                              1
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[1]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[1]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'tuesday_revert',
                                                                                value
                                                                                  ?.timesheet_data[1]
                                                                                  ?.timesheet_date,
                                                                                1
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>
                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                1,
                                                                                tue
                                                                              )
                                                                            }}
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[1]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'tuesday_revert',
                                                                          value?.timesheet_data[1]
                                                                            ?.timesheet_date,
                                                                          1
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}
                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[1]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'tuesday_revert',
                                                                      value?.timesheet_data[1]
                                                                        ?.timesheet_date,
                                                                      1
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[1]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[1]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>

                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3 ',
                                                        value.timesheet_data[2]?.absent_type === 1
                                                          ? 'tableTdYellowBg'
                                                          : value.timesheet_data[2]?.absent_type ===
                                                            2
                                                          ? 'tableTdPinkBg'
                                                          : value.timesheet_data[2]?.absent_type ===
                                                            'null'
                                                          ? 'tableTdBlueBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          WED:{wed ? wed.slice(0, 5) : ''}
                                                        </label>
                                                        {value?.timesheet_data[2]?.status && (
                                                          <button
                                                            className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                // value[2]?.timesheet_date,
                                                                value.id,

                                                                value.timesheet_data[0]
                                                                  .week_start_date,
                                                                value.timesheet_data[0]
                                                                  .week_end_date,
                                                                wed
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        {value.timesheet_data[2]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                        {value.timesheet_data[2]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}

                                                        <div className='invisibleBlock'></div>
                                                      </div>
                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[2]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              {...register(
                                                                `details.${index}.wednesday_actual`
                                                              )}
                                                            />
                                                            {(candidate[index]?.timesheet_data[2]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[2]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.wednesday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.wednesday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className='d-flex justify-content-center gap-1 py-2'>
                                                        {candidate[index]?.timesheet_data[2]
                                                          ?.status && (
                                                          <div className='col-auto'>
                                                            {/* <Popup
                                                                trigger={
                                                                  <button className='btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'>
                                                                    <img src={Info} alt='Info' />
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                              >
                                                                <div>
                                                                  <div className='p-3'>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Bill Rate:{value.bill_rate}
                                                                    </div>
                                                                    <div className='border-top my-3'></div>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Pay Rate:{value.pay_rate}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Popup> */}
                                                          </div>
                                                        )}
                                                        {value.timesheet_data[2].status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[2]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'wednesday_approve',
                                                                    value?.timesheet_data[2]
                                                                      ?.timesheet_date,
                                                                    2
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>
                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[2]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                // disabled={
                                                                //   ((validateADPdataApprover && enableADPdATA) && !value.adp_validated) ? true : false
                                                                // }
                                                                onClose={() => {
                                                                  if (wednesday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'wednesday_reject',
                                                                      value?.timesheet_data[2]
                                                                        ?.timesheet_date,
                                                                      2,
                                                                      wednesday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.wednesday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={wednesday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {value.timesheet_data[2].status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[2]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[2]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'wednesday_revert',
                                                                  value?.timesheet_data[2]
                                                                    ?.timesheet_date,
                                                                  2
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>

                                                      {value?.timesheet_data[2]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[2]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[2]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[2]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'wednesday_revert',
                                                                              value
                                                                                ?.timesheet_data[2]
                                                                                ?.timesheet_date,
                                                                              2
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[2]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[2]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[2]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'wednesday_revert',
                                                                              value
                                                                                ?.timesheet_data[2]
                                                                                ?.timesheet_date,
                                                                              2
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[2]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[2]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'wednesday_revert',
                                                                                value
                                                                                  ?.timesheet_data[2]
                                                                                  ?.timesheet_date,
                                                                                2
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>

                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                2,
                                                                                wed
                                                                              )
                                                                            }}
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[2]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'wednesday_revert',
                                                                          value?.timesheet_data[2]
                                                                            ?.timesheet_date,
                                                                          2
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}
                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[2]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'wednesday_revert',
                                                                      value?.timesheet_data[2]
                                                                        ?.timesheet_date,
                                                                      2
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[2]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[2]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3 ',
                                                        value.timesheet_data[3]?.absent_type == 1
                                                          ? 'tableTdYellowBg'
                                                          : value.timesheet_data[3]?.absent_type ==
                                                            2
                                                          ? 'tableTdPinkBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        {' '}
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          THU:{thu ? thu.slice(0, 5) : ''}
                                                        </label>
                                                        {value?.timesheet_data[3]?.status && (
                                                          <button
                                                            className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                // value[3]?.timesheet_date,
                                                                value?.id,
                                                                value?.timesheet_data[0]
                                                                  ?.week_start_date,
                                                                value?.timesheet_data[0]
                                                                  ?.week_end_date,
                                                                thu
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        {value.timesheet_data[3]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                        {value.timesheet_data[3]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        <div className='invisibleBlock'></div>
                                                      </div>
                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[3]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              {...register(
                                                                `details.${index}.thursday_actual`
                                                              )}
                                                            />

                                                            {/* {(weekdaysOtHours[3].ot_hours !== 0) && ( */}
                                                            {(candidate[index]?.timesheet_data[3]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[3]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.thursday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.thursday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='d-flex justify-content-center gap-1 py-2'>
                                                        {candidate[index]?.timesheet_data[3]
                                                          ?.status && (
                                                          <div className='col-auto'>
                                                            {/* <Popup
                                                                trigger={
                                                                  <button className='btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'>
                                                                    <img src={Info} alt='Info' />
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                              >
                                                                <div>
                                                                  <div className='p-3'>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Bill Rate:{value.bill_rate}
                                                                    </div>
                                                                    <div className='border-top my-3'></div>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Pay Rate:{value.pay_rate}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Popup> */}
                                                          </div>
                                                        )}
                                                        {value.timesheet_data[3].status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[3]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'thursday_approve',
                                                                    value?.timesheet_data[3]
                                                                      ?.timesheet_date,
                                                                    3
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>
                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1  btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[3]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                onClose={() => {
                                                                  if (thursday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'thursday_reject',
                                                                      value?.timesheet_data[3]
                                                                        ?.timesheet_date,
                                                                      3,
                                                                      thursday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.thursday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={thursday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {/* {(value.timesheet_data[3].status ==
                                                          'APPROVED' ||
                                                          value.timesheet_data[3].status ==
                                                            'REJECTED') && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[3]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[3]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'thursday_revert',
                                                                  value?.timesheet_data[3]
                                                                    ?.timesheet_date,
                                                                  3
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )} */}
                                                        {value.timesheet_data[3].status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[3]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[3]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'thursday_revert',
                                                                  value?.timesheet_data[3]
                                                                    ?.timesheet_date,
                                                                  3
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      {value?.timesheet_data[3]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[3]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[3]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[3]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'thursday_revert',
                                                                              value
                                                                                ?.timesheet_data[3]
                                                                                ?.timesheet_date,
                                                                              3
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[3]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[3]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[3]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[3]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'thursday_revert',
                                                                              value
                                                                                ?.timesheet_data[3]
                                                                                ?.timesheet_date,
                                                                              3
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[3]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[3]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'thursday_revert',
                                                                                value
                                                                                  ?.timesheet_data[3]
                                                                                  ?.timesheet_date,
                                                                                3
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>
                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                3,
                                                                                thu
                                                                              )
                                                                            }}
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[3]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'thursday_revert',
                                                                          value?.timesheet_data[3]
                                                                            ?.timesheet_date,
                                                                          3
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}
                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[3]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'thursday_revert',
                                                                      value?.timesheet_data[3]
                                                                        ?.timesheet_date,
                                                                      3
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[3]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[3]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3 ',
                                                        value.timesheet_data[4]?.absent_type === 1
                                                          ? 'tableTdYellowBg'
                                                          : value.timesheet_data[4]?.absent_type ===
                                                            2
                                                          ? 'tableTdPinkBg'
                                                          : value.timesheet_data[4]?.absent_type ===
                                                            'null'
                                                          ? 'tableTdBlueBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8 fs-6'>
                                                          FRI:{fri ? fri.slice(0, 5) : ''}
                                                        </label>
                                                        {value?.timesheet_data[4]?.status && (
                                                          <button
                                                            className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                // value[4]?.timesheet_date,
                                                                value.id,

                                                                value.timesheet_data[0]
                                                                  .week_start_date,
                                                                value.timesheet_data[0]
                                                                  .week_end_date,
                                                                fri
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        {value.timesheet_data[4]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                        {value.timesheet_data[4]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        <div className='invisibleBlock'></div>
                                                      </div>
                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[4]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              {...register(
                                                                `details.${index}.friday_actual`
                                                              )}
                                                            />
                                                            {(candidate[index]?.timesheet_data[4]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[4]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.friday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.friday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='d-flex justify-content-center gap-1 py-2'>
                                                        {candidate[index]?.timesheet_data[4]
                                                          ?.status && (
                                                          <div className='col-auto'>
                                                            {/* <Popup
                                                                trigger={
                                                                  <button className='btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'>
                                                                    <img src={Info} alt='Info' />
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                              >
                                                                <div>
                                                                  <div className='p-3'>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Bill Rate:{value.bill_rate}
                                                                    </div>
                                                                    <div className='border-top my-3'></div>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Pay Rate:{value.pay_rate}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Popup> */}
                                                          </div>
                                                        )}
                                                        {value.timesheet_data[4].status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[4]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'friday_approve',
                                                                    value?.timesheet_data[4]
                                                                      ?.timesheet_date,
                                                                    4
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>
                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1  btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[4]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                // disabled={
                                                                //   ((validateADPdataApprover && enableADPdATA) && !value.adp_validated) ? true : false
                                                                // }
                                                                onClose={() => {
                                                                  if (friday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'friday_reject',
                                                                      value?.timesheet_data[4]
                                                                        ?.timesheet_date,
                                                                      4,
                                                                      friday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.friday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={friday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {/* {(value.timesheet_data[4].status ==
                                                          'APPROVED' ||
                                                          value.timesheet_data[4].status ==
                                                            'REJECTED') && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[4]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[4]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'friday_revert',
                                                                  value?.timesheet_data[4]
                                                                    ?.timesheet_date,
                                                                  4
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )} */}
                                                        {value.timesheet_data[4].status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[4]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[4]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'friday_revert',
                                                                  value?.timesheet_data[4]
                                                                    ?.timesheet_date,
                                                                  4
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      {value?.timesheet_data[4]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[4]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[4]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[4]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'friday_revert',
                                                                              value
                                                                                ?.timesheet_data[4]
                                                                                ?.timesheet_date,
                                                                              4
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[4]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[4]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[4]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[4]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'friday_revert',
                                                                              value
                                                                                ?.timesheet_data[4]
                                                                                ?.timesheet_date,
                                                                              4
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[4]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[4]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'friday_revert',
                                                                                value
                                                                                  ?.timesheet_data[4]
                                                                                  ?.timesheet_date,
                                                                                4
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>
                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                4,
                                                                                fri
                                                                              )
                                                                            }}
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[4]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'friday_revert',
                                                                          value?.timesheet_data[4]
                                                                            ?.timesheet_date,
                                                                          4
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}
                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[4]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'friday_revert',
                                                                      value?.timesheet_data[4]
                                                                        ?.timesheet_date,
                                                                      4
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[4]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[4]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3  ',
                                                        value.timesheet_data[5]?.absent_type === 1
                                                          ? 'tableTdYellowBg'
                                                          : value.timesheet_data[5]?.absent_type ===
                                                            2
                                                          ? 'tableTdPinkBg'
                                                          : value.timesheet_data[5]?.absent_type ===
                                                            'null'
                                                          ? 'tableTdBlueBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          SAT:{sat ? sat.slice(0, 5) : ''}
                                                        </label>
                                                        <img
                                                          src={SofaIcon}
                                                          className='timeEntryIcons blueBg ms-2'
                                                        />
                                                        {value?.timesheet_data[5]?.status && (
                                                          <button
                                                            className='btn btn-icon  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                // value[5]?.timesheet_date,
                                                                value.id,
                                                                value.timesheet_data[0]
                                                                  .week_start_date,
                                                                value.timesheet_data[0]
                                                                  .week_end_date,
                                                                sat
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        {/* <img
                                                      src={SofaIcon}
                                                      className='timeEntryIcons blueBg ms-2'
                                                    /> */}
                                                        {value.timesheet_data[5]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                        {value.timesheet_data[5]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        <div className='invisibleBlock'></div>
                                                      </div>
                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[5]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              aria-label='Hours'
                                                              {...register(
                                                                `details.${index}.saturday_actual`
                                                              )}
                                                            />
                                                            {(candidate[index]?.timesheet_data[5]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[5]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.saturday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.saturday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='d-flex justify-content-center gap-1 py-2'>
                                                        {candidate[index]?.timesheet_data[5]
                                                          ?.status && (
                                                          <div className='col-auto'>
                                                            {/* <Popup
                                                                trigger={
                                                                  <button className='btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'>
                                                                    <img src={Info} alt='Info' />
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                              >
                                                                <div>
                                                                  <div className='p-3'>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Bill Rate:{value.bill_rate}
                                                                    </div>
                                                                    <div className='border-top my-3'></div>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Pay Rate:{value.pay_rate}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Popup> */}
                                                          </div>
                                                        )}
                                                        {value.timesheet_data[5].status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[5]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'saturday_approve',
                                                                    value?.timesheet_data[5]
                                                                      ?.timesheet_date,
                                                                    5
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>
                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[5]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                // disabled={
                                                                //   ((validateADPdataApprover && enableADPdATA) && !value.adp_validated) ? true : false
                                                                // }
                                                                onClose={() => {
                                                                  if (saturday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'saturday_reject',
                                                                      value?.timesheet_data[5]
                                                                        ?.timesheet_date,
                                                                      5,
                                                                      saturday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.saturday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={saturday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {/* {(value.timesheet_data[5].status ==
                                                          'APPROVED' ||
                                                          value.timesheet_data[5].status ==
                                                            'REJECTED') && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[5]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[5]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'saturday_revert',
                                                                  value?.timesheet_data[5]
                                                                    ?.timesheet_date,
                                                                  5
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )} */}
                                                        {value.timesheet_data[5].status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[5]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[5]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'saturday_revert',
                                                                  value?.timesheet_data[5]
                                                                    ?.timesheet_date,
                                                                  5
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      {value?.timesheet_data[5]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[5]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[5]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[5]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'saturday_revert',
                                                                              value
                                                                                ?.timesheet_data[5]
                                                                                ?.timesheet_date,
                                                                              5
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[5]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[5]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[5]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[5]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'saturday_revert',
                                                                              value
                                                                                ?.timesheet_data[5]
                                                                                ?.timesheet_date,
                                                                              5
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[5]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[5]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'saturday_revert',
                                                                                value
                                                                                  ?.timesheet_data[5]
                                                                                  ?.timesheet_date,
                                                                                5
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>

                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                5,
                                                                                sat
                                                                              )
                                                                            }}
                                                                            // className='btn btn-success btn-sm'
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[5]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'saturday_revert',
                                                                          value?.timesheet_data[5]
                                                                            ?.timesheet_date,
                                                                          5
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}
                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[5]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'saturday_revert',
                                                                      value?.timesheet_data[5]
                                                                        ?.timesheet_date,
                                                                      5
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[5]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[5]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>

                                                    <td
                                                      className={clsx(
                                                        'px-2 py-3 ',
                                                        value.timesheet_data[6]?.absent_type === 1
                                                          ? 'tableTdYellowBg'
                                                          : value.timesheet_data[6]?.absent_type ===
                                                            2
                                                          ? 'tableTdPinkBg'
                                                          : value.timesheet_data[6]?.absent_type ===
                                                            'null'
                                                          ? 'tableTdBlueBg'
                                                          : 'tableTdBlueBg'
                                                      )}
                                                    >
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          SUN:{sun ? sun.slice(0, 5) : ''}
                                                        </label>
                                                        {value?.timesheet_data[6]?.status && (
                                                          <button
                                                            className='btn btn-icon   btn-active-color-success btn-icon-success buttonIconInApprover'
                                                            onClick={() =>
                                                              getApproverHistoryPerDay(
                                                                // value[6]?.timesheet_date,
                                                                value.id,

                                                                value.timesheet_data[0]
                                                                  .week_start_date,
                                                                value.timesheet_data[0]
                                                                  .week_end_date,
                                                                sun
                                                              )
                                                            }
                                                          >
                                                            <img src={History} alt='history' />
                                                          </button>
                                                        )}
                                                        <img
                                                          src={SofaIcon}
                                                          className='timeEntryIcons blueBg ms-2'
                                                        />
                                                        {value.timesheet_data[6]?.absent_type ===
                                                        2 ? (
                                                          <img
                                                            src={UmbrellaIcon}
                                                            className='timeEntryIcons pinkBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                        {value.timesheet_data[6]?.absent_type ===
                                                        1 ? (
                                                          <img
                                                            src={PlaneIcon}
                                                            className='timeEntryIcons yellowBg ms-2'
                                                          />
                                                        ) : (
                                                          ''
                                                        )}{' '}
                                                        <div className='invisibleBlock'></div>
                                                      </div>

                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <div className='input-group flex-nowrap'>
                                                            <input
                                                              type='text'
                                                              disabled={true}
                                                              className={clsx(
                                                                'form-control  px-1 my-2 fs-6',
                                                                candidate[index]?.timesheet_data[6]
                                                                  ?.ot_hours !== 0
                                                                  ? 'formControlMinWidth45 border-end-0'
                                                                  : 'formControlMinWidth90'
                                                              )}
                                                              placeholder='0:00'
                                                              {...register(
                                                                `details.${index}.sunday_actual`
                                                              )}
                                                            />
                                                            {(candidate[index]?.timesheet_data[6]
                                                              ?.ot_hours ||
                                                              candidate[index]?.timesheet_data[6]
                                                                ?.ot_minutes) !== 0 && (
                                                              <>
                                                                {' '}
                                                                <span className='input-group-text disabledBg  bg-body   px-0 my-2 fs-6'>
                                                                  +
                                                                </span>
                                                                <input
                                                                  type='text'
                                                                  disabled={true}
                                                                  className={clsx(
                                                                    'form-control border-start-0 px-1 my-2 fs-6 formControlMinWidth45'
                                                                  )}
                                                                  placeholder='0:00'
                                                                  aria-label='OT'
                                                                  {...register(
                                                                    `details.${index}.sunday_ot`
                                                                  )}
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='col'>
                                                              <input
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                                placeholder='Leave'
                                                                {...register(
                                                                  `details.${index}.sunday_leave`
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className='d-flex justify-content-center gap-1 py-2'>
                                                        {candidate[index]?.timesheet_data[6]
                                                          ?.status && (
                                                          <div className='col-auto'>
                                                            {/* <Popup
                                                                trigger={
                                                                  <button className='btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'>
                                                                    <img src={Info} alt='Info' />
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                              >
                                                                <div>
                                                                  <div className='p-3'>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Bill Rate:{value.bill_rate}
                                                                    </div>
                                                                    <div className='border-top my-3'></div>
                                                                    <div className='text-gray-900 fs-7 fw-semibold'>
                                                                      Pay Rate:{value.pay_rate}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Popup> */}
                                                          </div>
                                                        )}
                                                        {value.timesheet_data[6].status ==
                                                          'SUBMITTED' && (
                                                          <>
                                                            <div className='col-auto'>
                                                              <button
                                                                className='me-1 btn btn-icon btn-light  btn-active-color-success btn-icon-success buttonIconInApprover'
                                                                disabled={
                                                                  candidate[index]
                                                                    ?.timesheet_data[6]
                                                                    ?.qb_invoice_id != null
                                                                    ? true
                                                                    : validateADPdataApprover &&
                                                                      enableADPdATA &&
                                                                      candidate[index]
                                                                        .employee_type == 'W2' &&
                                                                      !candidate[index]
                                                                        .adp_validated
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={() =>
                                                                  onClickHolidayDisableLeave(
                                                                    index,
                                                                    'sunday_approve',
                                                                    value?.timesheet_data[6]
                                                                      ?.timesheet_date,
                                                                    6
                                                                  )
                                                                }
                                                              >
                                                                <img src={Check} alt='Approve' />
                                                              </button>
                                                            </div>
                                                            <div className='col-auto'>
                                                              <Popup
                                                                trigger={
                                                                  <button
                                                                    className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                    disabled={
                                                                      candidate[index]
                                                                        ?.timesheet_data[6]
                                                                        ?.qb_invoice_id != null
                                                                        ? true
                                                                        : validateADPdataApprover &&
                                                                          enableADPdATA &&
                                                                          candidate[index]
                                                                            .employee_type ==
                                                                            'W2' &&
                                                                          !candidate[index]
                                                                            .adp_validated
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    <img src={Cross} alt='reject' />{' '}
                                                                  </button>
                                                                }
                                                                position='bottom center'
                                                                // disabled={
                                                                //   ((validateADPdataApprover && enableADPdATA) && !value.adp_validated) ? true : false
                                                                // }
                                                                onClose={() => {
                                                                  if (sunday_notes !== '') {
                                                                    // value[1].rejected_comments_flag = false;
                                                                    onClickRejectDisableLeave(
                                                                      index,
                                                                      'sunday_reject',
                                                                      value?.timesheet_data[6]
                                                                        ?.timesheet_date,
                                                                      6,
                                                                      sunday_notes
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <div>
                                                                  <textarea
                                                                    className='form-control'
                                                                    placeholder='Rejected Comments'
                                                                    rows={2}
                                                                    {...register(
                                                                      `details.${index}.sunday_notes`
                                                                    )}
                                                                    onChange={handleChange}
                                                                    value={sunday_notes}
                                                                  ></textarea>
                                                                </div>
                                                              </Popup>
                                                            </div>
                                                          </>
                                                        )}
                                                        {/* {(value.timesheet_data[6].status ==
                                                          'APPROVED' ||
                                                          value.timesheet_data[6].status ==
                                                            'REJECTED') && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[6]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[6]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'sunday_revert',
                                                                  value?.timesheet_data[6]
                                                                    ?.timesheet_date,
                                                                  6
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )} */}
                                                        {value.timesheet_data[6].status ==
                                                          'REJECTED' && (
                                                          <div className='col-auto'>
                                                            <button
                                                              className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                              // disabled={
                                                              //   value?.timesheet_data[6]?.status ==
                                                              //     'SAVED'
                                                              //     ? true
                                                              //     : false
                                                              // }
                                                              disabled={
                                                                candidate[index]?.timesheet_data[6]
                                                                  ?.qb_invoice_id != null
                                                                  ? true
                                                                  : validateADPdataApprover &&
                                                                    enableADPdATA &&
                                                                    candidate[index]
                                                                      .employee_type == 'W2' &&
                                                                    !candidate[index].adp_validated
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={() =>
                                                                onClickHolidayRevertLeave(
                                                                  index,
                                                                  'sunday_revert',
                                                                  value?.timesheet_data[6]
                                                                    ?.timesheet_date,
                                                                  6
                                                                )
                                                              }
                                                            >
                                                              <img src={Revert} alt='Revert' />
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                      {value?.timesheet_data[6]?.status ==
                                                      'APPROVED' ? (
                                                        <>
                                                          {localStorage.getItem('refresh_Token') ==
                                                          'false' ? (
                                                            <>
                                                              {numArray?.includes('admin') ||
                                                              numArray?.includes('accounts') ? (
                                                                <>
                                                                  {value?.timesheet_data[6]
                                                                    ?.qb_invoice_id != null ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[6]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[6]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'sunday_revert',
                                                                              value
                                                                                ?.timesheet_data[6]
                                                                                ?.timesheet_date,
                                                                              6
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {/* {value
                                                                                ?.timesheet_data[6]
                                                                                ?.sent_to_quickbook && ( */}
                                                                              <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                QB
                                                                              </div>
                                                                              {/* )} */}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : value?.timesheet_data[6]
                                                                      ?.sent_to_quickbook ? (
                                                                    <div className='d-flex  justify-content-between gap-3 py-1 pt-5'>
                                                                      <div className='col-auto'>
                                                                        <button
                                                                          className='btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover'
                                                                          // disabled={
                                                                          //   value?.timesheet_data[6]?.status ==
                                                                          //     'SAVED'
                                                                          //     ? true
                                                                          //     : false
                                                                          // }
                                                                          disabled={
                                                                            candidate[index]
                                                                              ?.timesheet_data[6]
                                                                              ?.qb_invoice_id !=
                                                                            null
                                                                              ? true
                                                                              : validateADPdataApprover &&
                                                                                enableADPdATA &&
                                                                                candidate[index]
                                                                                  .employee_type ==
                                                                                  'W2' &&
                                                                                !candidate[index]
                                                                                  .adp_validated
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onClick={() =>
                                                                            onClickHolidayRevertLeave(
                                                                              index,
                                                                              'sunday_revert',
                                                                              value
                                                                                ?.timesheet_data[6]
                                                                                ?.timesheet_date,
                                                                              6
                                                                            )
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={Revert}
                                                                            alt='Revert'
                                                                          />
                                                                        </button>
                                                                      </div>
                                                                      {/* <div className='fs-8 text-success fw-bold fw-regular mt-1 '>
                                                                        Approved
                                                                      </div> */}
                                                                      <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                        <img
                                                                          src={Approval}
                                                                          width='25px'
                                                                          height='28px'
                                                                        />
                                                                      </div>

                                                                      {localStorage.getItem(
                                                                        'refresh_Token'
                                                                      ) == 'false' && (
                                                                        <>
                                                                          {(numArray?.includes(
                                                                            'admin'
                                                                          ) ||
                                                                            numArray?.includes(
                                                                              'accounts'
                                                                            )) && (
                                                                            <>
                                                                              {value
                                                                                ?.timesheet_data[6]
                                                                                ?.sent_to_quickbook && (
                                                                                <div className='fs-6 text-primary fw-bold fw-regular mt-2 text-right'>
                                                                                  QB
                                                                                </div>
                                                                              )}
                                                                            </>
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      <div className='d-flex  justify-content-evenly gap-3 py-1 pt-5'>
                                                                        <div className='col-auto'>
                                                                          <button
                                                                            className='me-1 mt-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                            // disabled={
                                                                            //   value?.timesheet_data[0]?.status ==
                                                                            //     'SAVED'
                                                                            //     ? true
                                                                            //     : false
                                                                            // }
                                                                            disabled={
                                                                              candidate[index]
                                                                                ?.timesheet_data[6]
                                                                                ?.qb_invoice_id !=
                                                                              null
                                                                                ? true
                                                                                : validateADPdataApprover &&
                                                                                  enableADPdATA &&
                                                                                  candidate[index]
                                                                                    .employee_type ==
                                                                                    'W2' &&
                                                                                  !candidate[index]
                                                                                    .adp_validated
                                                                                ? true
                                                                                : false
                                                                            }
                                                                            onClick={() =>
                                                                              onClickHolidayRevertLeave(
                                                                                index,
                                                                                'sunday_revert',
                                                                                value
                                                                                  ?.timesheet_data[6]
                                                                                  ?.timesheet_date,
                                                                                6
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={Revert}
                                                                              alt='Revert'
                                                                            />
                                                                          </button>
                                                                        </div>

                                                                        <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                          <img
                                                                            src={Approval}
                                                                            width='25px'
                                                                            height='28px'
                                                                          />
                                                                        </div>
                                                                        <div>
                                                                          <button
                                                                            onClick={() => {
                                                                              sendToQuickBooksDayLevel(
                                                                                index,
                                                                                6,
                                                                                sun
                                                                              )
                                                                            }}
                                                                            className='btn btn-success btn-sm fs-7  p-1 mt-1 text-nowrap d-flex'
                                                                          >
                                                                            <KTSVG
                                                                              path='/media/icons/duotune/arrows/arr064.svg'
                                                                              className='svg-icon-3'
                                                                            />
                                                                            QB
                                                                          </button>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className='col-auto'>
                                                                    <button
                                                                      className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                      // disabled={
                                                                      //   value?.timesheet_data[0]?.status ==
                                                                      //     'SAVED'
                                                                      //     ? true
                                                                      //     : false
                                                                      // }
                                                                      disabled={
                                                                        candidate[index]
                                                                          ?.timesheet_data[6]
                                                                          ?.qb_invoice_id != null
                                                                          ? true
                                                                          : validateADPdataApprover &&
                                                                            enableADPdATA &&
                                                                            candidate[index]
                                                                              .employee_type ==
                                                                              'W2' &&
                                                                            !candidate[index]
                                                                              .adp_validated
                                                                          ? true
                                                                          : false
                                                                      }
                                                                      onClick={() =>
                                                                        onClickHolidayRevertLeave(
                                                                          index,
                                                                          'sunday_revert',
                                                                          value?.timesheet_data[6]
                                                                            ?.timesheet_date,
                                                                          6
                                                                        )
                                                                      }
                                                                    >
                                                                      <img
                                                                        src={Revert}
                                                                        alt='Revert'
                                                                      />
                                                                    </button>
                                                                  </div>
                                                                  {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                    Approved
                                                                  </div> */}
                                                                  <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                    <img
                                                                      src={Approval}
                                                                      width='25px'
                                                                      height='28px'
                                                                    />
                                                                  </div>
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className='col-auto'>
                                                                <button
                                                                  className='me-1 btn btn-icon btn-light  btn-active-color-danger btn-icon-danger buttonIconInApprover '
                                                                  // disabled={
                                                                  //   value?.timesheet_data[0]?.status ==
                                                                  //     'SAVED'
                                                                  //     ? true
                                                                  //     : false
                                                                  // }
                                                                  disabled={
                                                                    candidate[index]
                                                                      ?.timesheet_data[6]
                                                                      ?.qb_invoice_id != null
                                                                      ? true
                                                                      : validateADPdataApprover &&
                                                                        enableADPdATA &&
                                                                        candidate[index]
                                                                          .employee_type == 'W2' &&
                                                                        !candidate[index]
                                                                          .adp_validated
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  onClick={() =>
                                                                    onClickHolidayRevertLeave(
                                                                      index,
                                                                      'sunday_revert',
                                                                      value?.timesheet_data[6]
                                                                        ?.timesheet_date,
                                                                      6
                                                                    )
                                                                  }
                                                                >
                                                                  <img src={Revert} alt='Revert' />
                                                                </button>
                                                              </div>
                                                              {/* <div className='fs-8 text-success fw-regular mt-1 text-center'>
                                                                Approved
                                                              </div> */}
                                                              <div className='fs-8 text-success fw-regular mt-1 me-3 text-center'>
                                                                <img
                                                                  src={Approval}
                                                                  width='25px'
                                                                  height='28px'
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : value?.timesheet_data[6]?.status ==
                                                        'REJECTED' ? (
                                                        <Tooltip
                                                          text={
                                                            value?.timesheet_data[6]?.approver_notes
                                                          }
                                                        >
                                                          <div
                                                            className='fs-8 text-danger fw-regular mt-1 text-center'
                                                            role='button'
                                                          >
                                                            <i className='bi bi-info-lg text-danger  fs-6'></i>
                                                            Rejected
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div className='fs-8 text-danger fw-regular invisible mt-1 text-center'>
                                                          Hidden
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td className='px-2 py-3 border border-end-0'>
                                                      <div className='d-flex align-items-center nowrap'>
                                                        <label className='form-label mb-0 fw-semibold fs-8'>
                                                          TOTAL
                                                        </label>
                                                        <div className='invisibleBlock'></div>
                                                      </div>

                                                      <div className='d-flex  gap-1'>
                                                        <div>
                                                          <input
                                                            type='text'
                                                            className='form-control px-1 my-2 fs-6 formControlMinWidth90'
                                                            {...register(
                                                              `details.${index}.total_actual`
                                                            )}
                                                            defaultValue={
                                                              sumofHour_otHourProject[index]
                                                            }
                                                            disabled={true}
                                                          />
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-sm  px-1 py-0 fs-8 w-100  textFieldMinHeight '
                                                            placeholder='Leave Hours'
                                                            {...register(
                                                              `details.${index}.total_leave`
                                                            )}
                                                            defaultValue={sumofAllProjLeave[index]}
                                                            disabled
                                                          />
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div className='border-top '></div>
                                          <div className='py-4 px-5'>
                                            <div className='row g-3'>
                                              <div className='col-12 col-md-6'>
                                                <label className='form-label '>
                                                  Consultant Comments
                                                </label>
                                                <textarea
                                                  className='form-control'
                                                  disabled={true}
                                                  placeholder='Please Enter Comments'
                                                  {...register(`details.${index}.comments`)}
                                                  rows={2}

                                                  // value="Worked on TCS Project"
                                                ></textarea>
                                              </div>

                                              <div className='col-12 col-md-6'>
                                                <label className='form-label '>
                                                  Approver Comments
                                                </label>

                                                <textarea
                                                  className='form-control'
                                                  placeholder='Approver Comments'
                                                  rows={2}
                                                  disabled={candidate[index].timesheet_data.every(
                                                    (item: any) =>
                                                      item.status == 'APPROVED' || !item.status
                                                  )}
                                                  {...register(
                                                    `details.${index}.ts_approver_notes`
                                                  )}
                                                  onChange={(event: any) => {
                                                    handleChange2(event, index)
                                                  }}
                                                ></textarea>
                                              </div>

                                              {/* dont delete this is for approver comments */}
                                            </div>
                                          </div>
                                          {docResultDataRes?.length > 0 && (
                                            <>
                                              <div className='border-top '></div>
                                              <div className='py-4 px-5'>
                                                <div className='row gap-3 px-3'>
                                                  {docResultDataRes.map((each: any, index: any) => {
                                                    if (each.project_id === value.id) {
                                                      return (
                                                        <>
                                                          {each.project_id ==
                                                            value?.timesheet_data[0]
                                                              ?.project_id && (
                                                            <div
                                                              key={index}
                                                              className='col-auto p-2 text-dark bg-opacity-10 border border-primary border-dashed uploadDocument  text-gray-700 fw-normal fs-7'
                                                            >
                                                              <div className='d-flex gap-2'>
                                                                <div>{each?.original_name}</div>

                                                                {each?.original_name?.length > 0 &&
                                                                  (each?.original_name?.substr(
                                                                    each?.original_name?.lastIndexOf(
                                                                      '.'
                                                                    ) + 1
                                                                  ) == 'jpeg' ||
                                                                    each?.original_name?.substr(
                                                                      each?.original_name?.lastIndexOf(
                                                                        '.'
                                                                      ) + 1
                                                                    ) == 'jpg' ||
                                                                    each?.original_name?.substr(
                                                                      each?.original_name?.lastIndexOf(
                                                                        '.'
                                                                      ) + 1
                                                                    ) == 'pdf' ||
                                                                    each?.original_name?.substr(
                                                                      each?.original_name?.lastIndexOf(
                                                                        '.'
                                                                      ) + 1
                                                                    ) == 'PDF' ||
                                                                    each?.original_name?.substr(
                                                                      each?.original_name?.lastIndexOf(
                                                                        '.'
                                                                      ) + 1
                                                                    ) == 'png' ||
                                                                    each?.original_name?.substr(
                                                                      each?.original_name?.lastIndexOf(
                                                                        '.'
                                                                      ) + 1
                                                                    ) == 'PNG') && (
                                                                    // <div role='button'>
                                                                    //   <a>
                                                                    //     <i
                                                                    //       className='bi bi-eye fw-bold fs-4 text-primary'
                                                                    //       onClick={() => {
                                                                    //         viewFile(
                                                                    //           each?.blobUrl,
                                                                    //           index
                                                                    //         )
                                                                    //       }}
                                                                    //     ></i>
                                                                    //   </a>
                                                                    // </div>
                                                                    <div role='button'>
                                                                      <a
                                                                        href={each.blobUrl}
                                                                        target='_blank'
                                                                        className='m-1 mx-2'
                                                                      >
                                                                        <i className='bi bi-eye fw-bold fs-4 text-primary'></i>
                                                                      </a>
                                                                    </div>
                                                                  )}
                                                                <Tooltip text='Download File'>
                                                                  <div>
                                                                    <a
                                                                      className='m-1 mx-2'
                                                                      role='button'
                                                                      onClick={() => {
                                                                        // downloadFile(
                                                                        //   each.blobUrl,
                                                                        //   index
                                                                        // )
                                                                        downloadFile(
                                                                          each.blobDownloadUrl,
                                                                          index
                                                                        )
                                                                      }}
                                                                    >
                                                                      <i className='bi bi-download  fw-bold fs-4 text-primary'></i>
                                                                    </a>
                                                                  </div>
                                                                </Tooltip>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </>
                                                      )
                                                    }
                                                  })}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          <div>
                                            {/* {imagesArrayRes.length + " " + JSON.stringify(imagesArrayRes) + " "} */}
                                          </div>
                                          <div className='border-top my-2'></div>
                                          <div className='px-6 pb-3'>
                                            <div className='d-flex gap-3 flex-wrap justify-content-md-end'>
                                              {localStorage.getItem('role_name') != 'accounts' ? (
                                                <>
                                                  <div>
                                                    <button
                                                      type='button'
                                                      className='btn btn-bg-light btn-active-color-primary btn-color-gray-700 btn-sm'
                                                      onClick={() =>
                                                        getHistoryPerWeek(
                                                          value.id,
                                                          value.timesheet_data[0].week_start_date,
                                                          value.timesheet_data[0].week_end_date
                                                        )
                                                      }
                                                    >
                                                      History
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                              <Drawer
                                                show={show}
                                                setShow={setShow}
                                                approver_history_per_week={
                                                  approver_history_per_week
                                                }
                                                approver_history_open_per_week={
                                                  approver_history_open_per_week
                                                }
                                                approver_history_open_per_day={
                                                  approver_history_open_per_day
                                                }
                                                approver_history_per_day={approver_history_per_day}
                                                approver_history_per_day_status={
                                                  approver_history_per_day_status
                                                }
                                                setApproverHistoryOpenPerWeek={
                                                  setApproverHistoryOpenPerWeek
                                                }
                                                setApproverHistoryOpenPerDay={
                                                  setApproverHistoryOpenPerDay
                                                }
                                              />

                                              {viewshow && (
                                                <ViewDocumentPop
                                                  viewFile={viewFile}
                                                  view_open={view_open}
                                                  setViewOpen={setViewOpen}
                                                  view_blob={view_blob}
                                                  setViewBlob={setViewBlob}
                                                  cancel={handleCancel}
                                                />
                                              )}
                                              {viewshow3 && (
                                                <DocFilesView
                                                  viewFile1={viewFile1}
                                                  view_open={view_open}
                                                  setViewOpen={setViewOpen}
                                                  view_blob={view_blob}
                                                  setViewBlob={setViewBlob}
                                                  cancel={handleCancel}
                                                />
                                              )}
                                              {viewshow1 && (
                                                <RevertPopup
                                                  setConsultant={setConsultant}
                                                  // checkAdpData={checkAdpData}
                                                  // setCheckAdpData={setCheckAdpData}
                                                  onCancel={closeModalHandler1}
                                                  // cancel={handleCancel}
                                                />
                                              )}
                                              {viewshow2 && (
                                                <RevertWeekPopup
                                                  setConsultant1={setConsultant1}
                                                  // checkAdpData={checkAdpData}
                                                  // setCheckAdpData={setCheckAdpData}
                                                  onCancel={closeModalHandler2}
                                                  // cancel={handleCancel}
                                                />
                                              )}
                                              {viewshow4 && (
                                                <RevertAllPopup
                                                  setConsultant2={setConsultant2}
                                                  // checkAdpData={checkAdpData}
                                                  // setCheckAdpData={setCheckAdpData}
                                                  onCancel={closeModalHandler4}
                                                  // cancel={handleCancel}
                                                />
                                              )}

                                              {localStorage.getItem('role_name') != 'accounts' ? (
                                                <>
                                                  {(candidate[index].timesheet_data.find(
                                                    (item: any) => item.status == 'APPROVED'
                                                  ) ||
                                                    candidate[index].timesheet_data.find(
                                                      (item: any) => item.status == 'REJECTED'
                                                    )) && (
                                                    <div>
                                                      <button
                                                        // disabled={
                                                        //   ((validateADPdataApprover && enableADPdATA) && !candidate[index].adp_validated) ? true : false
                                                        // }
                                                        disabled={
                                                          validateADPdataApprover &&
                                                          enableADPdATA &&
                                                          candidate[index].employee_type == 'W2' &&
                                                          !candidate[index].adp_validated
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() => {
                                                          allRevert(index)
                                                        }}
                                                        className='btn btn-light-danger btn-sm'
                                                      >
                                                        Revert Week
                                                      </button>
                                                    </div>
                                                  )}
                                                  {candidate[index].timesheet_data.find(
                                                    (item: any) => item.status == 'SUBMITTED'
                                                  ) && (
                                                    <div>
                                                      <button
                                                        // disabled={
                                                        //   ((validateADPdataApprover && enableADPdATA) && !candidate[index].adp_validated) ? true : false
                                                        // }
                                                        disabled={
                                                          validateADPdataApprover &&
                                                          enableADPdATA &&
                                                          candidate[index].employee_type == 'W2' &&
                                                          !candidate[index].adp_validated
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() => {
                                                          allReject(index)
                                                        }}
                                                        className='btn btn-danger btn-sm'
                                                      >
                                                        Reject Week
                                                      </button>
                                                    </div>
                                                  )}
                                                  {candidate[index].timesheet_data.find(
                                                    (item: any) => item.status == 'SUBMITTED'
                                                  ) && (
                                                    <div>
                                                      <button
                                                        // disabled={
                                                        //   ((validateADPdataApprover && enableADPdATA) && !candidate[index].adp_validated) ? true : false
                                                        // }
                                                        disabled={
                                                          validateADPdataApprover &&
                                                          enableADPdATA &&
                                                          candidate[index].employee_type == 'W2' &&
                                                          !candidate[index].adp_validated
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={() => {
                                                          allApprove(index)
                                                        }}
                                                        className='btn btn-success btn-sm'
                                                      >
                                                        Approve Week
                                                      </button>
                                                    </div>
                                                  )}
                                                  {/* let check = arr.every(element => element.isChecked === false); */}
                                                  {/* const check = arr.every(({ isChecked }) => !isChecked); */}
                                                  {/* {(validateQuickBooksdataApprover && qBtRANSACTIONSdATA) && (
                                                    <> */}
                                                  {localStorage.getItem('refresh_Token') ==
                                                    'false' && (
                                                    <>
                                                      {(numArray?.includes('superadmin') ||
                                                        numArray?.includes('admin') ||
                                                        numArray?.includes('accounts')) && (
                                                        <>
                                                          {/*  {candidate[index].timesheet_data.find(
                                                        (item: any) => item.status == 'APPROVED'
                                                      ) && (
                                                        <>
                                                          {value.timesheet_data[0]
                                                            .qb_timesheet_id == null && (
                                                            <div>
                                                              <button
                                                                disabled={
                                                                  validateQuickBooksdataApprover &&
                                                                  qBtRANSACTIONSfdATA
                                                                    ? false
                                                                    : true
                                                                }
                                                                onClick={() => {
                                                                  sendToQuickBooks(index, '')
                                                                }}
                                                                className='btn btn-success btn-sm'
                                                              >
                                                                Send To QuickBooks
                                                              </button>
                                                            </div>
                                                          )}
                                                        </>
                                                      )}*/}

                                                          {candidate[index].ts_status ==
                                                            'APPROVED' && (
                                                            <>
                                                              {/* {value.timesheet_data.find(
                                                                (item: any) =>
                                                                  item.qb_timesheet_id == null
                                                              ) && ( 
                                                                 <> */}
                                                              {(value.ts_sent_to_quickbook ==
                                                                null ||
                                                                value.ts_sent_to_quickbook ==
                                                                  false) && (
                                                                <>
                                                                  <div>
                                                                    <button
                                                                      // disabled={
                                                                      //   validateQuickBooksdataApprover &&
                                                                      //   qBtRANSACTIONSfdATA
                                                                      //     ? false
                                                                      //     : true
                                                                      // }
                                                                      disabled={
                                                                        validateQuickBooksdataApprover
                                                                          ? qBtRANSACTIONSfdATA
                                                                            ? false
                                                                            : true
                                                                          : true
                                                                      }
                                                                      onClick={() => {
                                                                        sendToQuickBooks(index, '')
                                                                      }}
                                                                      className='btn btn-success btn-sm'
                                                                    >
                                                                      Send To QuickBooks
                                                                      {/* {value.ts_sent_to_quickbook
                                                                        ? 'Timesheets Submitted to QB'
                                                                        : 'Send To QuickBooks'} */}
                                                                    </button>
                                                                  </div>
                                                                </>
                                                              )}
                                                              {/* </> 
                                                               )} */}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}

                                                  {(numArray?.includes('admin') ||
                                                    numArray?.includes('accounts')) && (
                                                    <>
                                                      {candidate[index].ts_status == 'APPROVED' && (
                                                        <>
                                                          {(value.ts_sent_to_netsuite == null ||
                                                            value.ts_sent_to_netsuite == false) && (
                                                            <>
                                                              <div>
                                                                <button
                                                                  disabled={
                                                                    validateNetSuitedataApprover
                                                                      ? false
                                                                      : true
                                                                  }
                                                                  onClick={() => {
                                                                    SendToNetSuite(index)
                                                                  }}
                                                                  className='btn btn-success btn-sm'
                                                                >
                                                                  Send To NetSuite
                                                                </button>
                                                              </div>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                  {/* {localStorage.getItem('refresh_Token') ==
                                                    'false' && (
                                                    <>
                                                      {(numArray?.includes('superadmin') ||
                                                        numArray?.includes('admin') ||
                                                        numArray?.includes('accounts')) && (
                                                        <>
                                                          {candidate[index].ts_status ==
                                                            'APPROVED' && (
                                                            <>
                                                              {candidate[index]
                                                                .ts_sent_to_quickbook && (
                                                                <span className='badge badge-light-primary  p-2 px-6 fw-bold fs-6'>
                                                                  Timesheets Submitted to QB
                                                                </span>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )} */}
                                                  {/* </>
                                                  )} */}
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </div>

                {candidate.length > 1 && candidate.every((obj: any) => obj.employee_type !== 'W2') && (
                  <div className='d-flex justify-content-end gap-3 pt-5 flex-wrap'>
                    {candidate.every((obj: any) => obj.employee_type !== 'W2') && (
                      <>
                        {candidate.length > 1 && (
                          <>
                            {showRevertAll &&
                              showRevertAll.length > 0 &&
                              showRevertAll.indexOf(true) > -1 && (
                                <div>
                                  <button
                                    onClick={() => {
                                      const index = 0

                                      allRevertData(index)
                                    }}
                                    className='btn btn-light-danger btn-sm'
                                  >
                                    Revert All
                                  </button>
                                </div>
                              )}
                          </>
                        )}
                      </>
                    )}
                    {candidate.every((obj: any) => obj.employee_type == 'W2') && (
                      <>
                        {validateADPdataApprover &&
                          enableADPdATA &&
                          !candidate.every((obj: any) => obj.adp_validated == false) && (
                            <>
                              {candidate.length > 1 && (
                                <>
                                  {showRevertAll &&
                                    showRevertAll.length > 0 &&
                                    showRevertAll.indexOf(true) > -1 && (
                                      <div>
                                        <button
                                          onClick={() => {
                                            const index = 0

                                            allRevertData(index)
                                          }}
                                          className='btn btn-light-danger btn-sm'
                                        >
                                          Revert All
                                        </button>
                                      </div>
                                    )}
                                </>
                              )}
                            </>
                          )}
                      </>
                    )}

                    {candidate.every((obj: any) => obj.employee_type !== 'W2') && (
                      <>
                        <>
                          {candidate.length > 1 && (
                            <>
                              {showApproveReject &&
                                showApproveReject.length > 0 &&
                                showApproveReject.indexOf(true) > -1 && (
                                  <>
                                    <div>
                                      <button
                                        onClick={() => {
                                          const index = 0

                                          allRejectData(index)
                                        }}
                                        className='btn btn-danger btn-sm'
                                      >
                                        Reject All
                                      </button>
                                    </div>
                                    <div>
                                      <button
                                        onClick={() => {
                                          const index = 0
                                          allApproveData(index)
                                        }}
                                        className='btn btn-success btn-sm'
                                      >
                                        Approve All
                                      </button>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                        </>
                      </>
                    )}

                    {candidate.every((obj: any) => obj.employee_type == 'W2') && (
                      <>
                        {validateADPdataApprover &&
                          enableADPdATA &&
                          !candidate.every((obj: any) => obj.adp_validated == false) && (
                            <>
                              {candidate.length > 1 && (
                                <>
                                  {showApproveReject &&
                                    showApproveReject.length > 0 &&
                                    showApproveReject.indexOf(true) > -1 && (
                                      <>
                                        <div>
                                          <button
                                            onClick={() => {
                                              const index = 0
                                              allRejectData(index)
                                            }}
                                            className='btn btn-danger btn-sm'
                                          >
                                            Reject All
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            onClick={() => {
                                              const index = 0
                                              allApproveData(index)
                                            }}
                                            className='btn btn-success btn-sm'
                                          >
                                            Approve All
                                          </button>
                                        </div>
                                      </>
                                    )}
                                </>
                              )}
                            </>
                          )}
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </form>
  )
}
export default Approver
