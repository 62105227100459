import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import Addproj from '../../app/modules/apps/masters/projects/Addproj'
import AddProject from '../../app/pages/masters/manageProjects/AddProject'

// import AddTimesheet from '../../app/modules/apps/timesheetentry/AddTimesheet'
import AddTimesheet from '../../app/pages/myTimesheets/AddTimesheet'

// import AddClients from '../modules/apps/masters/clients/AddClients'
import UpdateClient from '../pages/masters/manageClients/UpdateClient'
import AddMobileSetting from '../pages/mobileSettings/AddMobileSetting'

import AddList from '../pages/dashboard/AddList'
// import Adduser from '../pages/dashboard/Adduser'
import AddConsultant from '../pages/masters/manageConsultants/AddConsultant'
import CorrespondenceDetails from '../pages/myConsultants/CorrespondenceDetails'

// import Timesheet from '../pages/dashboard/Timesheet'
import Timesheet from '../pages/login/Login'

// import Approver from '../modules/apps/approver/Approver'
import Approver from '../pages/approver/Approver'

// import ProjectEmployeeReports from '../modules/apps/ExportReports/ProjectEmployeeReports'
import Reports from '../pages/reports/Reports'

import {useNavigate} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import OrganizationList from '../../app/pages/dashboard/OrganizationList'

const PrivateRoutes = () => {
  const navigate = useNavigate()

  // const UsersList = lazy(() => import('../modules/apps/masters/users/UserList'))
  // const ManageUsersList = lazy(() => import('../pages/masters/ManageUsersList'))
  const ManageUsersList = lazy(() => import('../pages/masters/manageUsers/ManageUsersList'))
  const ManageBusinessUnitList = lazy(
    () => import('../pages/masters/manageBusinessUnit/ManageBusinessUnitList')
  )
  const ManageBranchesList = lazy(
    () => import('../pages/masters/manageBranches/ManageBranchesList')
  )
  const ManageDepartmentsList = lazy(
    () => import('../pages/masters/manageDepartments/ManageDepartmentsList')
  )
  const OrganizationCompliance = lazy(
    () => import('../pages/dashboard/organization/OrganizationComplianceReports')
  )
  const RevenueCompianceReports = lazy(
    () => import('../pages/dashboard/revenue/RevenueCompianceReports')
  )
  const CogsComplianceReports = lazy(() => import('../pages/dashboard/cogs/CogsComplianceReports'))
  const MarginComplianceReports = lazy(
    () => import('../pages/dashboard/margin/MarginComplianceReports')
  )
  const UtilizationComplianceReports = lazy(
    () => import('../pages/dashboard/utilization/UtilizationComplianceReports')
  )
  const ProjectReports = lazy(() => import('../modules/apps/Reports/ProjectReports'))
  const EmployeeFilterReports = lazy(() => import('../modules/apps/Reports/EmployeeFilterReports'))
  // const EmployeeReports = lazy(() => import('../modules/apps/Reports/EmployeeReports'))
  const EmployeeReports = lazy(() => import('../pages/reports/reportModals/EmployeeReports'))

  const TimeSheetReports = lazy(() => import('../modules/apps/Reports/TimeSheetReports'))
  const TimeSheetSubmissionReports = lazy(
    () => import('../modules/apps/Reports/TimeSheetSubmissionReports')
  )
  // const TimeSheetSubmissionReports = lazy(()=>'../m')
  // const ConsultantList = lazy(() => import('../modules/apps/masters/users/ConsultantList'))
  const ManageConsultantList = lazy(
    () => import('../pages/masters/manageConsultants/ManageConsultantList')
  )

  const QBSettings = lazy(() => import('../modules/apps/masters/users/QBSettings'))
  // const EmailSetting = lazy(() => import('../modules/apps/masters/users/EmailSetting'))
  const Settings = lazy(() => import('../pages/masters/settings/Settings'))
  const QBSettingsList = lazy(() => import('../pages/masters/settings/QBSettingsList'))
  const NetSuiteSettingsList = lazy(() => import('../pages/masters/integrations/NetSuiteSettings'))
  const IntegrationsList = lazy(() => import('../pages/masters/integrations/IntegrationsList'))
  const InvoiceMenu = lazy(() => import('../pages/masters/invoice/InvoiceMenu'))
  const MobileSettings = lazy(() => import('../pages/mobileSettings/MobileSettings'))

  // const Announcements = lazy(() => import('../modules/apps/masters/users/Announcements'))
  const AddAnnouncement = lazy(() => import('../pages/masters/manageAnnouncements/AddAnnouncement'))

  const Profile = lazy(() => import('../../_metronic/partials/layout/profile/ProfileScreen'))
  // const Project = lazy(() => import('../modules/apps/masters/users/Projects'))
  const ManageProjectList = lazy(() => import('../pages/masters/manageProjects/ManageProjectList'))
  const MyConsultant = lazy(() => import('../pages/myConsultants/MyConsultant'))

  // const ManageClient = lazy(() => import('../modules/apps/masters/users/ManageClients'))
  const ManageClientsList = lazy(() => import('../pages/masters/manageClients/ManageClientsList'))

  // const TimeSheetEntryList = lazy(() => import('../modules/apps/timesheetentry/TimeSheetEntryList'))
  const MyTimesheetsList = lazy(() => import('../../app/pages/myTimesheets/MyTimesheetsList'))
  const MyLeavesList = lazy(() => import('../../app/pages/myLeaves/MyLeavesList'))

  // import MyTimesheetsList from '../../app/pages/myTimesheets/MyTimesheetsList'

  // const ProjectView = lazy(() => import('../modules/apps/timesheetentry/ProjectView'))
  const MyProjects = lazy(() => import('../../app/pages/myProjects/MyProjects'))

  // const AnnouncementList = lazy(() => import('../modules/apps/masters/users/AnnouncementList'))
  const ManageAnnouncementsList = lazy(
    () => import('../pages/masters/manageAnnouncements/ManageAnnouncementsList')
  )
  const ManageDocument = lazy(() => import('../pages/masters/manageDocuments/ManageDocument'))

  const [accepted, setAccepted] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem('token')) {
      setAccepted(true)
    }
  }, [window.localStorage.getItem('token')])
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {localStorage.getItem('role_Name') ? (
          <>
            {/* {localStorage.getItem('role_Name') === 'employee' ? ( */}
            {numArray?.includes('employee') ? (
              <>
                <Route
                  path='/timesheetlist'
                  element={
                    <SuspensedView>
                      <MyTimesheetsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/invoice'
                  element={
                    <SuspensedView>
                      <InvoiceMenu />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/leaves'
                  element={
                    <SuspensedView>
                      <MyLeavesList />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/projectview'
                  element={
                    <SuspensedView>
                      <MyProjects />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timesheetlist/addtimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/updatetimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />

                <Route path='timesheetlist/addtimesheet' element={<AddTimesheet />} />
                <Route path='timesheetlist/updatetimesheet' element={<AddTimesheet />} />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='error/*' element={<ErrorsPage />} />
                {/* <Route index element={<Navigate to='/login' />} />
                <Route path='login/*' element={<Timesheet />} />
                <Route path='*' element={<Navigate to='/login' />} /> */}
              </>
            )}

            {/* {localStorage.getItem('role_Name') === 'superadmin' ||
            localStorage.getItem('role_Name') === 'admin' ? ( */}
            {numArray?.includes('superadmin') || numArray?.includes('admin') ? (
              <>
                <Route
                  path='/dashboard/organization'
                  element={
                    <SuspensedView>
                      <OrganizationCompliance />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/dashboard/revenue'
                  element={
                    <SuspensedView>
                      <RevenueCompianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/cogs'
                  element={
                    <SuspensedView>
                      <CogsComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/margin'
                  element={
                    <SuspensedView>
                      <MarginComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/utilization'
                  element={
                    <SuspensedView>
                      <UtilizationComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist'
                  element={
                    <SuspensedView>
                      <MyTimesheetsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/leaves'
                  element={
                    <SuspensedView>
                      <MyLeavesList />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/projectview'
                  element={
                    <SuspensedView>
                      <MyProjects />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timesheetlist/addtimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/updatetimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />

                <Route path='timesheetlist/addtimesheet' element={<AddTimesheet />} />
                <Route path='timesheetlist/updatetimesheet' element={<AddTimesheet />} />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/approver'}
                  element={
                    <SuspensedView>
                      <Approver />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/reports'}
                  element={
                    <SuspensedView>
                      <Reports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/employeereports'
                  element={
                    <SuspensedView>
                      <EmployeeReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/projectreports'
                  element={
                    <SuspensedView>
                      <ProjectReports />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timeSheetreports'
                  element={
                    <SuspensedView>
                      <TimeSheetReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timeSheetsubmissionreports'
                  element={
                    <SuspensedView>
                      <TimeSheetSubmissionReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/employeefilterreports'
                  element={
                    <SuspensedView>
                      <EmployeeFilterReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageusers'
                  element={
                    <SuspensedView>
                      <ManageUsersList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/businessunit'
                  element={
                    <SuspensedView>
                      <ManageBusinessUnitList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managebranches'
                  element={
                    <SuspensedView>
                      <ManageBranchesList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managedepartments'
                  element={
                    <SuspensedView>
                      <ManageDepartmentsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects'
                  element={
                    <SuspensedView>
                      <ManageProjectList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/addproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/masters/manageprojects/updateproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageprojects/addproject' element={<AddProject />} />
                <Route path='masters/manageprojects/updateproject' element={<AddProject />} />

                <Route
                  path='/manageclients'
                  element={
                    <SuspensedView>
                      <ManageClientsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/updateclients'
                  element={
                    <SuspensedView>
                      <UpdateClient />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants'
                  element={
                    <SuspensedView>
                      <ManageConsultantList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/addconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/updateconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/viewconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageconsultants/addconsultant' element={<AddConsultant />} />
                <Route
                  path='masters/manageconsultants/updateconsultant'
                  element={<AddConsultant />}
                />
                <Route
                  path='masters/manageconsultants/viewconsultant'
                  element={<AddConsultant />}
                />

                <Route
                  path='/masters/settings'
                  element={
                    <SuspensedView>
                      <Settings />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/integrations/qbSettings'
                  element={
                    <SuspensedView>
                      <QBSettingsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/integrations/netSuiteSettings'
                  element={
                    <SuspensedView>
                      <NetSuiteSettingsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='integrations'
                  element={
                    <SuspensedView>
                      <IntegrationsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/invoice'
                  element={
                    <SuspensedView>
                      <InvoiceMenu />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/mobilesettings'
                  element={
                    <SuspensedView>
                      <MobileSettings />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/addmobilesettings'
                  element={
                    <SuspensedView>
                      <AddMobileSetting />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/updatemobilesettings'
                  element={
                    <SuspensedView>
                      <AddMobileSetting />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/masters/manageannouncements'
                  element={
                    <SuspensedView>
                      <ManageAnnouncementsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managedocuments'
                  element={
                    <SuspensedView>
                      <ManageDocument />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/masters/manageannouncements/addannouncement'
                  element={
                    <SuspensedView>
                      <AddAnnouncement />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageannouncements/updateannouncement'
                  element={
                    <SuspensedView>
                      <AddAnnouncement />
                    </SuspensedView>
                  }
                />
                {/* <Route
                  path='/qbsettings'
                  element={
                    <SuspensedView>
                      <QBSettings />
                    </SuspensedView>
                  }
                /> */}
                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='error/*' element={<ErrorsPage />} />
                <Route index element={<Navigate to='/login' />} />

                {/* <Route path='/login' element={<Timesheet />} /> */}
                <Route path='login/*' element={<Timesheet />} />
                <Route path='*' element={<Navigate to='/login' />} />
              </>
            )}

            {/* {localStorage.getItem('role_Name') === 'accounts' ||
            localStorage.getItem('role_Name') === 'hr' ? ( */}
            {numArray?.includes('accounts') || numArray?.includes('hr') ? (
              <>
                <Route
                  path='/dashboard/organization'
                  element={
                    <SuspensedView>
                      <OrganizationCompliance />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/invoice'
                  element={
                    <SuspensedView>
                      <InvoiceMenu />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/revenue'
                  element={
                    <SuspensedView>
                      <RevenueCompianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/cogs'
                  element={
                    <SuspensedView>
                      <CogsComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/margin'
                  element={
                    <SuspensedView>
                      <MarginComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/utilization'
                  element={
                    <SuspensedView>
                      <UtilizationComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist'
                  element={
                    <SuspensedView>
                      <MyTimesheetsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/leaves'
                  element={
                    <SuspensedView>
                      <MyLeavesList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/projectview'
                  element={
                    <SuspensedView>
                      <MyProjects />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timesheetlist/addtimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/updatetimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />

                <Route path='timesheetlist/addtimesheet' element={<AddTimesheet />} />
                <Route path='timesheetlist/updatetimesheet' element={<AddTimesheet />} />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/approver'}
                  element={
                    <SuspensedView>
                      <Approver />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/reports'}
                  element={
                    <SuspensedView>
                      <Reports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/employeereports'
                  element={
                    <SuspensedView>
                      <EmployeeReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/projectreports'
                  element={
                    <SuspensedView>
                      <ProjectReports />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timeSheetreports'
                  element={
                    <SuspensedView>
                      <TimeSheetReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timeSheetsubmissionreports'
                  element={
                    <SuspensedView>
                      <TimeSheetSubmissionReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/employeefilterreports'
                  element={
                    <SuspensedView>
                      <EmployeeFilterReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageusers'
                  element={
                    <SuspensedView>
                      <ManageUsersList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/businessunit'
                  element={
                    <SuspensedView>
                      <ManageBusinessUnitList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managebranches'
                  element={
                    <SuspensedView>
                      <ManageBranchesList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managedepartments'
                  element={
                    <SuspensedView>
                      <ManageDepartmentsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects'
                  element={
                    <SuspensedView>
                      <ManageProjectList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/addproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/updateproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageprojects/addproject' element={<AddProject />} />
                <Route path='masters/manageprojects/updateproject' element={<AddProject />} />

                <Route
                  path='/manageclients'
                  element={
                    <SuspensedView>
                      <ManageClientsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/updateclients'
                  element={
                    <SuspensedView>
                      <UpdateClient />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants'
                  element={
                    <SuspensedView>
                      <ManageConsultantList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/addconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/updateconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/viewconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageconsultants/addconsultant' element={<AddConsultant />} />
                <Route
                  path='masters/manageconsultants/updateconsultant'
                  element={<AddConsultant />}
                />
                <Route
                  path='masters/manageconsultants/viewconsultant'
                  element={<AddConsultant />}
                />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='error/*' element={<ErrorsPage />} />
                {/* <Route index element={<Navigate to='/login' />} />
                <Route path='login/*' element={<Timesheet />} />
                <Route path='*' element={<Navigate to='/login' />} /> */}
              </>
            )}
            {/* {localStorage.getItem('role_Name') === 'approver' ? ( */}
            {numArray?.includes('approver') ? (
              <>
                <Route
                  path='/dashboard/organization'
                  element={
                    <SuspensedView>
                      <OrganizationCompliance />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/invoice'
                  element={
                    <SuspensedView>
                      <InvoiceMenu />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/revenue'
                  element={
                    <SuspensedView>
                      <RevenueCompianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/cogs'
                  element={
                    <SuspensedView>
                      <CogsComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/margin'
                  element={
                    <SuspensedView>
                      <MarginComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/utilization'
                  element={
                    <SuspensedView>
                      <UtilizationComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist'
                  element={
                    <SuspensedView>
                      <MyTimesheetsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/leaves'
                  element={
                    <SuspensedView>
                      <MyLeavesList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/projectview'
                  element={
                    <SuspensedView>
                      <MyProjects />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timesheetlist/addtimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/updatetimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />

                <Route path='timesheetlist/addtimesheet' element={<AddTimesheet />} />
                <Route path='timesheetlist/updatetimesheet' element={<AddTimesheet />} />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/approver'}
                  element={
                    <SuspensedView>
                      <Approver />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/reports'}
                  element={
                    <SuspensedView>
                      <Reports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/employeereports'
                  element={
                    <SuspensedView>
                      <EmployeeReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/projectreports'
                  element={
                    <SuspensedView>
                      <ProjectReports />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timeSheetreports'
                  element={
                    <SuspensedView>
                      <TimeSheetReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timeSheetsubmissionreports'
                  element={
                    <SuspensedView>
                      <TimeSheetSubmissionReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/employeefilterreports'
                  element={
                    <SuspensedView>
                      <EmployeeFilterReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageusers'
                  element={
                    <SuspensedView>
                      <ManageUsersList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/businessunit'
                  element={
                    <SuspensedView>
                      <ManageBusinessUnitList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managebranches'
                  element={
                    <SuspensedView>
                      <ManageBranchesList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managedepartments'
                  element={
                    <SuspensedView>
                      <ManageDepartmentsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects'
                  element={
                    <SuspensedView>
                      <ManageProjectList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/addproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/updateproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageprojects/addproject' element={<AddProject />} />
                <Route path='masters/manageprojects/updateproject' element={<AddProject />} />

                <Route
                  path='/manageclients'
                  element={
                    <SuspensedView>
                      <ManageClientsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/updateclients'
                  element={
                    <SuspensedView>
                      <UpdateClient />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants'
                  element={
                    <SuspensedView>
                      <ManageConsultantList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/addconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/updateconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/viewconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageconsultants/addconsultant' element={<AddConsultant />} />
                <Route
                  path='masters/manageconsultants/updateconsultant'
                  element={<AddConsultant />}
                />
                <Route
                  path='masters/manageconsultants/viewconsultant'
                  element={<AddConsultant />}
                />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='error/*' element={<ErrorsPage />} />
                {/* <Route index element={<Navigate to='/login' />} />
                <Route path='login/*' element={<Timesheet />} />
                <Route path='*' element={<Navigate to='/login' />} /> */}
              </>
            )}

            {numArray?.includes('relationshipmanager') ? (
              <>
                <Route
                  path='/MyConsultant'
                  element={
                    <SuspensedView>
                      <MyConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/invoice'
                  element={
                    <SuspensedView>
                      <InvoiceMenu />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/MyConsultant/correspondentDetails'
                  element={
                    <SuspensedView>
                      <CorrespondenceDetails />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/viewconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timesheetlist'
                  element={
                    <SuspensedView>
                      <MyTimesheetsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/leaves'
                  element={
                    <SuspensedView>
                      <MyLeavesList />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/projectview'
                  element={
                    <SuspensedView>
                      <MyProjects />
                    </SuspensedView>
                  }
                />

                <Route
                  path='/timesheetlist/addtimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/updatetimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />

                <Route path='timesheetlist/addtimesheet' element={<AddTimesheet />} />
                <Route path='timesheetlist/updatetimesheet' element={<AddTimesheet />} />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='error/*' element={<ErrorsPage />} />
                {/* <Route index element={<Navigate to='/login' />} />
                <Route path='login/*' element={<Timesheet />} />
                <Route path='*' element={<Navigate to='/login' />} /> */}
              </>
            )}

            {/* {localStorage.getItem('role_Name') === 'timesheetadmin' ? ( */}
            {numArray?.includes('timesheetadmin') ? (
              <>
                <Route
                  path='/dashboard/organization'
                  element={
                    <SuspensedView>
                      <OrganizationCompliance />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/invoice'
                  element={
                    <SuspensedView>
                      <InvoiceMenu />
                    </SuspensedView>
                  }
                />
                <Route
                  path={'/reports'}
                  element={
                    <SuspensedView>
                      <Reports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/revenue'
                  element={
                    <SuspensedView>
                      <RevenueCompianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/cogs'
                  element={
                    <SuspensedView>
                      <CogsComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/margin'
                  element={
                    <SuspensedView>
                      <MarginComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/dashboard/utilization'
                  element={
                    <SuspensedView>
                      <UtilizationComplianceReports />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageusers'
                  element={
                    <SuspensedView>
                      <ManageUsersList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/businessunit'
                  element={
                    <SuspensedView>
                      <ManageBusinessUnitList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managebranches'
                  element={
                    <SuspensedView>
                      <ManageBranchesList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/managedepartments'
                  element={
                    <SuspensedView>
                      <ManageDepartmentsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects'
                  element={
                    <SuspensedView>
                      <ManageProjectList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/addproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageprojects/updateproject'
                  element={
                    <SuspensedView>
                      <AddProject />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageprojects/addproject' element={<AddProject />} />
                <Route path='masters/manageprojects/updateproject' element={<AddProject />} />

                <Route
                  path='/manageclients'
                  element={
                    <SuspensedView>
                      <ManageClientsList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/updateclients'
                  element={
                    <SuspensedView>
                      <UpdateClient />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants'
                  element={
                    <SuspensedView>
                      <ManageConsultantList />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/addconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/updateconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/masters/manageconsultants/viewconsultant'
                  element={
                    <SuspensedView>
                      <AddConsultant />
                    </SuspensedView>
                  }
                />
                <Route path='masters/manageconsultants/addconsultant' element={<AddConsultant />} />
                <Route
                  path='masters/manageconsultants/updateconsultant'
                  element={<AddConsultant />}
                />
                <Route
                  path='masters/manageconsultants/viewconsultant'
                  element={<AddConsultant />}
                />

                <Route
                  path='/profile'
                  element={
                    <SuspensedView>
                      <Profile />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/addtimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
                <Route
                  path='/timesheetlist/updatetimesheet'
                  element={
                    <SuspensedView>
                      <AddTimesheet />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route path='*' element={<Navigate to='/error/404' />} />
                <Route path='error/*' element={<ErrorsPage />} />
                {/* <Route index element={<Navigate to='/login' />} />
                <Route path='login/*' element={<Timesheet />} />
                <Route path='*' element={<Navigate to='/login' />} /> */}
              </>
            )}

            {/* <Route path='OrganizationList' element={<OrganizationList />} />
            <Route
              path='/OrganizationList'
              element={
                <SuspensedView>
                  <OrganizationList />
                </SuspensedView>
              }
            />
            <Route
              path='apps/chooseorganization'
              element={
                <SuspensedView>
                  <ChooseOrganization />
                </SuspensedView>
              }
            /> */}
          </>
        ) : (
          <>
            <Route index element={<Navigate to='/login' />} />
            <Route path='login/*' element={<Timesheet />} />
            <Route path='*' element={<Navigate to='/login' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
