/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')

  return (
    <>
      {/* {localStorage.getItem('role_Name') === 'employee' ? ( */}
      {numArray?.includes('employee') ||
      numArray?.includes('admin') ||
      numArray?.includes('hr') ||
      numArray?.includes('approver') ||
      numArray?.includes('relationshipmanager') ||
      numArray?.includes('accounts') ? (
        <>
          <AsideMenuItem
            to='timesheetlist'
            icon='/media/icons/duotune/files/fil002.svg'
            title='Timesheets'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='leaves'
            icon='/media/icons/duotune/maps/map008.svg'
            title='My Leaves'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='projectview'
            icon='/media/icons/duotune/general/gen008.svg'
            title='My Projects'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}
      {numArray?.includes('relationshipmanager') ? (
        <>
          <AsideMenuItem
            to='MyConsultant'
            icon='/media/icons/duotune/communication/com014.svg'
            title='My Consultants'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}
      {/* {localStorage.getItem('role_Name') === 'superadmin' ||
      localStorage.getItem('role_Name') === 'admin' ||
      localStorage.getItem('role_Name') === 'hr' ||
      localStorage.getItem('role_Name') === 'approver' ||
      localStorage.getItem('role_Name') === 'accounts' ? ( */}
      {numArray?.includes('superadmin') ||
      numArray?.includes('admin') ||
      numArray?.includes('hr') ||
      numArray?.includes('approver') ||
      numArray?.includes('accounts') ? (
        <>
          <AsideMenuItem
            to='approver'
            icon='/media/icons/duotune/general/gen048.svg'
            title='Approver'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}
      {/* {localStorage.getItem('role_Name') === 'superadmin' ||
      localStorage.getItem('role_Name') === 'admin' ||
      localStorage.getItem('role_Name') === 'hr' ||
      localStorage.getItem('role_Name') === 'approver' ||
      localStorage.getItem('role_Name') === 'accounts' ? ( */}
      {numArray?.includes('superadmin') ||
      numArray?.includes('admin') ||
      numArray?.includes('hr') ||
      numArray?.includes('approver') ||
      numArray?.includes('timesheetadmin') ||
      numArray?.includes('accounts') ? (
        <AsideMenuItem
          to='reports'
          icon='/media/icons/duotune/graphs/gra010.svg'
          title='Reports'
          fontIcon='bi-layers'
        />
      ) : (
        ''
      )}
      {/* {numArray?.includes('admin') ||
      numArray?.includes('accounts') ||
      localStorage.getItem('invoice_access') == 'true' ? (
        <>
          <AsideMenuItem
            to='invoice'
            icon='/media/icons/duotune/finance/fin010.svg'
            title='Invoices'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )} */}

      {numArray?.includes('admin') || localStorage.getItem('invoice_access') == 'true' ? (
        <>
          <AsideMenuItem
            to='invoice'
            icon='/media/icons/duotune/finance/fin010.svg'
            title='Invoices'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}

      {numArray?.includes('admin') ? (
        <>
          <AsideMenuItem
            to='integrations'
            icon='/media/icons/duotune/general/gen011.svg'
            title='Integrations'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}
      {/* {localStorage.getItem('role_Name') === 'superadmin' ||
      localStorage.getItem('role_Name') === 'admin' ||
      localStorage.getItem('role_Name') === 'approver' ||
      localStorage.getItem('role_Name') === 'accounts' ||
      localStorage.getItem('role_Name') === 'hr' ||
      localStorage.getItem('role_Name') === 'timesheetadmin' ? ( */}
      {numArray?.includes('superadmin') ||
      numArray?.includes('admin') ||
      numArray?.includes('approver') ||
      numArray?.includes('accounts') ||
      numArray?.includes('hr') ||
      numArray?.includes('timesheetadmin') ? (
        <>
          <AsideMenuItemWithSub
            to='/masters'
            icon='/media/icons/duotune/files/fil001.svg'
            title='Masters'
            // hasBullet={true}
            fontIcon='bi-layers'
          >
            <AsideMenuItem
              to='/masters/manageusers'
              title={intl.formatMessage({id: 'MENU.USERSLIST'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/masters/manageprojects'
              title={intl.formatMessage({id: 'MENU.MANAGEPROJECTS'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/manageclients'
              title={intl.formatMessage({id: 'MENU.MANAGECLIENTS'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/masters/manageconsultants'
              title={intl.formatMessage({id: 'MENU.CONSULTANTlIST'})}
              hasBullet={true}
            />

            {/* {!numArray?.includes('timesheetadmin') && ( */}

            {numArray?.includes('admin') ||
            numArray?.includes('approver') ||
            numArray?.includes('accounts') ||
            numArray?.includes('hr') ? (
              <>
                <AsideMenuItem
                  to='/masters/businessunit'
                  title={intl.formatMessage({id: 'MENU.BUSINESSUNIT'})}
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/masters/managebranches'
                  title={intl.formatMessage({id: 'MENU.BRANCHES'})}
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/masters/managedepartments'
                  title={intl.formatMessage({id: 'MENU.DEPARTMENTS'})}
                  hasBullet={true}
                />
              </>
            ) : (
              ''
            )}
            {/* {localStorage.getItem('role_Name') === 'superadmin' ||
            localStorage.getItem('role_Name') === 'admin' ? ( */}
            {numArray?.includes('superadmin') || numArray?.includes('admin') ? (
              <>
                <AsideMenuItem
                  to='/masters/settings'
                  title={intl.formatMessage({id: 'MENU.EmailSetting'})}
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/masters/manageannouncements'
                  title={intl.formatMessage({id: 'MENU.AnnouncementList'})}
                  hasBullet={true}
                />
                <AsideMenuItem
                  to='/masters/managedocuments'
                  title={intl.formatMessage({id: 'MENU.MANAGEDOCUMENTS'})}
                  hasBullet={true}
                />
                {/* <AsideMenuItem
                  to='/masters/qbSettings'
                  title={intl.formatMessage({id: 'MENU.QBSETTINGS'})}
                  hasBullet={true}
                /> */}
              </>
            ) : (
              ''
            )}
          </AsideMenuItemWithSub>
        </>
      ) : (
        ''
      )}

      {/* {localStorage.getItem('role_Name') === 'superadmin' ||
      localStorage.getItem('role_Name') === 'admin' ? ( */}
      {numArray?.includes('superadmin') || numArray?.includes('admin') ? (
        <>
          {localStorage.getItem('MobileForceUpdateAccessbyEmail') &&
            localStorage.getItem('MobileForceUpdateAccessbyEmail') == 'true' && (
              <AsideMenuItem
                to='mobilesettings'
                icon='/media/icons/duotune/graphs/gra010.svg'
                title='Mobile'
                fontIcon='bi-layers'
              />
            )}
        </>
      ) : (
        ''
      )}
    </>
  )
}
