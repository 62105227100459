import React, {useState, useRef, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
// import { DatePicker, Space } from 'antd'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import EmployeePop from './EmployeePop'
// import { default as Apiservices } from '../../../../../src/common/index'
import {default as Apiservices} from '../../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
// import DatePicker from 'react-datepicker'
import DatePicker, {Calendar, getAllDatesInRange} from 'react-multi-date-picker'
import 'react-datepicker/dist/react-datepicker.css'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useLocation} from 'react-router-dom'
import {Tooltip} from '../../timesheetentry/ComponentTooltip'
import {KTCardBody, KTSVG} from '../../../../../_metronic/helpers'
import BackDrop from '../../../../modules/apps/timesheetentry/ComponentBackdrop'

export default function QuickBooksReports(props: any) {
  const methods = useForm()

  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [weekDayStatus, setWeekDayStatus] = useState<any[]>([])
  const [search_open, setSearch_Open] = useState(false)
  const [DateValidation, setDateValidation] = useState(false)
  function handleShow(breakpoint: any) {
    setFullscreen(true)
    setShow(true)
  }

  useEffect(() => {
    // getValidate()
    getAdpValidateUpdate()
    // const res: any = Apiservices.updateUserEmpId({ user_id: userUpdateId })
    // setMainTableData(res.data.data)
  }, [])

  const getAdpValidateUpdate = async () => {
    const response: any = await Apiservices.getQbbyname({country_name: country})
    if (response.data.data.length > 0) {
      // setWorkAuthorizationData(response.data.data)
      setWorkAuthorizationData(['Select All', ...response.data.data])
    }
    // // setWorkData1(e.target.value)
  }

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  // const { RangePicker } = DatePicker
  const [dates, setDates] = useState<any>([])
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })
  type UserSubmitForm = {
    joining_date: Date
    work_country: string
    profileIdResponse: any
    leaving_date: string
    timesheet_approver: string
    searchcandidate: string
    qb_employee_name: string
    work_city: string
    qb_employee_id: number
    prefix: string
    record_type_status: string
    first_name: string
    name: string
    phone_number: string
    phone: string
    maxLength: any
    // adp_associate_id: number
    last_name: string
    user_status: string
    employeeId: any
    toolName: any
    orgId: any
    domain: string
    roleName: any
    org_name: any
    userStatus: any
    ecdb_user_id: number
    role: string
    email: string
    firstName: string
    full_name: string
    employee_type: string
    // user_status: string
    adp_associate_id: string
    status: string
    userId: any
    filteredMatch: any
    id: any
    employee_id: any
    candidateId: any
    org_id: any
    profileResponse: any
    work_state: string
    zipcode: string
    permanent_address: string
    work_location: string
    work_street_address: string
    work_address: string
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [mainTableData, setMainTableData] = React.useState([])

  const [showBackDrop, setShowBackDrop] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false)
  const [clientname, setClientName] = useState<any | []>([])
  const [profileResponseId, setProfileResponseId] = useState<any | []>([])
  const [list1, setList1] = useState<any | []>([])
  const [list2, setList2] = useState<any | []>([])
  const [list3, setList3] = useState<any | []>([])
  const [list4, setList4] = useState<any | []>([])
  const [list5, setList5] = useState<any | []>([])
  const [profileResponseLocalId, setProfileResponseLocalId] = useState<any | []>([])
  const [dataIdprofile, setDataidProfile] = useState<any | []>([])
  const [isView, setIsView] = useState(false)
  const [singleSelections, setSingleSelections] = useState<any[]>([])
  const [prospectiveLocalIdData, setProspectiveLocalIdData] = useState<any[]>([])
  const [employeeTypeData, setEmployeeTypeData] = useState<any>()
  const [ecdbUserId, setEcdbUserId] = useState<any[]>([])
  const [candidate, setCandidate] = useState<any>({})
  const [clientopen, setClientOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [consultantEmployeeType, setConsultantEmployeeType] = useState('')
  const [numberData, setNumberData] = useState('')
  const [nameData, setNameData] = useState('')
  const [ecdbData, setECDBData] = useState('')
  const [countryData, setCountryData] = useState('')
  const [lastData, setLastData] = useState('')
  const [adpId, setADPID] = useState('')
  const [zip, setZIP] = useState('')
  const [country, setCountry] = useState<any | []>([])
  const [stateName, setStateName] = useState<any | []>([])
  const [nameEmployee, setNameEmployee] = useState<any | []>([])
  const [title, setTitle] = useState<any | []>([])
  const [checkedEmail, setCheckedEmail] = useState<any | []>([])
  const [ecdbUserIdData, setEcdbUserIdData] = useState<any>()
  const [profileResponseId1, setProfileResponseId1] = useState<any | []>([])
  const [text, setText] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [disabled, setDisable] = useState(true)
  const [filteredMatch, setFilteredMatch] = useState<any>()
  const [filteredMatch1, setFilteredMatch1] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [resetvalues, setResetvalues] = useState(true)
  const [qb_disable_fields, setqbDisableFields] = useState(false)
  const [message, setMessage] = useState(false)
  const [resProspDetailsFromLocaldb, setResProspDetailsFromLocaldb] = useState(false)
  const [resEcdbDetailsFromLocaldb, setResEcdbDetailsFromLocaldb] = useState(false)
  const [searchCandidateDisable, setSearchCandidateDisable] = useState(false)
  const [flag, setFlag] = useState(false)
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [error1, setError1] = useState<any | []>([])
  const [statesId, setStatesId] = useState<any | []>([])
  const location = useLocation()
  const [selectedItem, setSelectedItem] = useState('')
  const [firstNameData, setFirstNameData] = useState('')
  const [email, setEmail] = useState('')
  const [list, setList] = useState(false)
  // const [dateRange, setDateRange] = useState([null, null])
  // const [startDate, endDate] = dateRange
  const [dateRange, setDateRange] = useState<any | []>([])
  const [MonthYearDynamicallyChange, setMonthYearDynamicallyChange] = useState<any>([])
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [workAuthorizationData, setWorkAuthorizationData] = useState<any | []>([])

  const ref: any = useRef<HTMLInputElement>(null)

  // const [error, setError] = useState(null);
  const [error, setError] = useState<any | []>([])
  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }
  const [flag1, setFlag1] = useState(false)
  const [resetData1, setResetData] = useState<any | []>([])
  const [workData1, setWorkData1] = useState('')

  const searchHandleChange = async (value: any) => {
    setSearch_Open(true)
    setSearch(value)
    setDateValidation(true)
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    if (dateRange?.length == 2) {
      setDateValidation(false)
      const data: any = await Apiservices.employeeExportQBreportViewData({
        // start_date: startDate2,
        // end_date: endDate2,
        start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
        end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
        keyword: value,
      })
      setMainTableData(data?.data?.data)
    }
  }

  const workData = async (e: any) => {
    // const response: any = await Apiservices.getQbbyname({ country_name: country })
    setWorkData1(e.target.value)

    // // const str = e.target.value;

    // // const lastWord = str.split(' ').pop();
    // // const firstWord = str.split(' ').shift();

    // let id = workAuthorizationData.find((obj: any) => obj.workAuthorizationName === e.target.value)

    // id = id ? id.workAuthorizationId : null

    // setWorkId(id)
    // setWorkData1(e.target.value)
  }

  const handleService = (selected: any) => {
    // setBusinessUnit(selected)
    if (selected.length > 0) {
      for (let each of selected) {
        if (each == 'Select All') {
          let x = workAuthorizationData.filter(
            (temp: any) => temp != 'Select All' && temp != 'Deselect All'
          )
          setBusinessUnit(x)
          workAuthorizationData.shift('Select All')
          let abc = workAuthorizationData.find((item: any) => item == 'Deselect All')
          if (!abc) {
            workAuthorizationData.push('Deselect All')
            setWorkAuthorizationData(workAuthorizationData)
          }
        } else if (each == 'Deselect All') {
          setBusinessUnit([])
          let cde = workAuthorizationData.find((item: any) => item == 'Select All')

          if (!cde) {
            workAuthorizationData.unshift('Select All')
            // setWorkAuthorizationData(workAuthorizationData)
          }
          workAuthorizationData.pop('Deselect All')
        } else {
          setBusinessUnit(selected)
          let abc = workAuthorizationData.find((item: any) => item == 'Deselect All')
          let cde = workAuthorizationData.find((item: any) => item == 'Select All')

          if (!abc) {
            workAuthorizationData.push('Deselect All')
          }
          if (!cde) {
            workAuthorizationData.unshift('Select All')
            setWorkAuthorizationData(workAuthorizationData)
          }

          let check_drop = selected.find((item: any) => item == 'Select All')
          let check_drop1 = selected.find((item: any) => item == 'Deselect All')
          if (!check_drop && !check_drop1) {
            let test = workAuthorizationData.filter(
              (id: any) => !selected.some((x: any) => x == id)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res == 'Select All' || res == 'Deselect All')

              workAuthorizationData.shift('Select All')
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      workAuthorizationData.pop('Deselect All')
      let businessReset = workAuthorizationData.find((item: any) => item == 'Select All')
      if (!businessReset) {
        workAuthorizationData.unshift()
      }
    }
  }
  const handleChange44 = (e: any) => {
    const name = e.target.name
    const value = e.target.value

    setFilteredMatch(e.target.value)
    setFilteredMatch1(e.target.value)

    setAccount({...account, [name]: value})
  }
  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }
  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const selectedCountries = async (state: any) => {
    setFlag1(false)
    setCountries(state)
    setUseStateCountryId(state[0]?.id)
    setResetData(state[0]?.api_name)
  }

  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getQbbyname({country_name: country})

      setCountry(response && response.data && response.data.data ? response?.data?.data[0] : '')
    }
  }
  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [account, setAccount] = useState({
    joining_date: '',
    leaving_date: '',
    // enddate: "",
    reason: '',
    leavetype: '',
  })
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })
  const [userId, setUserId] = useState([])
  const [prospUpdateId, setProspUpdateId] = useState()
  const [ECDBUpdateId, setECDBUpdateId] = useState<any>()

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    let employeeIdData = employeeValid.data.data.employee_id

    setShowBackDrop(false)
  }

  const [value1, setValue1] = useState<any>([])
  const [value2, setValue2] = useState<any>([])
  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue1(newDate)
    props.setFilterDepartment(newDate)
  }
  const onChangeDate1 = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue2(newDate)
    props.setFilterStatus(newDate)
  }

  const handleClick = async (e: any) => {
    setSearch_Open(false)
    setShowBackDrop(true)
    setDateValidation(true)
    setFlag(true)
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let business1 = BusinessUnit.map((val: any) => {
      return val
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    if (dateRange?.length == 2) {
      setDateValidation(false)
      const data: any = await Apiservices.employeeExportQBreportViewData({
        // pagenumber: currentPage,
        // pagesize: itemsPerPage,
        // start_date: startDate2,
        // end_date: endDate2,
        start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
        end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
        // api_name: workData1 == 'Select Service Name' ? null : workData1 ? workData1 : '',
        api_name: business2 ? business2 : null,
      })
      setMainTableData(data?.data?.data)

      const projectData = data?.data?.data
      let otLeaveHolidayStatus: any = []

      for (var i = 0; i < projectData?.length; i++) {
        var forSingleProject: any = {
          otdisable: false,
        }
        otLeaveHolidayStatus.push(forSingleProject)
        setWeekDayStatus([...otLeaveHolidayStatus])
      }
    }
    setShowBackDrop(false)
  }
  const [date, setDate] = useState(new Date())
  const [projectsData, setProjectsData] = useState<any | []>([])
  const [indexState, setindexState] = useState('')
  const [totalState, setTotalState] = useState('')
  const [serViceNameData, setServiceName] = useState('')
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const [copySuccess, setCopySuccess] = useState('')
  const [Random, setRandom] = useState('')
  const textAreaRef: any = useRef(null)
  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }
  const handleDescription = async (description: any, total: any, index: any) => {
    var today = moment(date)
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    const res: any = await Apiservices.employeeWiseProject({
      start_date: startOfWeek,
      end_date: endOfWeek,
      user_id: description,
    })
    setProjectsData(res.data.data)

    setAnnouncementOpenPoup(true)
    setindexState(description)
    setTotalState(total)
  }
  const AnnouncePop = (value: any) => {
    setAnnouncementOpenPoup(false)
  }
  const [search, setSearch] = useState('')
  const resetData = async (e: any) => {
    setFlag(false)
    setMainTableData([])
    setDateValidation(false)
    // setDateRange([null])
    setDateRange(null)
    setMonthYearDynamicallyChange([])
    setBusinessUnit([])
    // setInputValue("")
    // ref.current.clear()

    // methods.setValue("last_name", '')
    // const data: any = await Apiservices.employeereportViewData({
    //   // pagenumber: currentPage,
    //   // pagesize: itemsPerPage,
    //   start_date: dates,
    //   end_date: dates1,
    // })
    //     setMainTableData(data?.data?.data)
  }
  const [inputValue, setInputValue] = useState('')

  const handleUserInput = (e: any) => {
    setInputValue(e.target.value)
  }

  // const exporthandleClick = async (e: any) => {
  //   setShowBackDrop(true)
  //   let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   // e.preventDefault();
  //   // console.log(flag3, "anilll2")
  //   // if (flag3 == true) {
  //   console.log('anilll2')
  //   const res: any = await Apiservices.employeeExportQBreportViewReports({
  //     start_date: startDate2,
  //     end_date: endDate2,
  //     // api_name: resetData1 ? resetData1 : '',
  //     api_name: workData1 == 'Select Service Name' ? null : workData1 ? workData1 : '',
  //     keyword: search ? search : '',
  //   })
  //   setShowBackDrop(false)

  //   var link = document.createElement('a')
  //   // If you don't know the name or want to use
  //   // the webserver default set name = ''
  //   link.setAttribute('download', 'Downloaded File')
  //   link.href = res.data.data.blobUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  //   // }
  // }

  const exporthandleClick = async (e: any) => {
    setShowBackDrop(true)
    setDateValidation(true)
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let business1 = BusinessUnit.map((val: any) => {
      return val
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    if (dateRange?.length == 2) {
      setDateValidation(false)
      if (search_open) {
        // if (flag3 == true) {
        const res: any = await Apiservices.employeeExportQBreportViewReports({
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          // api_name: resetData1 ? resetData1 : '',
          // api_name: workData1 == 'Select Service Name' ? null : workData1 ? workData1 : '',
          keyword: search ? search : '',
        })
        setShowBackDrop(false)

        var link = document.createElement('a')
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', 'Downloaded File')
        link.href = res.data.data.blobUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else {
        // if (flag3 == true) {
        const res: any = await Apiservices.employeeExportQBreportViewReports({
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          // api_name: resetData1 ? resetData1 : '',
          api_name: business2 ? business2 : null,
          // keyword: search ? search : '',
        })
        setShowBackDrop(false)

        var link = document.createElement('a')
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', 'Downloaded File')
        link.href = res.data.data.blobUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    }
    setShowBackDrop(false)
  }
  const handleInputChange = async (e: any) => {
    setServiceName(e.target.value)
  }

  function copyToClipboard(e: any, index: any, random: any) {
    setRandom(random)
    const textField = document.createElement('textarea')
    textField.innerText = e
    weekDayStatus[index].otdisable = true
    setWeekDayStatus([...weekDayStatus])
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setCopySuccess('Copied!')
    setTimeout(function () {
      setCopySuccess('')
      weekDayStatus[index].otdisable = false
    }, 1000)
  }
  const handleDateMulti = (date: any, val: any) => {
    let allDates: any = getAllDatesInRange(date, true)
    setDateRange(date)
    let monthYear: any = {
      d1: date[0]?.day < 10 ? '0' + date[0]?.day : date[0]?.day,
      d2: date[1]?.day < 10 ? '0' + date[1]?.day : date[1]?.day,
      // m1: date[0]?.month.number,
      // m1: date[0]?.month.name,
      m1: moment(date[0]?.month.name, 'MMMM').format('MM'),
      // m2: date[1]?.month.number,
      // m2: date[1]?.month.name,
      m2: moment(date[1]?.month.name, 'MMMM').format('MM'),
      y1: date[0]?.year,
      y2: date[1]?.year,
    }
    setDateValidation(false)
    setMonthYearDynamicallyChange(monthYear)
  }
  return (
    <>
      {announcement_open_popup ? (
        <EmployeePop
          close={AnnouncePop}
          indexState={indexState}
          totalState={totalState}
          role_name={'Admin'}
          setProjectsData={setProjectsData}
          projectsData={projectsData}
        />
      ) : (
        ''
      )}
      <BackDrop showBackDrop={showBackDrop} />
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>QB logs(Quickbook logs)</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <form onSubmit={handleSubmit(onSubmit)}>
            {loading ? (
              <>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    {/* <div className='row gy-6 align-items-center py-4'> */}
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col'>
                        <label className='form-label fw-semibold'>
                          Date Range <span className='fs-9'>(DD-MMM-YYYY)</span> *{' '}
                        </label>
                        {/* <DatePicker
                          placeholderText='Date Range'
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat='dd-MMM-yyyy'
                          onChange={(update: any) => {
                            setDateRange(update)
                          }}
                          isClearable={true}
                          monthsShown={2}
                          className={clsx('form-control')}
                          required
                        /> */}
                        <DatePicker
                          range
                          numberOfMonths={2}
                          dateSeparator=' - '
                          format='DD-MMM-YYYY'
                          // className={clsx('form-control')}
                          inputClass='form-control'
                          placeholder='Select Date Range'
                          value={dateRange}
                          onChange={(dateObjects: any) => {
                            handleDateMulti(dateObjects, true)
                          }}
                        />
                        {DateValidation && (
                          <span className='error text-danger mb-0'>Date Range is required</span>
                        )}
                      </div>

                      {/* <input
                                            {...register('last_name')}
                                            placeholder='Service Name'

                                            type='text'
                                            // onKeyPress={(e) => {
                                            //     if (new RegExp(/[0-9a-zA-Z]/i).test(e.key)) {
                                            //     } else e.preventDefault()
                                            // }}
                                            onChange={(e) => handleInputChange(e)}
                                            className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                        /> */}
                      {/* <input type="text"
                                            className='form-control fs-6'
                                            placeholder='Enter Employee Id'
                                            value={inputValue} onChange={handleUserInput} />
                                        <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      <div className='col'>
                        <label className='form-label '>Service Name</label>

                        {/* <select
                          className={clsx('form-select', errors.last_name ? 'is-invalid' : '')}
                          {...register('last_name')}
                          id='visa_status'
                          // disabled={isView}

                          onChange={workData}
                        >
                          {workAuthorizationData.map((option: any) => {
                            return (
                              <option value={option}>
                                {user(option)}
                              </option>
                            )
                          })}
                        </select> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleService(e)
                            typeaheadRef5.current.toggleMenu()
                          }}
                          options={workAuthorizationData}
                          ref={typeaheadRef5}
                          placeholder='Select Service Name'
                          selected={BusinessUnit}
                          labelKey={(workAuthorizationData: any) => workAuthorizationData}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                        {/* <Typeahead
                                            id='basic-typeahead-single'
                                            disabled={isView}
                                            {...register('work_country')}
                                            onChange={(e) => selectedCountries(e)}
                                            onInputChange={(e) => handleCountries(e)}
                                            placeholder='Please Enter Service Name'
                                            options={country}
                                            // selected={countries}
                                            labelKey={(country: any) => country.api_name}
                                            ref={ref}
                                        >
                                            {({ onClear, selected }) => (
                                                <div className='rbt-aux'>
                                                    {!!selected.length && <ClearButton onClick={onClear} />}
                                                </div>
                                            )}
                                        </Typeahead> */}
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>

                      <div className='col'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          className='form-control'
                          value={search}
                          placeholder='Search'
                          onChange={(e) => searchHandleChange(e.target.value)}
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-auto pt-8'>
                        <button className='btn btn-sm btn-primary' onClick={handleClick}>
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>

          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the report
            </div>
          )}
          {flag == true && (
            <div>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='table-responsive reports-table'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4'
                      >
                        <>
                          <thead>
                            <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                              {/* <th className='  py-4'>Employee Id </th>
                                                        <th className='  py-4'>Employee Name </th> */}
                              <th className='  py-4'>S.No </th>
                              <th className='  py-4'>Service Name </th>
                              <th className='  py-4'>Request Status </th>
                              <th className='  py-4 text-center'>Request Data </th>

                              <th className='  py-4 text-center'>Response Data </th>

                              {/* <th className='  py-4'>
                                    Employee Id

                                  </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {mainTableData?.length > 0 ? (
                              <>
                                {mainTableData
                                  // .filter(
                                  //   (each: any) =>
                                  //     each?.request_status
                                  //       ?.toLowerCase()
                                  //       ?.includes(search?.toLowerCase()) ||
                                  //     each?.api_name?.toLowerCase()?.includes(search?.toLowerCase())
                                  // )

                                  ?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      key={index}
                                    >
                                      <td className=''>{index + 1}</td>
                                      <td className=''>{each.api_name ? each.api_name : '-'}</td>

                                      <td className=''>
                                        {each.request_status ? each.request_status : '-'}
                                      </td>

                                      <td className='text-center'>
                                        <Tooltip
                                          text={each?.request_data ? each?.request_data : '-'}
                                        >
                                          {/* <i className='fa fa-eye ' aria-hidden='true'></i> */}

                                          <a
                                            onClick={() =>
                                              copyToClipboard(each.request_data, index, 'req')
                                            }
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen054.svg'
                                              className='svg-icon-1 text-primary'
                                            />
                                          </a>
                                        </Tooltip>
                                        {weekDayStatus[index]?.otdisable &&
                                          Random == 'req' &&
                                          copySuccess}
                                      </td>

                                      <td className='text-center'>
                                        <Tooltip
                                          text={each.response_data ? each.response_data : '-'}
                                        >
                                          {/* <i className='fa fa-eye ' aria-hidden='true'></i> */}
                                          <a
                                            onClick={() =>
                                              copyToClipboard(each.response_data, index, 'res')
                                            }
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen054.svg'
                                              className='svg-icon-1 text-primary'
                                            />
                                          </a>
                                        </Tooltip>
                                        {weekDayStatus[index]?.otdisable &&
                                          Random == 'res' &&
                                          copySuccess}
                                      </td>
                                    </tr>
                                  ))}
                              </>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </>
                      </table>
                      {(mainTableData?.length == null || mainTableData?.length == 0) && (
                        <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
